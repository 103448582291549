<template>
  <contextmenu :menu="contextmenus">
    <div
      class="attachmentItem"
      :title="attachment.name"
      @click.stop="previewFile"
      v-loading="previewLoading"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.2)"
    >
      <div class="closeItem" v-if="showDelete" @click.stop="deleteAttachment">
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>
      <div class="attachmentIcon">
        <el-image
          style="height: 100%"
          :src="getIconByFileName(attachment.name)"
          :fit="'cover'"
        ></el-image>
        <div
          class="loadingView"
          v-show="percentCompleted > -1 && percentCompleted < 100"
        >
          <el-image
            style="height: 100%"
            :src="
              require('../../../../../../assets/images/email/read/download.png')
            "
            :fit="'cover'"
          ></el-image>
          <div class="percentCompletedText">
            {{ `${percentCompleted}%` }}
          </div>
        </div>
      </div>
      <div class="attachmentName">{{ attachment.name }}</div>
      <div class="attachmentInfo">({{ changeLimit(attachment.size) }})</div>
    </div>
  </contextmenu>
</template>
<script>
import Axios from "axios";
import { encryptData } from "@/utils/aes_email";
import { handlerUploadFileToQiniu } from "@/api/qiniu";
import { attachmentDownload } from "@/api/newVersion/melinked_email";
export default {
  name: "EmailAttachmentItem",
  props: {
    attachment: {
      type: [Object, File],
      default: () => {
        return {};
      },
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    emailInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    preview: {
      type: Boolean,
      default: false,
    },
    operationStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      percentCompleted: 0,
      downloadSources: {},
      previewFileCache: null,
      replyFileItemCache: null,
      previewLoading: false,
      contextmenus: [],
    };
  },
  computed: {
    // 当前附件下载状态记录队列
    readingEmailAttachmentDownloads() {
      return this.$store.state.melinked_email.readingEmailAttachmentDownloads;
    },
    // 当前文件下载进度
    currentDownloadProgress() {
      if (
        typeof this.readingEmailAttachmentDownloads[
          `${this.emailInfo.uniqueIdentifier}_${this.attachment.sectionId}`
        ] != "number"
      ) {
        return -1;
      }
      return this.readingEmailAttachmentDownloads[
        `${this.emailInfo.uniqueIdentifier}_${this.attachment.sectionId}`
      ];
    },
    // 开启预览进度
    previewProgress() {
      if (this.previewFileCache) {
        return parseFloat(this.previewFileCache.progress);
      } else {
        return 0;
      }
    },
  },
  watch: {
    replyFileItemCache: {
      handler(val, old) {
        if (val && parseInt(val.progress) == 100 && val.path) {
          this.attachment.onlineUrl = val.path;
          let messageType = "file";
          const fileType = this.matchFileSuffixType(this.attachment.name);
          switch (fileType) {
            case "image":
              messageType = fileType;
              break;
            case "video":
              messageType = fileType;
              break;
          }
          const message = this.createMessageModel(messageType, {
            fileName: this.attachment.name,
            fileSize: this.attachment.size,
            url: this.attachment.onlineUrl,
          });
          this.startReply(message);
          this.replyFileItemCache = null;
        }
      },
      deep: true,
    },
    previewFileCache: {
      handler(val) {
        if (val.path && this.previewProgress == 100) {
          this.previewLoading = false;
          this.previewTask();
        }
      },
      deep: true,
    },
    currentDownloadProgress: {
      handler(val, old) {
        if (typeof val == "number" && val != old) {
          this.percentCompleted = val;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.previewFileCache && this.previewFileCache.key) {
      console.log("删除文件");
    }
  },
  mounted() {
    this.contextmenus.push(
      {
        icon: "el-icon-view",
        click: this.previewFile,
      },
      {
        icon: "el-icon--transmit",
        click: this.replyFile,
      },
      {
        icon: "el-icon-download",
        click: this.downloadFile,
      }
    );
  },
  methods: {
    deleteAttachment() {
      this.$emit("deleteAttachment", this.attachment);
    },
    judgment(text, type) {
      const handlerText = text.toLowerCase();
      const handlerType = type.toLowerCase();
      let typeLength =
        handlerText.length - handlerText.lastIndexOf(handlerType);
      return (
        handlerText.lastIndexOf(handlerType) != -1 &&
        typeLength == handlerType.length
      );
    },
    // 开启预览任务
    previewTask() {
      const { path, key } = this.previewFileCache;
      const fileKey = this.matchFileSuffixType(key),
        supportedTypes = ["image", "video", "audio"],
        supportedExtensions = ["doc", "docx", "xls", "xlsx"];
      const isSupportedType =
        supportedTypes.includes(fileKey) || key.indexOf("pdf") != -1;
      const isSupportedExtension = supportedExtensions.some((item) =>
        this.judgment(key, item)
      );
      if (isSupportedType) {
        window.open(path, "_blank");
      } else if (isSupportedExtension) {
        window.open(
          `https://view.officeapps.live.com/op/view.aspx?src=${path}`,
          "_blank"
        );
      } else {
        this.$message.error("不支持该文件类型预览");
      }
    },
    // 转发文件事件
    async replyFile() {
      // 判断是否已经上传过了
      if (!this.attachment.onlineUrl) {
        const fileSuffix = this.attachment.name
          .split(".")
          .pop()
          .toLowerCase();
        const mimeType = this.getMimeType(fileSuffix);
        const result = await this.download(this.attachment);
        const file = new File([result], this.attachment.name, {
          type: mimeType,
        });
        handlerUploadFileToQiniu({ file: file }).then(async (item) => {
          this.replyFileItemCache = item;
        });
      } else {
        let messageType = "file";
        const fileType = this.matchFileSuffixType(this.attachment.name);
        switch (fileType) {
          case "image":
            messageType = fileType;
            break;
          case "video":
            messageType = fileType;
            break;
        }
        const message = this.createMessageModel(messageType, {
          fileName: this.attachment.name,
          fileSize: this.attachment.size,
          url: this.attachment.onlineUrl,
        });
        this.startReply(message);
      }
    },
    // 开始转发
    startReply(message) {
      this.$store.dispatch("commitOpenReplyPopup", message);
    },
    // 预览文件事件
    async previewFile() {
      if (!this.operationStatus) {
        return;
      }
      if (!this.preview) {
        this.downloadFile();
        return;
      }
      if (this.previewFileCache) {
        this.previewTask();
        return;
      }
      this.previewLoading = true;
      const name = this.attachment.name;
      const fileType = this.matchFileSuffixType(name);
      const fileSuffix = name
        .split(".")
        .pop()
        .toLowerCase();
      const mimeType = this.getMimeType(fileSuffix);
      const supportedTypes = ["image", "video", "audio"];
      const supportedExtensions = ["doc", "docx", "xls", "xlsx", "pdf"];
      const isSupportedFile =
        supportedTypes.includes(fileType) ||
        supportedExtensions.some((item) => this.judgment(name, item));
      if (isSupportedFile) {
        const result = await this.download(this.attachment);
        const file = new File([result], this.attachment.name, {
          type: mimeType,
        });
        handlerUploadFileToQiniu({ file: file }).then(async (item) => {
          this.previewFileCache = item;
        });
      } else {
        this.previewLoading = false;
        this.downloadFile();
      }
    },
    // 手动指定 MIME 类型
    getMimeType(suffix) {
      const mimeTypes = {
        // Image types
        bmp: "image/bmp",
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        tif: "image/tiff",
        gif: "image/gif",
        pcx: "image/x-pcx",
        tga: "image/x-targa",
        exif: "image/jpeg",
        fpx: "image/vnd.fpx",
        svg: "image/svg+xml",
        psd: "image/vnd.adobe.photoshop",
        cdr: "image/x-coreldraw",
        pcd: "image/x-photo-cd",
        dxf: "image/vnd.dxf",
        ufo: "image/vnd.ufo",
        eps: "application/postscript",
        ai: "application/postscript",
        raw: "image/x-raw",
        wmf: "application/x-msmetafile",
        webp: "image/webp",
        avif: "image/avif",
        jfif: "image/jpeg",
        asf: "video/x-ms-asf",
        asx: "video/x-ms-asf",
        rm: "application/vnd.rn-realmedia",
        mp4: "video/mp4",
        m4v: "video/x-m4v",
        dat: "video/mpeg",
        mkv: "video/x-matroska",
        vob: "video/dvd",
        mp3: "audio/mpeg",
        wav: "audio/wav",
        doc: "application/msword",
        docx:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        xls: "application/vnd.ms-excel",
        xlsx:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        pdf: "application/pdf",
      };
      return mimeTypes[suffix] || "";
    },
    // 点击附件下载事件
    async downloadFile() {
      if (!this.operationStatus) {
        return;
      }
      const result = await this.download(this.attachment);
      this.downloadByBrowser(result);
    },
    // 重置下载状态
    resetDownloadStatus(attachment) {
      const downloads = _.cloneDeep(this.readingEmailAttachmentDownloads);
      const key = `${this.emailInfo.uniqueIdentifier}_${attachment.sectionId}`;
      downloads[key] = 0;
      this.$store.commit("setReadingEmailAttachmentDownloads", downloads);
    },
    // 是否正在下载
    isDownloading(attachment) {
      const downloads = _.cloneDeep(this.readingEmailAttachmentDownloads);
      const key = `${this.emailInfo.uniqueIdentifier}_${attachment.sectionId}`;
      return downloads[key] && downloads[key] != -1;
    },
    // 下载事件
    async download(attachment) {
      return new Promise(async (resolve, reject) => {
        try {
          if (this.isDownloading(attachment)) {
            return;
          }
          const params = {
            fileName: attachment.name,
            sectionId: attachment.sectionId,
            messageId: this.emailInfo.messageId,
            from: this.emailInfo.from,
          };
          this.resetDownloadStatus(attachment);
          const key = `${this.emailInfo.uniqueIdentifier}_${attachment.sectionId}`;
          const CancelToken = Axios.CancelToken;
          const source = CancelToken.source();
          if (this.downloadSources[key]) {
            this.downloadSources[key].cancel("中断下载");
            delete this.downloadSources[key];
          }
          this.downloadSources[key] = source;
          const realTime = await this.getRealTime();
          let token = JSON.parse(
            this.$store.state.melinked_email.melinked_email_token
          );
          token.u = token.u.toLowerCase();
          token.t = realTime;
          params.mailToken = encryptData(JSON.stringify(token));
          const result = await attachmentDownload(
            params,
            async (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              const downloads = this.$store.state.melinked_email
                .readingEmailAttachmentDownloads;
              if (typeof downloads[key] == "number" && percentCompleted < 100) {
                downloads[key] = percentCompleted;
              } else {
                downloads[key] = -1;
              }
            },
            source.token
          );
          if (this.downloadSources[key]) {
            delete this.downloadSources[key];
          }
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    },
    // 浏览器下载
    async downloadByBrowser(result) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(result);
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const fileBlob = new Blob([arrayBuffer]);
        const fileUrl = URL.createObjectURL(fileBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.download = this.attachment.name;
        downloadLink.click();
        URL.revokeObjectURL(fileUrl);
        downloadLink.remove();
      };
    },
  },
};
</script>
<style lang="stylus" scoped>
.attachmentItem
  display: flex;
  padding: 0 0 0 16px;
  width: 100%;
  height: 50px;
  border: 1px solid #D9D9D9;
  background: #F5F5F5;
  margin: 10px 0 0 0;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  &:hover
    .closeItem
      display: flex;
  .closeItem
    display: none;
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: rgba(0,0,0,0.5);
    top: -7px;
    right: -7px;
    align-items: center;
    justify-content: center;
    .iconStyle
      color: #FFFFFF;
      font-size: 10px;
  .attachmentIcon
    width: 26px;
    height: 26px;
    margin-right: 10px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .loadingView
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index 10;
      display: flex;
      align-items: center;
      justify-content: center;
      .percentCompletedText
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 700;
        color: #FFFFFF;
  .attachmentName
    font-size: 14px;
    line-height: 20px;
    color: #292D32;
    flex: 1;
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  .attachmentInfo
    font-size: 14px;
    color: #858585;
    max-width: 120px;
    min-width: 60px;
    padding-right: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
</style>
