var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "readEmailBox" }, [
    _c("div", { staticClass: "functionButtonArea" }, [
      _c(
        "div",
        { staticClass: "leftBox" },
        [
          _c(
            "div",
            {
              staticClass: "buttonBox",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-return backStyle" })]
          ),
          _vm._l(_vm.functionButtons, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "buttonBox",
                style: {
                  width: item.name == "edit" ? "30px" : "",
                  marginRight: item.name == "edit" ? "20px" : ""
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.clickEmailButton(item)
                  }
                }
              },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: _vm.showImg2(item) ? item.title_2 : item.title,
                      placement: "top"
                    }
                  },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center"
                      },
                      attrs: {
                        src: _vm.showImg2(item) ? item.img_2 : item.img,
                        fit: "contain"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._m(0)
    ]),
    _c("div", { staticClass: "emailContentArea" }, [
      _c("div", { ref: "emailContent", staticClass: "emailContent" }, [
        _c("div", { ref: "emailHeader", staticClass: "emailHeader" }, [
          _c(
            "div",
            { staticClass: "subTitle" },
            [
              _c("span", {
                staticClass: "titleText",
                attrs: { title: _vm.currentReadItemInfo.subject },
                domProps: { innerHTML: _vm._s(_vm.currentReadItemInfo.subject) }
              }),
              _c("el-image", {
                staticStyle: {
                  width: "20px",
                  height: "20px",
                  "margin-left": "8px",
                  "flex-shrink": "0"
                },
                attrs: {
                  src: _vm.currentReadItemInfo.mark
                    ? require("../../../../../../assets/images/email/list/star_bright.png")
                    : require("../../../../../../assets/images/email/list/star_grey.png"),
                  fit: "cover"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "subInfoBox" },
            [
              _vm.refreshContactName
                ? [
                    _vm.currentReadItemInfo.from
                      ? _c(
                          "div",
                          { staticClass: "commonLine" },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("emailFrom")) + ": ")
                            ]),
                            _vm._l(
                              _vm.handlerShowName(_vm.currentReadItemInfo.from),
                              function(item, index) {
                                return _c(
                                  "span",
                                  { key: index },
                                  [
                                    _vm.currentReadItemInfo.folder_name ==
                                    "Sent"
                                      ? [
                                          _c(
                                            "span",
                                            { staticClass: "fromName" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    `${item.name}<${item.email}>`
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      : [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "bottom",
                                                trigger: "click",
                                                "popper-class":
                                                  "emailFromOperatePopover"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "operatetitle" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(item.email) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "operateBox" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "buttonStyle",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.sendAnEmail(
                                                            item.email
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("sending")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "buttonStyle",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.setContactData(
                                                            item.email
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "saveAsAddressBook"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "fromName",
                                                  style: { cursor: "pointer" },
                                                  attrs: { slot: "reference" },
                                                  slot: "reference"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        `${item.name}<${item.email}>`
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.currentReadItemInfo.to
                      ? _c("div", { staticClass: "commonLine" }, [
                          _c(
                            "span",
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("emailRecipient")) +
                                  ":\n                "
                              ),
                              _vm._l(
                                _vm.handlerShowName(_vm.currentReadItemInfo.to),
                                function(item, index) {
                                  return _c(
                                    "span",
                                    { key: index },
                                    [
                                      _vm.currentEmailAccount.mailAddress ==
                                      item.email
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  `${item.name}<${item.email}>`
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        : [
                                            _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  placement: "bottom",
                                                  trigger: "click",
                                                  "popper-class":
                                                    "emailFromOperatePopover"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "operatetitle"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(item.email) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "operateBox" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "buttonStyle",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.sendAnEmail(
                                                              item.email
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t("sending")
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "buttonStyle",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.setContactData(
                                                              item.email
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "saveAsAddressBook"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      cursor: "pointer"
                                                    },
                                                    attrs: {
                                                      slot: "reference"
                                                    },
                                                    slot: "reference"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          `${item.name}<${item.email}>`
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ])
                      : _vm._e(),
                    _vm.currentReadItemInfo.cc
                      ? _c(
                          "div",
                          { staticClass: "commonLine" },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("CcTo")) + ": ")
                            ]),
                            _vm._l(
                              _vm.handlerShowName(_vm.currentReadItemInfo.cc),
                              function(item, index) {
                                return _c(
                                  "span",
                                  { key: index },
                                  [
                                    _vm.currentEmailAccount.mailAddress ==
                                    item.email
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                `${item.name}<${item.email}>`
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      : [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "bottom",
                                                trigger: "click",
                                                "popper-class":
                                                  "emailFromOperatePopover"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "operatetitle" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(item.email) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "operateBox" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "buttonStyle",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.sendAnEmail(
                                                            item.email
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("sending")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "buttonStyle",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.setContactData(
                                                            item.email
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "saveAsAddressBook"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  style: { cursor: "pointer" },
                                                  attrs: { slot: "reference" },
                                                  slot: "reference"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        `${item.name}<${item.email}>`
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.currentReadItemInfo.bcc &&
                    _vm.currentReadItemInfo.folder_name == "Sent"
                      ? _c(
                          "div",
                          { staticClass: "commonLine" },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("bcc")) + ": ")
                            ]),
                            _vm._l(
                              _vm.handlerShowName(_vm.currentReadItemInfo.bcc),
                              function(item, index) {
                                return _c(
                                  "span",
                                  { key: index },
                                  [
                                    _vm.currentEmailAccount.mailAddress ==
                                    item.email
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                `${item.name}<${item.email}>`
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      : [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "bottom",
                                                trigger: "click",
                                                "popper-class":
                                                  "emailFromOperatePopover"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "operatetitle" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(item.email) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "operateBox" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "buttonStyle",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.sendAnEmail(
                                                            item.email
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("sending")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "buttonStyle",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.setContactData(
                                                            item.email
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "saveAsAddressBook"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  style: { cursor: "pointer" },
                                                  attrs: { slot: "reference" },
                                                  slot: "reference"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        `${item.name}<${item.email}>`
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _c("div", { staticClass: "commonLine" }, [
                _c("span", [_vm._v(" " + _vm._s(_vm.$t("time")) + ": ")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.formatTimestamp(_vm.currentReadItemInfo.sendTimestamp)
                    )
                  )
                ])
              ])
            ],
            2
          )
        ]),
        _vm.haveICSFile && _vm.ICSInfoData
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.ICFLoading,
                    expression: "ICFLoading"
                  }
                ],
                ref: "ICSFileBox",
                staticClass: "ICSFileBox",
                attrs: { "element-loading-spinner": "el-icon-loading" }
              },
              [
                _c(
                  "div",
                  { staticClass: "ICSCardInfo" },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c("img", {
                        staticClass: "ICSImg",
                        attrs: {
                          src: require("../../../../../../assets/images/email/calendar.png")
                        }
                      }),
                      _vm._v(
                        "\n            " + _vm._s(_vm.ICSText) + "\n          "
                      )
                    ]),
                    _vm.icsFileStatus != "reply"
                      ? [
                          _vm.calendarEmailFlags == "default" &&
                          _vm.icsFileStatus == "request"
                            ? _c("div", { staticClass: "buttons" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" }
                                    ],
                                    staticClass: "buttonStyle button_1",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.confirmCalendar.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont_Me icon-check iconStyle"
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" }
                                    ],
                                    staticClass: "buttonStyle button_2",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.cancelCalendar.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont_Me icon-x iconStyle"
                                    })
                                  ]
                                )
                              ])
                            : (_vm.calendarEmailFlags == "accepted" ||
                                _vm.calendarEmailFlags == "declined") &&
                              _vm.icsFileStatus != "cancel"
                            ? _c("div", { staticClass: "buttons" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" }
                                    ],
                                    staticClass: "buttonStyle button_result"
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        src:
                                          _vm.calendarEmailFlags == "accepted"
                                            ? `https://imfile.melinked.com/2024/07/97282497-995f-4ce3-8bec-4eb5d1e0e9fd.png`
                                            : `https://imfile.melinked.com/2024/07/cd167ae8-2103-4122-8d6b-63a73ad6764b.png`
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _c("div", { staticClass: "buttons" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" }
                                    ],
                                    staticClass: "buttonStyle button_result"
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        src: `https://imfile.melinked.com/2024/07/cd167ae8-2103-4122-8d6b-63a73ad6764b.png`
                                      }
                                    })
                                  ]
                                )
                              ])
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm.ICSInfoData.summary || _vm.ICSInfoData.description
                  ? _c("div", { staticClass: "ICSContent" }, [
                      _vm.ICSInfoData.summary
                        ? _c("div", { staticClass: "summaryBox" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.ICSInfoData.summary) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _vm.ICSInfoData.description
                        ? _c("div", {
                            staticClass: "descriptionBox",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.handlerDescription(
                                  _vm.ICSInfoData.description
                                )
                              )
                            }
                          })
                        : _vm._e(),
                      _vm.ICSInfoData.location
                        ? _c("div", { staticClass: "locationBox" }, [
                            _c("i", {
                              staticClass: "iconfont_Me icon-position iconStyle"
                            }),
                            _vm._v(
                              _vm._s(_vm.ICSInfoData.location) + "\n          "
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm.handlerAttachments.length != 0
          ? _c(
              "div",
              { ref: "emailAttachment", staticClass: "emailAttachment" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "resize-ob",
                        rawName: "v-resize-ob",
                        value: _vm.attachmentBoxResize,
                        expression: "attachmentBoxResize"
                      }
                    ],
                    staticClass: "attachmentBox"
                  },
                  _vm._l(_vm.handlerShowAttachments, function(
                    attachment,
                    index
                  ) {
                    return _c("EmailAttachmentItem", {
                      key: index,
                      attrs: {
                        preview: true,
                        attachment: attachment,
                        emailInfo: _vm.currentReadItemInfo
                      }
                    })
                  }),
                  1
                ),
                _vm.handlerAttachments.length > this.attachmentsSliceNumber
                  ? _c("div", { staticClass: "displayFiles" }, [
                      _c(
                        "div",
                        {
                          staticClass: "displayImg",
                          on: { click: _vm.changeAttachmentList }
                        },
                        [
                          _c("img", {
                            style: {
                              transform: _vm.attachmentListStatus
                                ? "rotate(180deg)"
                                : ""
                            },
                            attrs: {
                              src: require("../../../../../../assets/images/email/drop_down .png")
                            }
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "displayText",
                          on: { click: _vm.changeAttachmentList }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.attachmentListStatus
                                  ? _vm.$t("hide")
                                  : _vm.$t("displayAttachments", {
                                      m: _vm.$t("show"),
                                      n: _vm.handlerAttachments.length
                                    })
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.htmlLoading,
                expression: "htmlLoading"
              }
            ],
            staticClass: "read_view",
            attrs: { "element-loading-spinner": "el-icon-loading" }
          },
          [
            _c("iframe", {
              ref: "email_iframe",
              attrs: {
                id: "email_iframe",
                frameborder: "0",
                allowpaymentrequest: true
              }
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rightBox" }, [
      _c("div", { staticClass: "operationPaging" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }