var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("contextmenu", { attrs: { menu: _vm.contextmenus } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.previewLoading,
            expression: "previewLoading"
          }
        ],
        staticClass: "attachmentItem",
        attrs: {
          title: _vm.attachment.name,
          "element-loading-text": _vm.$t("loading"),
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 0.2)"
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.previewFile.apply(null, arguments)
          }
        }
      },
      [
        _vm.showDelete
          ? _c(
              "div",
              {
                staticClass: "closeItem",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.deleteAttachment.apply(null, arguments)
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "attachmentIcon" },
          [
            _c("el-image", {
              staticStyle: { height: "100%" },
              attrs: {
                src: _vm.getIconByFileName(_vm.attachment.name),
                fit: "cover"
              }
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.percentCompleted > -1 && _vm.percentCompleted < 100,
                    expression:
                      "percentCompleted > -1 && percentCompleted < 100"
                  }
                ],
                staticClass: "loadingView"
              },
              [
                _c("el-image", {
                  staticStyle: { height: "100%" },
                  attrs: {
                    src: require("../../../../../../assets/images/email/read/download.png"),
                    fit: "cover"
                  }
                }),
                _c("div", { staticClass: "percentCompletedText" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(`${_vm.percentCompleted}%`) +
                      "\n        "
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "attachmentName" }, [
          _vm._v(_vm._s(_vm.attachment.name))
        ]),
        _c("div", { staticClass: "attachmentInfo" }, [
          _vm._v("(" + _vm._s(_vm.changeLimit(_vm.attachment.size)) + ")")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }