var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "publicList" }, [
    !_vm.hiddenListHeader
      ? _c("div", { staticClass: "listHeader" }, [
          _c(
            "div",
            { staticClass: "leftBox" },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("selectAll"),
                    placement: "top"
                  }
                },
                [
                  _c("el-checkbox", {
                    style: {
                      "margin-right": _vm.showOperatePopover ? "5px" : "15px"
                    },
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll"
                    }
                  })
                ],
                1
              ),
              _vm.showOperatePopover
                ? [
                    _c(
                      "span",
                      { staticClass: "currentListType" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.currentListsProperties) +
                            "\n          "
                        ),
                        _vm.currentListCount > 0
                          ? [
                              _vm._v(
                                "\n            (" +
                                  _vm._s(_vm.currentListCount) +
                                  ")\n          "
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top",
                          trigger: "click",
                          "visible-arrow": false,
                          "popper-class": _vm.operatePopoverClass
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "operateList" },
                          _vm._l(_vm.operateBottons, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "buttonStyle",
                                class: {
                                  activebutton: _vm.listsProperties == item.type
                                },
                                attrs: { title: item.title },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.selectedListsProperties(item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.title) +
                                    "\n            "
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _c("el-image", {
                          staticStyle: { width: "10px", height: "6px" },
                          attrs: {
                            slot: "reference",
                            src: require("../../../../../../assets/images/email/arrow.png"),
                            fit: "cover"
                          },
                          slot: "reference"
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "div",
                { staticClass: "listActionBar" },
                _vm._l(_vm.actionBars, function(bar, index) {
                  return _c(
                    "el-tooltip",
                    {
                      key: index,
                      attrs: {
                        effect: "dark",
                        content:
                          (_vm.showType == "mark" || _vm.pageDataAllMarked) &&
                          bar.name == "star_grey"
                            ? bar.markTitle
                            : bar.title,
                        placement: "top"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "actionBarButton",
                          style: {
                            "margin-right":
                              index == _vm.actionBars.length - 1
                                ? "0px"
                                : "30px"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.checkActionBar(bar)
                            }
                          }
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src:
                                _vm.checkedLists.length == 0
                                  ? bar.disabledImg
                                  : (_vm.showType == "mark" ||
                                      _vm.pageDataAllMarked) &&
                                    bar.name == "star_grey"
                                  ? bar.brightImg
                                  : bar.img,
                              fit: "cover"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            ],
            2
          ),
          _c("div", { staticClass: "rightBox" }, [
            _c("span", { staticClass: "pageInfo" }, [
              _vm._v(
                " " + _vm._s(_vm.page) + " of " + _vm._s(_vm.totalPage) + " "
              )
            ]),
            _c("div", { staticClass: "operationPaging" }, [
              _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.prePage.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "iconfont_Me icon-left iconStyle",
                    style: {
                      color: _vm.notPre ? "#D9D9D9" : "#858585",
                      cursor: _vm.notPre ? "not-allowed" : "pointer"
                    }
                  })
                ]
              ),
              _c("div", { staticClass: "inputOut" }, [
                _c(
                  "div",
                  { staticClass: "inputBox" },
                  [
                    _c("el-input", {
                      nativeOn: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.jumpPage.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.nowPage,
                        callback: function($$v) {
                          _vm.nowPage = $$v
                        },
                        expression: "nowPage"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.nextPage.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "iconfont_Me icon-right iconStyle",
                    style: {
                      color: _vm.notNext ? "#D9D9D9" : "#858585",
                      cursor: _vm.notNext ? "not-allowed" : "pointer"
                    }
                  })
                ]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm.showType == "spam" && _vm.listData.length != 0
      ? _c("div", { staticClass: "spamTips" }, [
          _c(
            "div",
            { staticClass: "tipsIcon" },
            [
              _c("el-image", {
                staticStyle: { width: "15px", height: "15px" },
                attrs: {
                  src: require("../../../../../../assets/images/email/send/tipsIcon.png"),
                  fit: "cover"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "tipsText" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("spamTips")) + "\n    ")
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "listBody" },
      [
        _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "listLoading",
          style: {
            height: _vm.listLoading ? "25px" : "0px"
          },
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255,250,250,0.1)",
            "element-loading-text": _vm.$t("pullTheLatestData")
          }
        }),
        _vm.listData.length != 0
          ? _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedItemChange },
                model: {
                  value: _vm.checkedLists,
                  callback: function($$v) {
                    _vm.checkedLists = $$v
                  },
                  expression: "checkedLists"
                }
              },
              _vm._l(_vm.listData, function(item) {
                return _c(
                  "div",
                  { key: item.uniqueIdentifier, staticClass: "listItem" },
                  [
                    _c("el-checkbox", {
                      attrs: { label: item.uniqueIdentifier }
                    }),
                    _vm.showType != "drafts"
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: item.mark
                                ? _vm.$t("cancelMark")
                                : _vm.$t("markEmail"),
                              placement: "top"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mark",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.changeSingleMark(item)
                                  }
                                }
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%"
                                  },
                                  attrs: {
                                    src: item.mark
                                      ? _vm.markImg.mark
                                      : _vm.markImg.unmark,
                                    fit: "cover"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "nicknameItem",
                        style: {
                          width: _vm.IMWindowSize === "full" ? "350px" : "200px"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goReadEmail(item)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "statusButton" },
                          [
                            item.mailStatus == 0
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("unreadMail"),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: require("../../../../../../assets/images/email/list/unread_email.png"),
                                        fit: "cover"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.readSingleEmail(item)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.mailStatus == "2-1"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("hasBeenSent"),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: require("../../../../../../assets/images/email/list/hasBeenSent.png"),
                                        fit: "cover"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.mailStatus == "2-2"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("isSending"),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: require("../../../../../../assets/images/email/list/sending.png"),
                                        fit: "cover"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.mailStatus == "2-3"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("sendFail"),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: require("../../../../../../assets/images/email/list/sendFail.png"),
                                        fit: "cover"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.mailStatus == 3
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("isDraftEmail"),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: require("../../../../../../assets/images/email/list/notSent.png"),
                                        fit: "cover"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.mailStatus == 4
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("repliedAndForwarded"),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: require("../../../../../../assets/images/email/list/repliedAndForwarded.png"),
                                        fit: "cover"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.mailStatus == 5
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("repliedEmail"),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: require("../../../../../../assets/images/email/list/repliedEmail.png"),
                                        fit: "cover"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.mailStatus == 6
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("forwardedEmail"),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: require("../../../../../../assets/images/email/list/forwardedEmail.png"),
                                        fit: "cover"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          { attrs: { placement: "top", enterable: false } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                item.contactName
                                  ? [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.contactName)
                                      ),
                                      _c("br")
                                    ]
                                  : _vm._e(),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.showNickname(_vm.showType, item)
                                    ) +
                                    "\n            "
                                )
                              ],
                              2
                            ),
                            _c("div", { staticClass: "nickname" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.handlerNickname(item)) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "lineContent",
                        style: {
                          "padding-left":
                            _vm.IMWindowSize === "full" ? "" : "15px",
                          "justify-content":
                            _vm.IMWindowSize === "full"
                              ? "flex-start"
                              : "space-between"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "emailTitle",
                            style: {
                              flex: _vm.IMWindowSize === "full" ? "" : 1,
                              "min-width": _vm.IMWindowSize === "full" ? "" : 0
                            },
                            attrs: { title: item.subject },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goReadEmail(item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.subject) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "emailStatusBox" }, [
                          _c(
                            "div",
                            {
                              staticClass: "classificationStatus",
                              style: {
                                "margin-right":
                                  item.attachments &&
                                  item.attachments.length > 0
                                    ? "10px"
                                    : ""
                              },
                              attrs: { title: _vm.$t("classification") },
                              on: {
                                click: function($event) {
                                  return _vm.clickClassification(item)
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "iconfont_Me icon-wenjianjia",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#fff"
                                }
                              })
                            ]
                          ),
                          item.attachments && item.attachments.length > 0
                            ? _c(
                                "div",
                                { staticClass: "attachmentStatus" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t("attachment"),
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%"
                                        },
                                        attrs: {
                                          src: require("../../../../../../assets/images/email/list/attachment.png"),
                                          fit: "cover"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "emailDate" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.formatTimestamp(item.sendTimestamp)) +
                          "\n        "
                      )
                    ])
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "placeholderMap" },
              [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }