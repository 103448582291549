var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: { effect: "dark", content: _vm.$t("image"), placement: "top" }
    },
    [
      _c(
        "div",
        {
          staticClass: "uploadImageButton",
          on: { click: _vm.uploadMelinkedImage }
        },
        [
          _c(
            "svg",
            {
              staticClass: "fa-icon",
              attrs: {
                "aria-hidden": "true",
                width: "16",
                height: "16",
                viewBox: "0 0 512 512",
                focusable: "false"
              }
            },
            [
              _c("g", [
                _c("path", {
                  attrs: {
                    d:
                      "M464 448h-416c-26.5 0-48-21.5-48-48v-288c0-26.5 21.5-48 48-48h416c26.5 0 48 21.5 48 48v288c0 26.5-21.5 48-48 48zM112 120c-30.9 0-56 25.1-56 56s25.1 56 56 56 56-25.1 56-56-25.1-56-56-56zM64 384h384v-112l-87.5-87.5c-4.7-4.7-12.3-4.7-17 0l-135.5 135.5-55.5-55.5c-4.7-4.7-12.3-4.7-17 0l-71.5 71.5v48z"
                  }
                })
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }