var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.primaryTreeLoadng,
          expression: "primaryTreeLoadng"
        }
      ],
      staticClass: "tree-menu",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255,255,255,0.6)"
      }
    },
    _vm._l(_vm.menuItems, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          on: {
            click: function($event) {
              return _vm.toggleSubMenu(index)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "menu-item", class: { active: item.open } },
            [
              _vm.showUreadIcon(item)
                ? _c("div", { staticClass: "ureadIcon" })
                : _vm._e(),
              _c("div", { staticClass: "menu-content" }, [
                _c(
                  "span",
                  {
                    staticClass: "menu-title",
                    style: {
                      color: item.open ? "#292D32" : "#65696E",
                      "font-weight": item.open ? "bold" : ""
                    },
                    attrs: {
                      title: `${_vm.handlerName(item)}(${item.mailAddress})`
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.handlerName(item)) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "menu-note",
                    style: { color: item.open ? "#858585" : "#65696E" },
                    attrs: {
                      title: `${_vm.handlerName(item)}(${item.mailAddress})`
                    }
                  },
                  [
                    _vm._v(
                      "\n          (" + _vm._s(item.mailAddress) + ")\n        "
                    )
                  ]
                )
              ]),
              _c("el-image", {
                style: {
                  transform: item.open ? "rotate(0deg)" : "rotate(-90deg)",
                  transition: "transform .3s",
                  width: "10px",
                  height: "6px"
                },
                attrs: {
                  src: require("../../../../../assets/images/email/arrow.png"),
                  fit: "cover"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.secondaryTreeLoadng,
                  expression: "secondaryTreeLoadng"
                }
              ],
              staticClass: "sub-menu",
              style: {
                height: item.open ? _vm.subMenuHeight : "0px",
                "border-bottom": item.open ? "1px solid #EDEDED" : ""
              },
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255,0.5)"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return (() => false).apply(null, arguments)
                }
              }
            },
            _vm._l(item.subMenu, function(subItem, subIndex) {
              return _c(
                "div",
                {
                  key: subIndex,
                  staticClass: "sub-menu-item",
                  style: {
                    background: _vm.showSubActive(item, subItem)
                      ? "#E4F8E5"
                      : "#FFFFFF"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.selectMenu(index, subIndex)
                    }
                  }
                },
                [
                  _vm.showSubActive(item, subItem)
                    ? _c("div", { staticClass: "menu-line" })
                    : _vm._e(),
                  _c("el-image", {
                    style: {
                      width: subItem.iconWidth
                        ? `${subItem.iconWidth}px`
                        : "18px",
                      height: subItem.iconHeight
                        ? `${subItem.iconHeight}px`
                        : "18px"
                    },
                    attrs: { src: subItem.icon, fit: "cover" }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "sub-menu-title",
                      style: { color: "#000000" }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(subItem.title) + "\n          "
                      ),
                      _vm.showInboxUnreadCount(subItem)
                        ? [
                            _c("span", { staticClass: "unreadIcon" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.handleUnreadIcon(
                                      subItem.localUnreadCount ||
                                        subItem.unreadMessageCount
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]
                        : _vm.showMessageCount(subItem)
                        ? [
                            _vm.handlerMessageCount(subItem) != 0
                              ? _c("span", { staticClass: "countIcon" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.handlerMessageCount(subItem)) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            }),
            0
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }