import { Extension } from "tiptap";
import { Image } from "element-tiptap";
import UploadImageButton from "./uploadImage.vue";

export default class UploadImageExtension extends Image {
  constructor(fn) {
    super();
    this.fn = fn;
  }
  menuBtnView({ isActive, commands, focus, editor }) {
    return {
      component: UploadImageButton,
      componentProps: {},
      componentEvents: {
        uploadMelinkedImage: () => {
          if (this.fn) {
            this.fn();
          }
        },
      },
    };
  }
}
