var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sendEmailBox" },
    [
      _vm.editorLoading
        ? _c(
            "div",
            { staticClass: "uploadProgress" },
            [
              _c("el-progress", {
                staticClass: "progressInner",
                attrs: {
                  type: "circle",
                  percentage: _vm.uploadPercent,
                  width: 60,
                  color: "#F39945"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.largeAttachmentProgress
        ? _c(
            "div",
            { staticClass: "uploadProgress" },
            [
              _c("el-progress", {
                staticClass: "progressInner",
                attrs: {
                  type: "circle",
                  percentage: Number(_vm.largeAttachmentProgress.progress),
                  width: 60,
                  color: "#F39945"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "operationArea" }, [
        _c(
          "div",
          { staticClass: "leftBox" },
          [
            _c("span", {
              staticClass: "iconfont_Me icon-return backStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            }),
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.$t("sending"),
                  placement: "top"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "sendEmail",
                    class: _vm.disable ? "disableStyle" : "",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.sendEmail(false)
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "iconfont_Me icon-send-fill sendStyle"
                    })
                  ]
                )
              ]
            ),
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.$t("cancelSending"),
                  placement: "top"
                }
              },
              [
                _c("el-image", {
                  staticStyle: { width: "20px", height: "20px" },
                  attrs: {
                    src: require("../../../../../../assets/images/email/send/doNotSave.png"),
                    fit: "cover"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.cancelSave.apply(null, arguments)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "centerBox" },
          [
            _vm.selectedFrom
              ? _c(
                  "div",
                  {
                    staticClass: "menu-content",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.openSelectModel.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "menu-title",
                        attrs: { title: _vm.selectedFrom.name }
                      },
                      [_vm._v(_vm._s(_vm.selectedFrom.name))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menu-note",
                        attrs: { title: _vm.selectedFrom.mailAddress }
                      },
                      [
                        _vm._v(
                          "\n          <" +
                            _vm._s(_vm.selectedFrom.mailAddress) +
                            ">\n        "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "el-select",
              {
                ref: "emailFromSelector",
                attrs: { "popper-class": "selectEmailFrom" },
                model: {
                  value: _vm.emailFromModel,
                  callback: function($$v) {
                    _vm.emailFromModel = $$v
                  },
                  expression: "emailFromModel"
                }
              },
              _vm._l(_vm.emailFromOptions, function(item) {
                return _c(
                  "el-option",
                  {
                    key: item.originProviderId,
                    attrs: { label: item.name, value: item.originProviderId }
                  },
                  [
                    _c("div", { staticClass: "selectEmailFromItem" }, [
                      _c(
                        "span",
                        {
                          staticClass: "EmailFromName commonItemStyle",
                          attrs: { title: item.name }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.name) +
                              "\n            "
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "EmailFromContent commonItemStyle",
                          attrs: { title: item.mailAddress }
                        },
                        [
                          _vm._v(
                            "\n              <" +
                              _vm._s(item.mailAddress) +
                              ">\n            "
                          )
                        ]
                      )
                    ])
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "windowChangeBox" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.IMWindowSize != "mini",
                  expression: "this.IMWindowSize != 'mini'"
                }
              ],
              staticClass: "imelink-miniscreen",
              on: {
                click: function($event) {
                  return _vm.changeScreen("mini")
                }
              }
            },
            [_c("SizeButton", { attrs: { buttonType: "minimize" } })],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.IMWindowSize == "medium",
                  expression: "this.IMWindowSize == 'medium'"
                }
              ],
              staticClass: "imelink-fullscreen",
              on: {
                click: function($event) {
                  return _vm.changeScreen("full")
                }
              }
            },
            [_c("SizeButton")],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.IMWindowSize == "full",
                  expression: "this.IMWindowSize == 'full'"
                }
              ],
              staticClass: "imelink-fullscreen",
              on: {
                click: function($event) {
                  return _vm.changeScreen("medium")
                }
              }
            },
            [_c("SizeButton", { attrs: { buttonType: "full" } })],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "containerBox" }, [
        _c("div", { staticClass: "inputArea" }, [
          _c(
            "div",
            { staticClass: "innerInputBox" },
            _vm._l(_vm.lineInputs, function(item) {
              return _c("div", { key: item.key, staticClass: "lineBox" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(item.title) + " : ")
                ]),
                _c(
                  "div",
                  { staticClass: "lineContent" },
                  [
                    item.key == 0
                      ? [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                trigger: "manual",
                                "popper-class": "recipientPopover"
                              },
                              model: {
                                value: _vm.recipientPopover,
                                callback: function($$v) {
                                  _vm.recipientPopover = $$v
                                },
                                expression: "recipientPopover"
                              }
                            },
                            [
                              _vm.recipientPromptTexts.length > 0
                                ? [
                                    _c("PopoverList", {
                                      ref: "recipientPopoverList",
                                      refInFor: true,
                                      attrs: {
                                        popoverWidth: _vm.recipientPopoverWidth,
                                        promptTexts: _vm.recipientPromptTexts
                                      },
                                      on: { clickItem: _vm.clickRecipient }
                                    })
                                  ]
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  ref: "recipientInputBox",
                                  refInFor: true,
                                  staticClass: "lineBoxInputStyle",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "lineBoxInner",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.clickInputOutBox(
                                            item.inputRef
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          staticClass: "itemList",
                                          attrs: {
                                            animation: "1000",
                                            group: {
                                              name: "emails",
                                              pull: true,
                                              put: true
                                            },
                                            sort: false,
                                            touchStartThreshold: "0px",
                                            ghostClass: "draggablegGhostClass"
                                          },
                                          model: {
                                            value: _vm.recipients,
                                            callback: function($$v) {
                                              _vm.recipients = $$v
                                            },
                                            expression: "recipients"
                                          }
                                        },
                                        [
                                          _vm._l(_vm.recipients, function(
                                            recipient
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: recipient.contactsId,
                                                staticClass: "item",
                                                style: {
                                                  border: recipient.isEmail
                                                    ? "none"
                                                    : "1px solid red"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "nickname commonStyle",
                                                    style: {
                                                      color: recipient.isEmail
                                                        ? ""
                                                        : "red"
                                                    },
                                                    attrs: {
                                                      title: recipient.name
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(recipient.name) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "email commonStyle",
                                                    style: {
                                                      color: recipient.isEmail
                                                        ? ""
                                                        : "red"
                                                    },
                                                    attrs: {
                                                      title: recipient.email
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          <" +
                                                        _vm._s(
                                                          recipient.email
                                                        ) +
                                                        ">\n                        "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "delete",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.deleteRecipient(
                                                          recipient
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "iconfont_Me icon-x iconStyle"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.recipientEmailText,
                                                expression: "recipientEmailText"
                                              }
                                            ],
                                            ref: item.inputRef,
                                            refInFor: true,
                                            staticClass: "itemInput",
                                            domProps: {
                                              value: _vm.recipientEmailText
                                            },
                                            on: {
                                              blur: function($event) {
                                                $event.stopPropagation()
                                                return _vm.addRecipientEmail(
                                                  "blur"
                                                )
                                              },
                                              keydown: [
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.addRecipientEmail(
                                                    "keydown"
                                                  )
                                                },
                                                function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handlerRecipientKeydown.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "up",
                                                      38,
                                                      $event.key,
                                                      ["Up", "ArrowUp"]
                                                    )
                                                  )
                                                    return null
                                                  return (event => {
                                                    _vm.handlerKeyUp(
                                                      event,
                                                      "recipientPopoverList",
                                                      "recipientPromptTexts"
                                                    )
                                                  }).apply(null, arguments)
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "down",
                                                      40,
                                                      $event.key,
                                                      ["Down", "ArrowDown"]
                                                    )
                                                  )
                                                    return null
                                                  return (event => {
                                                    _vm.handlerKeyDown(
                                                      event,
                                                      "recipientPopoverList",
                                                      "recipientPromptTexts"
                                                    )
                                                  }).apply(null, arguments)
                                                }
                                              ],
                                              compositionstart:
                                                _vm.handlerCompositionstart,
                                              compositionend:
                                                _vm.handlerCompositionend,
                                              input: [
                                                function($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.recipientEmailText =
                                                    $event.target.value
                                                },
                                                _vm.inputRecipientEmail
                                              ]
                                            }
                                          })
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "addIcon",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.addRecipient.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%"
                                            },
                                            attrs: {
                                              src: require("../../../../../../assets/images/email/send/add.png"),
                                              fit: "cover"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ]
                      : _vm._e(),
                    item.key == 1
                      ? [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                trigger: "manual",
                                "popper-class": "ccAndbccPopover"
                              },
                              model: {
                                value: _vm.ccPopover,
                                callback: function($$v) {
                                  _vm.ccPopover = $$v
                                },
                                expression: "ccPopover"
                              }
                            },
                            [
                              _vm.ccPromptTexts.length > 0
                                ? [
                                    _c("PopoverList", {
                                      ref: "ccPopoverList",
                                      refInFor: true,
                                      attrs: {
                                        popoverWidth: _vm.ccPopoverWidth,
                                        promptTexts: _vm.ccPromptTexts
                                      },
                                      on: {
                                        clickItem: item => {
                                          _vm.clickCcOrBcc(item, "cc")
                                        }
                                      }
                                    })
                                  ]
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  ref: "ccInputBox",
                                  refInFor: true,
                                  staticClass: "lineBoxInputStyle",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "lineBoxInner",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.clickInputOutBox(
                                            item.inputRef
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          staticClass: "itemList",
                                          attrs: {
                                            animation: "1000",
                                            group: {
                                              name: "emails",
                                              pull: true,
                                              put: true
                                            },
                                            sort: false,
                                            touchStartThreshold: "0px",
                                            ghostClass: "draggablegGhostClass"
                                          },
                                          model: {
                                            value: _vm.Ccs,
                                            callback: function($$v) {
                                              _vm.Ccs = $$v
                                            },
                                            expression: "Ccs"
                                          }
                                        },
                                        [
                                          _vm._l(_vm.Ccs, function(Cc, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "item",
                                                style: {
                                                  border: Cc.isEmail
                                                    ? "none"
                                                    : "1px solid red"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "nickname commonStyle",
                                                    style: {
                                                      color: Cc.isEmail
                                                        ? ""
                                                        : "red"
                                                    },
                                                    attrs: { title: Cc.name }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(Cc.name) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "email commonStyle",
                                                    style: {
                                                      color: Cc.isEmail
                                                        ? ""
                                                        : "red"
                                                    },
                                                    attrs: { title: Cc.email }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          <" +
                                                        _vm._s(Cc.email) +
                                                        ">\n                        "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "delete",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.deleteCcOrBcc(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "iconfont_Me icon-x iconStyle"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.CcEmailText,
                                                expression: "CcEmailText"
                                              }
                                            ],
                                            ref: item.inputRef,
                                            refInFor: true,
                                            staticClass: "itemInput",
                                            domProps: {
                                              value: _vm.CcEmailText
                                            },
                                            on: {
                                              blur: function($event) {
                                                $event.stopPropagation()
                                                return _vm.addCcOrBccEmail(
                                                  "blur"
                                                )
                                              },
                                              keydown: [
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.addCcOrBccEmail(
                                                    "keydown"
                                                  )
                                                },
                                                function($event) {
                                                  $event.stopPropagation()
                                                  return (event => {
                                                    _vm.handlerCcOrBccKeydown(
                                                      event,
                                                      "cc"
                                                    )
                                                  }).apply(null, arguments)
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "up",
                                                      38,
                                                      $event.key,
                                                      ["Up", "ArrowUp"]
                                                    )
                                                  )
                                                    return null
                                                  return (event => {
                                                    _vm.handlerKeyUp(
                                                      event,
                                                      "ccPopoverList",
                                                      "ccPromptTexts"
                                                    )
                                                  }).apply(null, arguments)
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "down",
                                                      40,
                                                      $event.key,
                                                      ["Down", "ArrowDown"]
                                                    )
                                                  )
                                                    return null
                                                  return (event => {
                                                    _vm.handlerKeyDown(
                                                      event,
                                                      "ccPopoverList",
                                                      "ccPromptTexts"
                                                    )
                                                  }).apply(null, arguments)
                                                }
                                              ],
                                              compositionstart:
                                                _vm.handlerCompositionstart,
                                              compositionend:
                                                _vm.handlerCompositionend,
                                              input: [
                                                function($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.CcEmailText =
                                                    $event.target.value
                                                },
                                                function($event) {
                                                  return _vm.inputCcOrBccEmail(
                                                    "cc"
                                                  )
                                                }
                                              ]
                                            }
                                          })
                                        ],
                                        2
                                      ),
                                      !_vm.showBccInput
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "bccButton",
                                              on: { click: _vm.startInputBcc }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(_vm.$t("bcc")) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "addIcon",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.addCcOrBcc("cc")
                                            }
                                          }
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%"
                                            },
                                            attrs: {
                                              src: require("../../../../../../assets/images/email/send/add.png"),
                                              fit: "cover"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ]
                      : _vm._e(),
                    item.key == 2
                      ? [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                trigger: "manual",
                                "popper-class": "ccAndbccPopover"
                              },
                              model: {
                                value: _vm.bccPopover,
                                callback: function($$v) {
                                  _vm.bccPopover = $$v
                                },
                                expression: "bccPopover"
                              }
                            },
                            [
                              _vm.bccPromptTexts.length > 0
                                ? [
                                    _c("PopoverList", {
                                      ref: "bccPopoverList",
                                      refInFor: true,
                                      attrs: {
                                        popoverWidth: _vm.bccPopoverWidth,
                                        promptTexts: _vm.bccPromptTexts
                                      },
                                      on: {
                                        clickItem: item => {
                                          _vm.clickCcOrBcc(item, "bcc")
                                        }
                                      }
                                    })
                                  ]
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  ref: "bccInputBox",
                                  refInFor: true,
                                  staticClass: "lineBoxInputStyle",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "lineBoxInner",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.clickInputOutBox(
                                            item.inputRef
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          staticClass: "itemList",
                                          attrs: {
                                            animation: "1000",
                                            group: {
                                              name: "emails",
                                              pull: true,
                                              put: true
                                            },
                                            sort: false,
                                            touchStartThreshold: "0px",
                                            ghostClass: "draggablegGhostClass"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.clickInputOutBox(
                                                item.inputRef
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.Bccs,
                                            callback: function($$v) {
                                              _vm.Bccs = $$v
                                            },
                                            expression: "Bccs"
                                          }
                                        },
                                        [
                                          _vm._l(_vm.Bccs, function(
                                            bcc,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "item",
                                                style: {
                                                  border: bcc.isEmail
                                                    ? "none"
                                                    : "1px solid red"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "nickname commonStyle",
                                                    style: {
                                                      color: bcc.isEmail
                                                        ? ""
                                                        : "red"
                                                    },
                                                    attrs: { title: bcc.name }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(bcc.name) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "email commonStyle",
                                                    style: {
                                                      color: bcc.isEmail
                                                        ? ""
                                                        : "red"
                                                    },
                                                    attrs: { title: bcc.email }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          <" +
                                                        _vm._s(bcc.email) +
                                                        ">\n                        "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "delete",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.deleteCcOrBcc(
                                                          index,
                                                          "bcc"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "iconfont_Me icon-x iconStyle"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.BccEmailText,
                                                expression: "BccEmailText"
                                              }
                                            ],
                                            ref: item.inputRef,
                                            refInFor: true,
                                            staticClass: "itemInput",
                                            domProps: {
                                              value: _vm.BccEmailText
                                            },
                                            on: {
                                              blur: function($event) {
                                                $event.stopPropagation()
                                                return _vm.addCcOrBccEmail(
                                                  "blur",
                                                  "bcc"
                                                )
                                              },
                                              keydown: [
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.addCcOrBccEmail(
                                                    "keydown",
                                                    "bcc"
                                                  )
                                                },
                                                function($event) {
                                                  $event.stopPropagation()
                                                  return (event => {
                                                    _vm.handlerCcOrBccKeydown(
                                                      event,
                                                      "bcc"
                                                    )
                                                  }).apply(null, arguments)
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "up",
                                                      38,
                                                      $event.key,
                                                      ["Up", "ArrowUp"]
                                                    )
                                                  )
                                                    return null
                                                  return (event => {
                                                    _vm.handlerKeyUp(
                                                      event,
                                                      "bccPopoverList",
                                                      "bccPromptTexts"
                                                    )
                                                  }).apply(null, arguments)
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "down",
                                                      40,
                                                      $event.key,
                                                      ["Down", "ArrowDown"]
                                                    )
                                                  )
                                                    return null
                                                  return (event => {
                                                    _vm.handlerKeyDown(
                                                      event,
                                                      "bccPopoverList",
                                                      "bccPromptTexts"
                                                    )
                                                  }).apply(null, arguments)
                                                }
                                              ],
                                              compositionstart:
                                                _vm.handlerCompositionstart,
                                              compositionend:
                                                _vm.handlerCompositionend,
                                              input: [
                                                function($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.BccEmailText =
                                                    $event.target.value
                                                },
                                                function($event) {
                                                  return _vm.inputCcOrBccEmail(
                                                    "bcc"
                                                  )
                                                }
                                              ]
                                            }
                                          })
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "addIcon",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.addCcOrBcc("bcc")
                                            }
                                          }
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%"
                                            },
                                            attrs: {
                                              src: require("../../../../../../assets/images/email/send/add.png"),
                                              fit: "cover"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "closeIcon",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.deleteBccInput.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont_Me icon-x"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ]
                      : _vm._e(),
                    item.key == 3
                      ? [
                          _c("el-input", {
                            ref: item.inputRef,
                            refInFor: true,
                            style: {
                              height: "26px"
                            },
                            attrs: { maxlength: "120" },
                            model: {
                              value: _vm.emailSubject,
                              callback: function($$v) {
                                _vm.emailSubject = $$v
                              },
                              expression: "emailSubject"
                            }
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            }),
            0
          ),
          _c("div", { staticClass: "sizeButtonBox" }, [
            _c("i", {
              staticClass: "iconfont_Me iconStyle",
              class: [_vm.hiddenInputArea ? "icon-down" : "icon-up"],
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.changeInputAreaSize.apply(null, arguments)
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "richTextBox" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "resize-ob",
                  rawName: "v-resize-ob",
                  value: _vm.resizeEditorBox,
                  expression: "resizeEditorBox"
                }
              ],
              staticClass: "richTextCore"
            },
            [
              _c("Eleditor", {
                staticClass: "el-editorInner",
                attrs: { editorModel: _vm.editorModel },
                on: {
                  ready: _vm.onEditorReady,
                  change: _vm.onEditorChange,
                  blur: _vm.onEditorBlur,
                  focus: _vm.onEditorFocus,
                  uploadAttachment: _vm.richAttachment,
                  addOriginalAttachment: _vm.addOriginalAttachment
                },
                scopedSlots: _vm._u([
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _vm.waitingUploadedFiles.length != 0 || _vm.downLoading
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.downLoading,
                                    expression: "downLoading"
                                  }
                                ],
                                staticClass: "emailAttachment",
                                attrs: {
                                  "element-loading-spinner": "el-icon-loading",
                                  "element-loading-background": "#FFFFFF"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "attachmentCore" },
                                  _vm._l(
                                    _vm.handleWaitingUploadedFiles,
                                    function(attachment, index) {
                                      return _c("EmailAttachmentItem", {
                                        key: index,
                                        attrs: {
                                          showDelete: true,
                                          attachment: attachment,
                                          operationStatus: false
                                        },
                                        on: {
                                          deleteAttachment: _vm.deleteAttachment
                                        }
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _vm.waitingUploadedFiles.length >
                                _vm.attachmentsSliceNumber
                                  ? _c("div", { staticClass: "displayFiles" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "displayImg",
                                          on: {
                                            click: _vm.changeAttachmentList
                                          }
                                        },
                                        [
                                          _c("img", {
                                            style: {
                                              transform: _vm.attachmentListStatus
                                                ? "rotate(180deg)"
                                                : ""
                                            },
                                            attrs: {
                                              src: require("../../../../../../assets/images/email/drop_down .png")
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "displayText",
                                          on: {
                                            click: _vm.changeAttachmentList
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.attachmentListStatus
                                                  ? _vm.$t("hide")
                                                  : _vm.$t(
                                                      "displayAttachments",
                                                      {
                                                        m: _vm.$t("show"),
                                                        n:
                                                          _vm
                                                            .waitingUploadedFiles
                                                            .length
                                                      }
                                                    )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c("el-upload", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "uploadAttachment",
        attrs: {
          action: "/",
          multiple: true,
          "show-file-list": false,
          "http-request": _vm.handlerUpload,
          "before-upload": _vm.beforeFileUpload
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }