<template>
  <div class="bindEmailAccountView">
    <div class="viewHeader" v-if="emailAccounts.length != 0">
      <span
        @click.stop="goBack"
        class="iconfont_Me icon-return iconStyle"
      ></span>
    </div>
    <div class="viewContent">
      <div class="centerBox">
        <div class="header">
          <div class="title">
            {{ $t("emailBinding") }}
          </div>
          <div class="tips">({{ $t("emailBindingTips") }})</div>
        </div>
        <div class="inputBox" v-resize-ob="windowResize">
          <el-form @submit.stop="() => false">
            <div class="input">
              <el-form-item prop="userName">
                <CommonInput @inputChange="getEmailText">
                  <el-image
                    :style="imageIconStyle"
                    :src="
                      require('../../../../../../assets/images/email/loginDialog/email_not_selected.png')
                    "
                    :fit="'cover'"
                  ></el-image>
                </CommonInput>
              </el-form-item>
            </div>
            <div class="input">
              <el-form-item prop="emailPassword">
                <CommonInput
                  @inputChange="getPasswordText"
                  :passwordInput="true"
                >
                  <span
                    class="iconfont_Me icon-password-fill iconStyle"
                    :style="fontIconStyle"
                  ></span>
                </CommonInput>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footer">
          <div
            class="buttonStyle"
            :class="{ disabledStyle: disabled }"
            v-waves
            @click.stop="startBind"
          >
            <span class="iconfont_Me icon-check iconStyle"></span>
          </div>
          <div class="unregisteredTips">
            <span @click.stop="goRegister">{{ $t("noEmail") }}</span
            >>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonInput from "../../../../../../newVersion/components/inputComponent/commonInput.vue";
import basicMixin from "./basic";
import {
  startBindEmail,
  unBindEmail,
  getEmailInfoByAddress,
} from "@/api/newVersion/melinked_email";
import { encryptData } from "@/utils/aes_email";
import { setSecretKeyByOriginId } from "@/api/indexDB/api/email_secretKey_api";
import { env } from "@/utils";
export default {
  mixins: [basicMixin],
  components: {
    CommonInput,
  },
  computed: {
    emailAccounts() {
      return this.$store.state.melinked_email.emailAccounts;
    },
    disabled() {
      return this.username == "" || this.password == "";
    },
  },
  props: {
    defaultBindText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      password: "",
      username: "",
      mailOriginProviderId: "",
    };
  },
  methods: {
    goBack() {
      this.$store.commit("setEmailHistoryComponentGoBack");
    },
    goRegister() {
      this.username = "";
      this.password = "";
      this.$store.commit("setIMWindowSize", "mini");
      this.$store.commit("setCurrent_popup_display_status", "register");
      const language = localStorage.getItem("langCode") || 1;
      if (language == 1) {
        this.$store.commit("setEmail_loginAndRegisterDialog", true);
      } else {
        this.routeJump({ name: "emailLoginOrRegistration" });
      }
    },
    async startBind() {
      if (this.disabled) {
        return;
      }
      // const result = await getEmailInfoByAddress(`${this.username}`, {
      //   cancelPermissions: true,
      // });
      await this.handlerBind();
    },
    async handlerBind() {
      let params = {
        password: this.fnencrypt(this.password),
        mailAddress: this.username,
      };
      this.$store.commit("setEmailModuleLoading", true);
      const result = await startBindEmail(params);
      this.$store.commit("setEmailModuleLoading", false);
      if (result.code == 200 && result.data.data) {
        this.mailOriginProviderId = result.data.data.mailOriginProviderId;
        await this.getBindEmailList();
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async getBindEmailList() {
      this.$store.commit("setEmailModuleLoading", true);
      try {
        const resultData = await this.globalGetBindEmailList();
        if (resultData.length == 0) {
          this.$message({
            type: "error",
            message: "邮箱列表为空，绑定失败",
          });
        } else {
          const emailInfo = await this.getEmailInfo(resultData);
          // 将被绑定的邮箱设置成需要默认展开的状态
          this.$store.commit("setNeedOpenEmailAddress", {
            mailAddress: `${this.username}`,
          });
          await this.$nextTick();
          // 判断是否修改过密码
          if (
            emailInfo.mailAddress.indexOf(env("EMAIL_DOMAIN")) == -1 &&
            !emailInfo.updatePassword
          ) {
            this.$store.commit("setTeamWindowCloseFn", async () => {
              const params = {
                password: this.fnencrypt(this.password),
                mailAddress: emailInfo.mailAddress,
              };
              const result = await unBindEmail(params);
              if (result.code == 200) {
                await this.globalGetBindEmailList();
              }
              this.$store.commit("setTeamWindowCloseFn", null);
            });
            this.$store.commit("setMemberUserName", this.username);
            this.$store.commit("setMemberOldPassword", this.password);
            this.$store.commit(
              "setMemberMailOriginProviderId",
              this.mailOriginProviderId
            );
            this.$store.commit("setHideTeamMemberBackButton", true);
            this.showMemberPasswordSetting();
          } else {
            this.$message({
              type: "success",
              message: this.$t("bindingSuccessful"),
            });
            this.$store.commit("setEmailCurrentComponentHistory", "main");
          }
        }
      } catch (error) {}
      this.$store.commit("setEmailModuleLoading", false);
    },
    // 将绑定的邮箱密码信息存储到本地
    async getEmailInfo(emailAccounts) {
      return new Promise(async (resolve, reject) => {
        const result = await getEmailInfoByAddress(`${this.username}`);
        if (result.code == 200) {
          emailAccounts.map(async (account) => {
            // 如果已加入到本地邮箱队列中，则将密码存储起来
            if (account.originProviderId == result.data.data.originProviderId) {
              const handlerData = encryptData(this.password);
              await setSecretKeyByOriginId(
                result.data.data.originProviderId,
                handlerData
              );
            }
          });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        resolve(result.data.data);
      });
    },
    getEmailText(val) {
      this.username = val;
    },
    getPasswordText(val) {
      this.password = val;
    },
  },
};
</script>
<style lang="stylus" scoped>
.bindEmailAccountView
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .viewHeader
    width: 100%;
    height: 55px;
    flex-shrink: 0;
    border-bottom: 1px solid #D9D9D9;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .iconStyle
      font-size: 20px;
      color: #292D32;
      cursor: pointer;
  .viewContent
    flex: 1;
    width: 100%;
    min-height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .centerBox
      height: 100%;
      width: 400px;
      .header
        width: 100%;
        padding-top: 54px;
        overflow: hidden;
        .title
          display: inline;
          font-size: 20px
          color: #292D32;
        .tips
          display: inline;
          font-size: 16px;
          color: #8F8F8F;
      .inputBox
        width: 100%;
        overflow: hidden;
        padding-top: 30px;
        .input
          width: 100%;
          height: 48px;
          margin-bottom: 22px;
          .iconStyle
            color #666666;
            font-size 20px;
      .footer
        padding-top: 3.854vw;
        overflow: hidden;
        width: 100%;
        .disabledStyle
          background: rgba(249, 153, 69, 0.2) !important;
          cursor: not-allowed !important;
        .buttonStyle
          width: 100%;
          height: 48px;
          border-radius: 4px;
          background: #F39945;
          user-select: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconStyle
            color: #FFFFFF;
            font-size: 24px;
        .unregisteredTips
          margin-top: 12px;
          width: 100%;
          text-align: center;
          font-size: 16px;
          color: #F39945;
          span
            cursor: pointer;
</style>
<style lang="stylus">
.bindEmailAccountView
  .inputBox
    .el-form
      width: 100%;
      height: 100%;
      .el-form-item
        height: 100%;
        width: 100%;
        .el-form-item__content
          height: 100%;
          width: 100%;
</style>
