<template>
  <div class="publicList">
    <div class="listHeader" v-if="!hiddenListHeader">
      <div class="leftBox">
        <el-tooltip effect="dark" :content="$t('selectAll')" placement="top">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            :style="{
              'margin-right': showOperatePopover ? '5px' : '15px',
            }"
          ></el-checkbox>
        </el-tooltip>
        <template v-if="showOperatePopover">
          <span class="currentListType">
            {{ currentListsProperties }}
            <template v-if="currentListCount > 0">
              ({{ currentListCount }})
            </template>
          </span>
          <el-popover
            placement="top"
            trigger="click"
            :visible-arrow="false"
            :popper-class="operatePopoverClass"
          >
            <div class="operateList">
              <div
                class="buttonStyle"
                v-for="(item, index) in operateBottons"
                :key="index"
                :class="{ activebutton: listsProperties == item.type }"
                @click.stop="selectedListsProperties(item)"
                :title="item.title"
              >
                {{ item.title }}
              </div>
            </div>
            <el-image
              slot="reference"
              style="width: 10px; height: 6px"
              :src="require('../../../../../../assets/images/email/arrow.png')"
              :fit="'cover'"
            ></el-image>
          </el-popover>
        </template>
        <div class="listActionBar">
          <el-tooltip
            effect="dark"
            :content="
              (showType == 'mark' || pageDataAllMarked) &&
              bar.name == 'star_grey'
                ? bar.markTitle
                : bar.title
            "
            placement="top"
            v-for="(bar, index) in actionBars"
            :key="index"
          >
            <div
              class="actionBarButton"
              :style="{
                'margin-right': index == actionBars.length - 1 ? '0px' : '30px',
              }"
              @click.stop="checkActionBar(bar)"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="
                  checkedLists.length == 0
                    ? bar.disabledImg
                    : (showType == 'mark' || pageDataAllMarked) &&
                      bar.name == 'star_grey'
                    ? bar.brightImg
                    : bar.img
                "
                :fit="'cover'"
              ></el-image>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="rightBox">
        <span class="pageInfo"> {{ page }} of {{ totalPage }} </span>
        <div class="operationPaging">
          <div class="button" @click.stop="prePage">
            <i
              class="iconfont_Me icon-left iconStyle"
              :style="{
                color: notPre ? '#D9D9D9' : '#858585',
                cursor: notPre ? 'not-allowed' : 'pointer',
              }"
            ></i>
          </div>
          <div class="inputOut">
            <div class="inputBox">
              <el-input
                v-model="nowPage"
                @keydown.enter.native="jumpPage"
              ></el-input>
            </div>
          </div>
          <div class="button" @click.stop="nextPage">
            <i
              class="iconfont_Me icon-right iconStyle"
              :style="{
                color: notNext ? '#D9D9D9' : '#858585',
                cursor: notNext ? 'not-allowed' : 'pointer',
              }"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="spamTips" v-if="showType == 'spam' && listData.length != 0">
      <div class="tipsIcon">
        <el-image
          style="width: 15px; height: 15px"
          :src="
            require('../../../../../../assets/images/email/send/tipsIcon.png')
          "
          :fit="'cover'"
        ></el-image>
      </div>
      <div class="tipsText">
        {{ $t("spamTips") }}
      </div>
    </div>
    <div class="listBody">
      <div
        class="listLoading"
        :style="{
          height: listLoading ? '25px' : '0px',
        }"
        v-loading="listLoading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255,250,250,0.1)"
        :element-loading-text="$t('pullTheLatestData')"
      ></div>
      <el-checkbox-group
        v-model="checkedLists"
        @change="handleCheckedItemChange"
        v-if="listData.length != 0"
      >
        <div
          class="listItem"
          v-for="item in listData"
          :key="item.uniqueIdentifier"
        >
          <el-checkbox :label="item.uniqueIdentifier"></el-checkbox>
          <el-tooltip
            effect="dark"
            :content="item.mark ? $t('cancelMark') : $t('markEmail')"
            placement="top"
            v-if="showType != 'drafts'"
          >
            <div class="mark" @click.stop="changeSingleMark(item)">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.mark ? markImg.mark : markImg.unmark"
                :fit="'cover'"
              ></el-image>
            </div>
          </el-tooltip>
          <div
            class="nicknameItem"
            :style="{ width: IMWindowSize === 'full' ? '350px' : '200px' }"
            @click.stop="goReadEmail(item)"
          >
            <div class="statusButton">
              <!-- 未读邮件 -->
              <el-tooltip
                effect="dark"
                :content="$t('unreadMail')"
                placement="top"
                v-if="item.mailStatus == 0"
              >
                <el-image
                  @click.stop="readSingleEmail(item)"
                  style="width: 100%; height: 100%"
                  :src="
                    require('../../../../../../assets/images/email/list/unread_email.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </el-tooltip>
              <!-- 已发送邮件 -->
              <el-tooltip
                effect="dark"
                :content="$t('hasBeenSent')"
                placement="top"
                v-if="item.mailStatus == '2-1'"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    require('../../../../../../assets/images/email/list/hasBeenSent.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </el-tooltip>
              <!-- 正在发送中的邮件 -->
              <el-tooltip
                effect="dark"
                :content="$t('isSending')"
                placement="top"
                v-if="item.mailStatus == '2-2'"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    require('../../../../../../assets/images/email/list/sending.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </el-tooltip>
              <!-- 发送失败的邮件 -->
              <el-tooltip
                effect="dark"
                :content="$t('sendFail')"
                placement="top"
                v-if="item.mailStatus == '2-3'"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    require('../../../../../../assets/images/email/list/sendFail.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </el-tooltip>
              <!-- 草稿箱邮件 -->
              <el-tooltip
                effect="dark"
                :content="$t('isDraftEmail')"
                placement="top"
                v-if="item.mailStatus == 3"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    require('../../../../../../assets/images/email/list/notSent.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </el-tooltip>
              <!-- 回复又转发过 -->
              <el-tooltip
                effect="dark"
                :content="$t('repliedAndForwarded')"
                placement="top"
                v-if="item.mailStatus == 4"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    require('../../../../../../assets/images/email/list/repliedAndForwarded.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </el-tooltip>
              <!-- 回复过 -->
              <el-tooltip
                effect="dark"
                :content="$t('repliedEmail')"
                placement="top"
                v-if="item.mailStatus == 5"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    require('../../../../../../assets/images/email/list/repliedEmail.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </el-tooltip>
              <!-- 转发过 -->
              <el-tooltip
                effect="dark"
                :content="$t('forwardedEmail')"
                placement="top"
                v-if="item.mailStatus == 6"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    require('../../../../../../assets/images/email/list/forwardedEmail.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </el-tooltip>
            </div>
            <el-tooltip placement="top" :enterable="false">
              <div slot="content">
                <template v-if="item.contactName">
                  {{ item.contactName }}<br />
                </template>
                {{ showNickname(showType, item) }}
              </div>
              <div class="nickname">
                {{ handlerNickname(item) }}
              </div>
            </el-tooltip>
          </div>
          <div
            class="lineContent"
            :style="{
              'padding-left': IMWindowSize === 'full' ? '' : '15px',
              'justify-content':
                IMWindowSize === 'full' ? 'flex-start' : 'space-between',
            }"
          >
            <div
              class="emailTitle"
              :title="item.subject"
              @click.stop="goReadEmail(item)"
              :style="{
                flex: IMWindowSize === 'full' ? '' : 1,
                'min-width': IMWindowSize === 'full' ? '' : 0,
              }"
            >
              {{ item.subject }}
            </div>
            <div class="emailStatusBox">
              <div
                class="classificationStatus"
                :title="$t('classification')"
                :style="{
                  'margin-right':
                    item.attachments && item.attachments.length > 0
                      ? '10px'
                      : '',
                }"
                @click="clickClassification(item)"
              >
                <span
                  class="iconfont_Me icon-wenjianjia"
                  style="font-size: 14px; color: #fff"
                ></span>
              </div>
              <div
                class="attachmentStatus"
                v-if="item.attachments && item.attachments.length > 0"
              >
                <el-tooltip
                  effect="dark"
                  :content="$t('attachment')"
                  placement="top"
                >
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="
                      require('../../../../../../assets/images/email/list/attachment.png')
                    "
                    :fit="'cover'"
                  ></el-image>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="emailDate">
            {{ formatTimestamp(item.sendTimestamp) }}
          </div>
        </div>
      </el-checkbox-group>
      <div class="placeholderMap" v-else>
        <PlaceholderMap :placeholderType="2"></PlaceholderMap>
      </div>
    </div>
  </div>
</template>
<script>
import {
  markEmails,
  moveEmails,
  getEmailContent,
} from "@/api/newVersion/melinked_email";
import PlaceholderMap from "@/newVersion/components/placeholderMap";
import moment from "moment";
import {
  modifyLocalEmailData,
  getIndexedDBMailList,
  deleteEmailsByUniqueKey,
  getEmailsByUniqueKeys,
  readEmailsByUniqueKeys,
  updateEmailStarredByUniqueKey,
  updateEmailsStarredByUniqueKeys,
} from "@/api/indexDB/api/email_api";
export default {
  name: "PublicList",
  props: {
    showType: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlaceholderMap,
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      checkedLists: [],
      listData: [],
      listsProperties: "all",
      pageDataAllMarked: false,
      nowPage: this.page,
      currentListCount: 0,
    };
  },
  watch: {
    page: {
      handler(val, old) {
        if (val != old && val != this.nowPage) {
          this.nowPage = val;
        }
      },
      deep: true,
    },
    list: {
      handler(val, old) {
        if (val) {
          this.makeHandlerList(val);
        }
      },
      deep: true,
    },
    listsProperties: {
      handler(val, old) {
        if (val != old) {
          this.$emit("retrieveOnDemand", val, (data) => {
            this.currentListCount = data.count;
          });
        }
      },
      deep: true,
    },
    checkedLists: {
      async handler(val, old) {
        if (val.length > 0 && val.length == this.pageSize) {
          const handleCheckeds = await this.getCheckedsInfo();
          this.pageDataAllMarked = handleCheckeds.every(
            (item) => item.flags.indexOf("FLAGGED") != -1
          );
        } else {
          this.pageDataAllMarked = false;
        }
      },
      deep: true,
    },
    // 监听锚点
    anchorMail: {
      async handler(val, old) {
        if (val && val != old) {
          await this.handlerAnchorMailPage(val);
          await this.goReadEmail(val);
          this.$store.commit("setAnchorMail", null);
        }
      },
      deep: true,
    },
  },
  computed: {
    hiddenListHeader() {
      return this.listsProperties == "all" && this.listData.length == 0;
    },
    // 未读邮件总数
    unreadEmailNum() {
      return this.$store.state.melinked_email.unreadEmailNum;
    },
    // 当前展示的列表类型
    currentListsProperties() {
      let propertiesStr = "";
      const findItem = this.operateBottons.find(
        (item) => this.listsProperties == item.type
      );
      if (findItem) {
        propertiesStr = findItem.title;
      }
      return propertiesStr;
    },
    operateBottons() {
      let buttons = [
        {
          type: "all",
          title: this.$t("allMail"),
        },
      ];
      switch (this.showType) {
        case "inbox":
          buttons.push(
            ...[
              {
                type: "unread",
                title: this.$t("unreadMail"),
              },
              {
                type: "read",
                title: this.$t("readMail"),
              },
            ]
          );
          break;
        case "mark":
          buttons.push(
            ...[
              {
                type: "inbox",
                title: this.$t("receivedEmails"),
              },
              {
                type: "sent",
                title: this.$t("hasBeenSent"),
              },
              {
                type: "trash",
                title: this.$t("deletedMail"),
              },
              {
                type: "spam",
                title: this.$t("spam"),
              },
            ]
          );
          break;
        case "sent":
          buttons = [];
          break;
        case "drafts":
          buttons = [];
          break;
        case "trash":
          buttons = [];
          break;
        case "spam":
          buttons = [];
          break;
      }
      return buttons;
    },
    showOperatePopover() {
      return (
        this.showType != "sent" &&
        this.showType != "drafts" &&
        this.showType != "trash" &&
        this.showType != "spam"
      );
    },
    operatePopoverClass() {
      let className = "";
      switch (this.showType) {
        case "inbox":
          className = "emailListOperatePopover_inbox";
          break;
        case "mark":
          className = "emailListOperatePopover_mark";
          break;
      }
      return `emailListOperatePopover ${className}`;
    },
    // 禁止点击上一页
    notPre() {
      return this.page <= 1;
    },
    // 禁止点击下一页
    notNext() {
      return this.page >= this.totalPage;
    },
    // 默认操作图片集合
    defaultImgs() {
      return [
        {
          title: this.$t("starMarkedAllSelectedEmails"),
          markTitle: this.$t("cancelAllSelectedEmailStars"),
          name: "star_grey",
          disabledImg: require("../../../../../../assets/images/email/list/star_grey.png"),
          img: require("../../../../../../assets/images/email/list/star_operational.png"),
          brightImg: require("../../../../../../assets/images/email/list/star_bright.png"),
        },
        {
          title: this.$t("forwardEmail"),
          name: "batchForward",
          disabledImg: require("../../../../../../assets/images/email/list/forward_disabled.png"),
          img: require("../../../../../../assets/images/email/list/forward_operational.png"),
        },
        {
          title: this.$t("allRead"),
          name: "read_email",
          disabledImg: require("../../../../../../assets/images/email/list/read_email.png"),
          img: require("../../../../../../assets/images/email/list/read_email_operational.png"),
        },
        {
          title: this.$t("putTrash"),
          name: "trash",
          disabledImg: require("../../../../../../assets/images/email/list/trash.png"),
          img: require("../../../../../../assets/images/email/list/trash_operational.png"),
        },
        {
          title: this.$t("forwardEmail"),
          name: "forward",
          disabledImg: require("../../../../../../assets/images/email/list/forward.png"),
          img: require("../../../../../../assets/images/email/list/forward_operational.png"),
        },
        {
          title: this.$t("completelyDelete"),
          name: "completely_delete",
          disabledImg: require("../../../../../../assets/images/email/list/completely_delete.png"),
          img: require("../../../../../../assets/images/email/list/completely_delete_operational.png"),
        },
        {
          title: this.$t("mark_as_not_spam_email"),
          name: "recall_spam",
          disabledImg: require("../../../../../../assets/images/email/list/recall_spam.png"),
          img: require("../../../../../../assets/images/email/list/recall_spam_operational.png"),
        },
        {
          title: this.$t("mark_as_spam"),
          name: "spam",
          disabledImg: require("../../../../../../assets/images/email/list/spam.png"),
          img: require("../../../../../../assets/images/email/list/spam_operational.png"),
        },
      ];
    },
    markImg() {
      return {
        unmark: this.defaultImgs[0].disabledImg,
        mark: require("../../../../../../assets/images/email/list/star_bright.png"),
      };
    },
    actionBars() {
      let handlerBars = [];
      switch (this.showType) {
        case "inbox":
          handlerBars = [
            "star_grey",
            "read_email",
            "spam",
            "batchForward",
            "trash",
          ];
          break;
        case "mark":
          handlerBars = ["star_grey", "batchForward", "trash"];
          break;
        case "sent":
          handlerBars = ["star_grey", "batchForward", "trash"];
          break;
        case "drafts":
          handlerBars = ["trash"];
          break;
        case "trash":
          handlerBars = ["star_grey", "completely_delete"];
          break;
        case "spam":
          handlerBars = ["star_grey", "completely_delete", "recall_spam"];
          break;
      }
      return this.defaultImgs.filter((item) => {
        return handlerBars.includes(item.name);
      });
    },
    // 当前选中的小菜单
    currentSubMenuInfo() {
      return this.$store.state.melinked_email.currentSubMenuInfo;
    },
    // 当前打开的邮箱账号
    currentEmailAccount() {
      return this.$store.state.melinked_email.currentEmailAccount;
    },
    // 邮箱列表类型
    emailBoxTypes() {
      return this.$store.state.melinked_email.emailBoxTypes;
    },
    // 锚点邮件
    anchorMail() {
      return this.$store.state.melinked_email.anchorMail;
    },
    // 当前邮件文件夹名称
    folder_name() {
      if (!this.currentEmailAccount.subMenu) {
        return "";
      }
      const menu = this.currentEmailAccount.subMenu.find(
        (item) => item.name === this.showType
      );
      return menu.folder_name;
    },
    // 窗口尺寸
    IMWindowSize() {
      return this.$store.state.imStore.IMWindowSize;
    },
  },
  mounted() {
    if (this.emailBoxTypes.indexOf(this.showType) == -1) {
      this.$message({
        type: "error",
        message: "传入邮件文件夹类型错误",
      });
      return;
    }
    // 初始化监听是否有锚点邮件
    if (!!this.anchorMail) {
      this.$nextTick(async () => {
        await this.handlerAnchorMailPage(this.anchorMail);
        const emails = await getEmailsByUniqueKeys([
          this.anchorMail.uniqueIdentifier,
        ]);
        if (emails.length > 0) {
          await this.goReadEmail(emails[0]);
        } else {
          await this.goReadEmail(this.anchorMail);
        }
        this.$store.commit("setAnchorMail", null);
      });
    }
    this.currentListCount = this.currentSubMenuInfo.messageCount;
  },
  methods: {
    clickClassification(item) {
      console.log(item);
    },
    handlerNickname(item) {
      return item.contactName || this.showNickname(this.showType, item);
    },
    // 计算锚点邮件所在的页数
    async handlerAnchorMailPage(anchorMail) {
      this.$emit("handlerAnchorMailPage", anchorMail, async (page) => {
        await this.$forceUpdate();
        this.refreshPage(this.showType);
      });
    },
    // 重置当前页面
    async refreshPage(showType) {
      let locallyCachedData = [],
        dataCount = 0;
      try {
        const isStarred = showType == "mark";
        const commonParams = {
          page: this.page,
          pageSize: this.pageSize,
          mailAddress: this.currentEmailAccount.mailAddress,
        };
        if (isStarred) {
          commonParams.isStarred = true;
        } else {
          commonParams.folder = this.folder_name;
        }
        let result = await getIndexedDBMailList(commonParams);
        dataCount = result.total;
        locallyCachedData = result.data;
      } catch (error) {
        console.log(error);
      }
      this.$emit("refreshTotalPage", dataCount, async () => {
        // 重新截取当前页数据
        let listData = locallyCachedData;
        this.makeHandlerList(listData);
        this.checkedLists = [];
        this.checkAll = false;
        this.isIndeterminate = false;
        await this.$forceUpdate();
      });
    },
    formatTimestamp(timestamp) {
      let today = moment(),
        showTime = "";
      if (today.isSame(timestamp, "year")) {
        showTime = moment(timestamp).format("MM-DD");
      } else {
        showTime = moment(timestamp).format("YYYY-MM-DD");
      }
      return showTime;
    },
    makeHandlerList(list) {
      if (!list.length) {
        this.listData = [];
        return;
      }
      this.listData = list.map((item) => {
        let newItem = _.cloneDeep(item);
        newItem.mark = false;
        // 默认未读
        // mailStatus： 0 未读邮件；1 已读邮件；2-1 已发送邮件；2-2 正在发送中的邮件；2-3 发送失败的邮件；3 草稿箱邮件；4 回复又转发过；5 回复过；6 转发过
        newItem.mailStatus = 0;
        const {
          last_opened,
          mailStatus,
          starred,
          flags,
          folder_name,
        } = newItem;
        const handlerFlags = flags.toUpperCase();
        const hasAnswered = handlerFlags.includes("ANSWERED");
        const hasForwarded = handlerFlags.includes("FORWARDED");
        if (this.showType == "inbox") {
          if (hasAnswered || hasForwarded) {
            if (hasAnswered && hasForwarded) {
              newItem.mailStatus = 4;
            } else if (hasAnswered) {
              newItem.mailStatus = 5;
            } else if (hasForwarded) {
              newItem.mailStatus = 6;
            }
          } else if (last_opened > 0 && mailStatus == 0) {
            newItem.mailStatus = 1;
          }
        }
        if (this.showType == "mark") {
          newItem.mark = true;
          // 如果是收件箱或者已删除邮箱的话，则需要显示已读未读
          if (["INBOX", "Trash", "Spam"].includes(folder_name)) {
            if (hasAnswered || hasForwarded) {
              if (hasAnswered && hasForwarded) {
                newItem.mailStatus = 4;
              } else if (hasAnswered) {
                newItem.mailStatus = 5;
              } else if (hasForwarded) {
                newItem.mailStatus = 6;
              }
            } else if (last_opened > 0 && mailStatus == 0) {
              newItem.mailStatus = 1;
            }
          }
          // 如果来自已发送邮箱
          if (folder_name == "Sent") {
            newItem.mailStatus = "2-1";
          }
        } else if (starred == 1) {
          newItem.mark = true;
        }
        if (this.showType == "sent") {
          newItem.mailStatus = "2-1";
          if (handlerFlags.indexOf("SENDING") != -1) {
            newItem.mailStatus = "2-2";
          }
          if (handlerFlags.indexOf("SENDFAIL") != -1) {
            newItem.mailStatus = "2-3";
          }
        }
        if (this.showType == "drafts") {
          newItem.mailStatus = 3;
        }
        if (this.showType == "trash") {
          if (last_opened > 0 && mailStatus == 0) {
            newItem.mailStatus = 1;
          }
        }
        if (this.showType == "spam") {
          if (last_opened > 0 && mailStatus == 0) {
            newItem.mailStatus = 1;
          }
        }
        return newItem;
      });
    },
    showNickname(showType, item) {
      if (showType == "sent") {
        return item.to ? item.to : item.cc;
      } else {
        return item.from ? item.from : item.cc;
      }
    },
    // 获取草稿邮件消息体
    async getDraftsEmailBody(item) {
      const password = await this.globalVerifyEmailPassword(
        this.currentEmailAccount,
        false
      );
      const createdToken = {
        u: this.currentEmailAccount.mailAddress,
        p: password,
      };
      let params = {
        mailboxId: item.mailboxId,
        uid: item.uid,
        individualAesToten: JSON.stringify(createdToken),
      };
      const result = await getEmailContent(params);
      if (result.code == 200 && result.data.data) {
        const uniqueIdentifier = `${this.currentEmailAccount.mailAddress}/${item.folder_name}/${item.uid}`;
        await modifyLocalEmailData(uniqueIdentifier, {
          content: result.data.data.content,
        });
        item.content = result.data.data.content;
      } else if (!result.data.data) {
        this.$message({
          type: "error",
          message: "服务器数据有误，返回信息为空",
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async goReadEmail(item) {
      if (this.showType == "drafts") {
        if (item.content == "") {
          this.$store.commit("setMailsListViewLoading", true);
          await this.getDraftsEmailBody(item);
          this.$store.commit("setMailsListViewLoading", false);
        }
        this.$store.commit("setOpenEmailSendView", {
          open: true,
          type: "drafts",
          itemInfo: item,
        });
        this.$store.commit("setEmailCurrentComponentHistory", "send");
      } else {
        if (item.flags.indexOf("SEEN") == -1) {
          await this.readSingleEmail(item);
        }
        this.$store.commit("setCurrentReadItemInfo", item);
        this.$store.commit("setEmailCurrentComponentHistory", "read");
      }
    },
    // 彻底删除
    async completelyDelete(checkedUids) {
      this.$confirm(
        <div
          style={{
            width: "100%",
            display: "flex",
            "align-items": "center",
            "justify-content": "flex-start",
          }}
        >
          <img
            style={{
              width: "24px",
              height: "24px",
              "margin-right": "8px",
              "flex-shrink": "0",
            }}
            src={require("../../../../../../assets/images/email/send/tipsIcon.png")}
          />
          <div style={{ flex: 1, "text-align": "left" }}>
            {this.showType == "drafts"
              ? this.$t("draftsDeleteTips")
              : this.$t("completelyDeleteTips")}
          </div>
        </div>,
        this.$t("tips"),
        {
          customClass: "emailOperationTips",
          confirmButtonText: " ",
          center: true,
          showClose: true,
          confirmButtonClass: "el-icon-check my-confirm-btn",
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
        }
      )
        .then(async () => {
          let params = {
            folder: this.currentSubMenuInfo.folder_name,
            flag: "DELETED",
            set: true,
            uids: checkedUids.join(","),
          };
          this.$store.commit("setMailsListViewLoading", true);
          const result = await markEmails(params);
          this.$store.commit("setMailsListViewLoading", false);
          if (result.code == 200) {
            try {
              await deleteEmailsByUniqueKey(this.checkedLists);
            } catch (error) {
              console.log(error);
            }
            await this.refreshPage(this.showType);
          } else if (result.code == 501) {
            await this.deleteNonExistentData(result, this.showType);
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch(() => {});
    },
    // 还原垃圾邮件
    async recallSpam(checkedUids) {
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("unMarkAsSpam"),
        showDialog: true,
        color: "#F39945",
        confirmFn: async () => {
          const toFromFolder = this.currentEmailAccount.subMenu.filter(
            (item) => item.name == "inbox"
          )[0].folder_name;
          let params = {
            fromFolder: this.currentSubMenuInfo.folder_name,
            toFromFolder: toFromFolder,
            uids: checkedUids.join(","),
          };
          this.$store.commit("setMailsListViewLoading", true);
          const result = await moveEmails(params);
          this.$store.commit("setMailsListViewLoading", false);
          if (result.code == 200) {
            this.$store.commit("setRefreshEmailSubMenu", true);
            try {
              await deleteEmailsByUniqueKey(this.checkedLists);
            } catch (error) {
              console.log(error);
            }
            // 重新截取当前页数据
            await this.refreshPage(this.showType);
          } else if (result.code == 501) {
            await this.deleteNonExistentData(result, this.showType);
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        },
      });
    },
    // 将选中邮件标记为垃圾邮件
    async markAsSpam(checkedUids) {
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("isMarkedAsSpam"),
        showDialog: true,
        color: "#F39945",
        confirmFn: async () => {
          // 找到当前邮箱的垃圾箱文件名
          const toFromFolder = this.currentEmailAccount.subMenu.filter(
            (item) => item.name == "spam"
          )[0].folder_name;
          let params = {
            fromFolder: this.currentSubMenuInfo.folder_name,
            toFromFolder: toFromFolder,
            uids: checkedUids.join(","),
          };
          this.$store.commit("setMailsListViewLoading", true);
          const result = await moveEmails(params);
          this.$store.commit("setMailsListViewLoading", false);
          if (result.code == 200) {
            try {
              this.$store.commit("setRefreshEmailSubMenu", true);
              await deleteEmailsByUniqueKey(this.checkedLists);
            } catch (error) {
              console.log(error);
            }
            // 重新截取当前页数据
            await this.refreshPage(this.showType);
          } else if (result.code == 501) {
            await this.deleteNonExistentData(result, this.showType);
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        },
      });
    },
    // 将选中邮件放入已删除
    async deleteAllEmail(checkedUids) {
      // 如果是星标邮件
      if (this.showType == "mark") {
        await this.deleteEmailInMark();
        // 如果是草稿箱
      } else if (this.showType == "drafts") {
        await this.completelyDelete(checkedUids);
      } else {
        const toFromFolder = this.currentEmailAccount.subMenu.filter(
          (item) => item.name == "trash"
        )[0].folder_name;
        let params = {
          fromFolder: this.currentSubMenuInfo.folder_name,
          toFromFolder: toFromFolder,
          uids: checkedUids.join(","),
        };
        this.$store.commit("setMailsListViewLoading", true);
        const result = await moveEmails(params);
        this.$store.commit("setMailsListViewLoading", false);
        if (result.code == 200) {
          this.$store.commit("setRefreshEmailSubMenu", true);
          try {
            await deleteEmailsByUniqueKey(this.checkedLists);
          } catch (error) {
            console.log(error);
          }
          // 重新截取当前页数据
          await this.refreshPage(this.showType);
        } else if (result.code == 501) {
          await this.deleteNonExistentData(result, this.showType);
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    // 删除星标邮件需要先把邮件的星标取消，再放入已删除文件夹
    async deleteEmailInMark() {
      const handleCheckeds = await this.getCheckedsInfo();
      await this.cleanAllmarkInList();
      // 整理来自不同文件夹的数据
      let dataBag = [];
      this.currentEmailAccount.subMenu.forEach((item) => {
        const handleArr = handleCheckeds.filter(
          (data) => data.folder_name === item.folder_name
        );
        // 如果本來就是已删除邮件，就不需要再删除了
        if (handleArr.length != 0 && item.name != "trash") {
          dataBag.push({
            showType: item.name,
            folder_name: item.folder_name,
            items: handleArr,
            uids: handleArr.map((i) => i.uid),
          });
        }
      });
      const toFromFolder = this.currentEmailAccount.subMenu.filter(
        (item) => item.name == "trash"
      )[0].folder_name;
      const promises = dataBag.map((data) => {
        return new Promise(async (resolve, reject) => {
          let params = {
            fromFolder: data.folder_name,
            toFromFolder: toFromFolder,
            uids: data.uids.join(","),
          };
          const result = await moveEmails(params);
          resolve({ result });
        });
      });
      // 按照不同文件夹依次处理
      this.$store.commit("setMailsListViewLoading", true);
      await Promise.all(promises);
      try {
        // 将被删除的邮件清空
        await deleteEmailsByUniqueKey(this.checkedLists);
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("setMailsListViewLoading", false);
      // 重新截取当前页数据
      await this.refreshPage(this.showType);
    },
    // 在列表中取消当前选中的所有邮件星标
    async cleanAllmarkInList() {
      this.$store.commit("setMailsListViewLoading", true);
      try {
        await updateEmailsStarredByUniqueKeys(this.checkedLists, "cancel");
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("setMailsListViewLoading", false);
      // 重新截取当前页数据
      await this.refreshPage(this.showType);
    },
    async changeAllMark(checkedUids, handleCheckeds) {
      const allMarked = handleCheckeds.every(
        (item) => item.flags.indexOf("FLAGGED") != -1
      );
      // 如果所有都已经被星标，则直接调用全部取消星标方法
      if (allMarked) {
        await this.cleanAllmarkInList();
        return;
      }
      let params = {
        folder: this.currentSubMenuInfo.folder_name,
        flag: "FLAGGED",
        set: true,
        uids: checkedUids.join(","),
      };
      this.$store.commit("setMailsListViewLoading", true);
      const result = await markEmails(params);
      this.$store.commit("setMailsListViewLoading", false);
      if (result.code == 200) {
        try {
          await updateEmailsStarredByUniqueKeys(this.checkedLists, "mark");
        } catch (error) {
          console.log(error);
        }
        // 重新截取当前页数据
        await this.refreshPage(this.showType);
      } else if (result.code == 501) {
        await this.deleteNonExistentData(result, this.showType);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 删除不存在的数据
    async deleteNonExistentData(result, showType) {
      if (result.message != "") {
        const uids = result.message.split(",");
        if (uids.length != 0) {
          try {
            // 获取当前uids对应的数据
            const checkedLists = uids.map((uid) => {
              return `${this.currentEmailAccount.mailAddress}/${this.folder_name}/${uid}`;
            });
            await deleteEmailsByUniqueKey(checkedLists);
          } catch (error) {
            console.log(error);
          }
          await this.refreshPage(showType);
        }
      }
      this.$message({
        type: "error",
        message: this.$t("emailDoesNotExist"),
      });
    },
    async changeSingleMark(item) {
      let params = {
        folder: item.folder_name || this.currentSubMenuInfo.folder_name,
        flag: "FLAGGED",
        set: !item.mark,
        uids: item.uid,
      };
      this.$store.commit("setMailsListViewLoading", true);
      const result = await markEmails(params);
      this.$store.commit("setMailsListViewLoading", false);
      if (result.code == 200) {
        try {
          let action = item.mark ? "cancel" : "mark";
          await updateEmailStarredByUniqueKey(item.uniqueIdentifier, action);
        } catch (error) {
          console.log(error);
        }
        // 重新截取当前页数据
        await this.refreshPage(this.showType);
      } else if (result.code == 501) {
        await this.deleteNonExistentData(result, this.showType);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async readSingleEmail(item) {
      if (item.mailStatus == 0) {
        let params = {
          folder: item.folder_name || this.currentSubMenuInfo.folder_name,
          flag: "SEEN",
          set: true,
          uids: item.uid,
        };
        this.$store.commit("setMailsListViewLoading", true);
        const result = await markEmails(params);
        this.$store.commit("setMailsListViewLoading", false);
        if (result.code == 200) {
          try {
            await readEmailsByUniqueKeys([item.uniqueIdentifier]);
          } catch (error) {
            console.log(error);
          }
          let unreadEmailNum = this.unreadEmailNum - 1;
          this.$store.commit(
            "setUnreadEmailNum",
            unreadEmailNum > 0 ? unreadEmailNum : 0
          );
          // 重新截取当前页数据
          await this.refreshPage(this.showType);
        } else if (result.code == 501) {
          await this.deleteNonExistentData(result, this.showType);
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    async readAllEmail(checkedUids) {
      let params = {
        folder: this.currentSubMenuInfo.folder_name,
        flag: "SEEN",
        set: true,
        uids: checkedUids.join(","),
      };
      this.$store.commit("setMailsListViewLoading", true);
      const result = await markEmails(params);
      this.$store.commit("setMailsListViewLoading", false);
      if (result.code == 200) {
        let unreadCount = 0;
        try {
          unreadCount = await readEmailsByUniqueKeys(this.checkedLists);
        } catch (error) {
          console.log(error);
        }
        // 重新截取当前页数据
        await this.refreshPage(this.showType);
        let unreadEmailNum = this.unreadEmailNum - unreadCount;
        this.$store.commit(
          "setUnreadEmailNum",
          unreadEmailNum > 0 ? unreadEmailNum : 0
        );
      } else if (result.code == 501) {
        await this.deleteNonExistentData(result, this.showType);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 重组flags
    recombineFlags(oldFlags, newFlags) {
      let handlerFlags = oldFlags;
      if (handlerFlags.indexOf(newFlags) == -1) {
        if (handlerFlags.length == 0) {
          handlerFlags = `${newFlags}`;
        } else {
          handlerFlags = `${newFlags},${handlerFlags}`;
        }
      }
      return handlerFlags.replace(/,+/g, ",").replace(/(^,)|(,$)/g, "");
    },
    prePage() {
      if (this.notPre) {
        return;
      }
      this.checkAll = false;
      this.checkedLists = [];
      this.isIndeterminate = false;
      this.$emit("prePage");
    },
    nextPage() {
      if (this.notNext) {
        return;
      }
      this.checkAll = false;
      this.checkedLists = [];
      this.isIndeterminate = false;
      this.$emit("nextPage");
    },
    jumpPage() {
      if (this.nowPage > 0 && this.nowPage <= this.totalPage) {
        this.checkAll = false;
        this.checkedLists = [];
        this.isIndeterminate = false;
        this.$emit("jumpPage", this.nowPage);
      } else {
        this.nowPage = this.page;
      }
    },
    async getCheckedsInfo() {
      try {
        return await getEmailsByUniqueKeys(this.checkedLists);
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async checkActionBar(bar) {
      if (this.checkedLists.length == 0) {
        return;
      }
      // 获取当前列表项对应的uids
      const handleCheckeds = await this.getCheckedsInfo();
      const checkedUids = handleCheckeds.map((item) => {
        return item.uid;
      });
      switch (bar.name) {
        case "star_grey":
          await this.changeAllMark(checkedUids, handleCheckeds);
          break;
        case "batchForward":
          this.batchForward(handleCheckeds);
          break;
        case "read_email":
          await this.readAllEmail(checkedUids);
          break;
        case "trash":
          await this.deleteAllEmail(checkedUids);
          break;
        case "completely_delete":
          await this.completelyDelete(checkedUids);
          break;
        case "spam":
          await this.markAsSpam(checkedUids);
          break;
        case "recall_spam":
          await this.recallSpam(checkedUids);
          break;
      }
    },
    // 批量转发
    async batchForward(items) {
      if (items.length == 0) {
        return;
      }
      if (items.length > 10) {
        this.$message({
          type: "warning",
          message: "<10",
        });
        return;
      }
      this.$store.commit("setMailsListViewLoading", true);
      if (items.length == 1) {
        if (!items[0].content) {
          const params = await this.getEmailContentAndStore(
            this.currentEmailAccount,
            items[0]
          );
          if (params.content) {
            items[0].content = params.content;
          }
          if (params.flags) {
            items[0].flags = params.flags;
          }
        }
        this.$store.commit("setOpenEmailSendView", {
          open: true,
          type: "forward",
          replyItemInfo: items[0],
        });
        this.$store.commit("setEmailCurrentComponentHistory", "send");
      } else {
        this.$store.commit("setOpenEmailSendView", {
          open: true,
          type: "batchForward",
          batchForwardList: items.map((item) => {
            return {
              uid: item.uid,
              folder: item.folder_name,
              fileName: item.subject,
              mailboxId: item.mailboxId,
            };
          }),
        });
        this.$store.commit("setEmailCurrentComponentHistory", "send");
      }
      this.$store.commit("setMailsListViewLoading", false);
    },
    handleCheckAllChange(val) {
      this.checkedLists = val
        ? this.listData.map((item) => {
            return item.uniqueIdentifier;
          })
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedItemChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.listData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.listData.length;
    },
    selectedListsProperties(item) {
      this.listsProperties = item.type;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.publicList
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: #F8F8F8;
  .listHeader
    width: 100%;
    height: 48px;
    padding: 0 16px 0 15px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8F8F8;
    .leftBox
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 12px;
      .currentListType
        font-size 16px;
        line-height 20px;
        margin: 0 5px;
      :deep .el-checkbox
        margin-right: 5px;
        .is-focus
          .el-checkbox__inner
            border-color: #292D32  !important;;
        .el-checkbox__inner
          border: 2px solid #292D32;
          width: 16px;
          height: 16px;
          &:hover
            border-color: #52BD68 !important;;
        .is-checked,.is-indeterminate
          .el-checkbox__inner
            border-color: #52BD68  !important;;
        .is-checked,.is-indeterminate
          .el-checkbox__inner
            background-color: #52BD68;
      :deep .el-popover__reference-wrapper
        display: block;
        width: 10px;
        height: 6px;
        overflow: hidden;
        position: relative;
        .el-image
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
      :deep .listActionBar
        height: 20px;
        margin-left: 20px;
        .actionBarButton
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 30px;
          .el-image
            .el-image__inner
              width 100% !important;
              height auto;
    .rightBox
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      .pageInfo
        font-size: 15px;
        color: #858585;
        margin-right: 16px;
      .operationPaging
        width: 100px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button
          width: 20px;
          height: 20px;
          .iconStyle
            font-size: 20px;
        .inputOut
          width: 50px;
          height: 20px;
          .inputBox
            width: 50px;
            height: 20px;
            ::v-deep(.el-input)
              width: 100%;
              height: 100%;
              input
                width: 100%;
                height: 100%;
                text-align: center;
                padding: 0;
  .spamTips
    width: 100%;
    height: 24px;
    background: rgba(249, 200, 72, 0.15);
    padding: 0 16px 0 31px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .tipsIcon
      width: 15px;
      height: 15px;
      flex-shrink: 0;
      position: relative;
      margin-right: 7px;
      :deep .el-image
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    .tipsText
      height: 100%;
      min-width: 0;
      flex: 1;
      line-height: 24px;
      font-size: 14px;
      color: #F39945;
      overflow: hidden;
  .listBody
    width: calc(100% - 11px);
    flex: 1;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-light();
    margin-left: 11px;
    background: #F8F8F8;
    .placeholderMap
      width: 100%;
      height: 100%;
      overflow: hidden;
    .listLoading
      width: 100%;
      background: #F5F5F5;
      transition: height 0.3s;
      overflow: hidden;
      :deep .el-loading-mask
        .el-loading-spinner
          position: relative;
          top: 0;
          margin-top: 0;
          .el-icon-loading
            font-size: 14px;
          .el-loading-text
            display: inline-block;
    .listItem
      width: 100%;
      height: 40px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid #F0F0F0;
      &:hover
        background: #F5F5F5;
        cursor: pointer;
        .classificationStatus
          display: flex !important;
      :deep .el-checkbox
        height: 100%;
        width: 32px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .is-focus
          .el-checkbox__inner
            border-color: #52BD68  !important;
        .el-checkbox__inner
          &:hover
            border-color: #52BD68 !important;
        .is-checked
          .el-checkbox__inner
            border-color: #52BD68 !important;
        .is-checked
          .el-checkbox__inner
            background-color: #52BD68;
        .el-checkbox__input
          margin-left: 16px;
          width: 16px;
          height: 16px;
          overflow: hidden;
          flex-shrink: 0;
          .el-checkbox__inner
            border: 2px solid #B3B3B3;
            width: 16px;
            height: 16px;
        .el-checkbox__label
          display: none;
      .mark
        width: 20px;
        height: 20px;
        margin-left: 14px;
        flex-shrink: 0;
      .nicknameItem
        height: 20px;
        flex-shrink: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: width 0.5s;
        .nickname
          min-width: 0;
          flex: 1;
          height: 20px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          color: #292D32;
          margin-left: 14px;
        .statusButton
          width: 20px;
          height: 20px;
          margin-left: 14px;
          flex-shrink: 0;
      .lineContent
        flex: 1;
        min-width: 0px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px 0 5px;
        .emailTitle
          height: 100%;
          line-height: 20px;
          max-width: calc(100% - 20px);
          min-width: 0px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 15px;
          padding-right: 18px;
          color: #292D32;
        .emailStatusBox
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-width: 0;
          flex-shrink: 0;
          .classificationStatus
            background: #ffb320;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            align-items: center;
            justify-content: center;
            display: none;
          .attachmentStatus
            width: 20px;
            height: 20px;
      .emailDate
        height: 20px;
        line-height: 20px;
        color: #B3B3B3;
        flex-shrink: 0;
        font-size: 15px;
        margin-right: 20px;
        max-width: 90px;
        min-width: 0;
        text-align: right;
</style>

<style lang="stylus">
.emailListOperatePopover_mark
  height: 162px;
.emailListOperatePopover_inbox
  height: 98px;
.emailListOperatePopover
  min-height: 96px;
  min-width: 0;
  padding: 0;
  .operateList
    width: 100%;
    height: 100%;
    overflow: hidden;
    .activebutton
      background: #F0F0F0 !important;
      color: #000000 !important;
    .buttonStyle
      width: 100%;
      min-height: 32px;
      max-height: 64px;
      cursor: pointer;
      background: #FFFFFF;
      user-select: none;
      font-size: 14px;
      color: #858585;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: background .3s,color .3s;
      line-height: 32px;
      text-align: center;
      padding: 0 5px;
      &:hover
        background: #F0F0F0;
        color: #000000;
</style>
