<template>
  <div class="inboxComponent">
    <PublicList
      :showType="type"
      :list="list"
      :page="page"
      :pageSize="pageSize"
      :totalPage="totalPage"
      :listLoading="listLoading"
      @prePage="prePage"
      @nextPage="nextPage"
      @jumpPage="jumpPage"
      @retrieveOnDemand="retrieveOnDemand"
      @refreshTotalPage="refreshTotalPage"
      @handlerAnchorMailPage="handlerAnchorMailPage"
    />
  </div>
</template>
<script>
import PublicList from "../components/publicList.vue";
import Mixins from "../mixins";
export default {
  name: "Inbox",
  mixins: [Mixins],
  components: {
    PublicList,
  },
  data() {
    return {
      type: "inbox",
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="stylus" scoped>
.inboxComponent
  width: 100%;
  height: 100%;
  overflow: hidden;
</style>
