import Axios from "axios";
import {
  findMailPage,
  bulkAddEmails,
  getIndexedDBMailList,
  getEmailLocalDataTotalNum,
  deleteEmailsByUniqueKey,
  getEmailsByUniqueKeys,
  getPostmasterEmails,
  markSentEmailsAsFailed,
} from "@/api/indexDB/api/email_api";
import { getLocalEmailContactsList } from "@/api/indexDB/api/email_address_book_api";
export default {
  data() {
    return {
      page: 1,
      pageSize: 50,
      requestPageSize: 500,
      totalPage: 1,
      currentListCount: 0,
      list: [],
      listsProperties: "all",
      listLoading: false,
      // 当前选中的邮件
      mailRecord: null,
      // 当前选中的小菜单
      subMenuRecord: null,
    };
  },
  props: {
    migrating_email: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    // 当前展示的模块
    currentComponent() {
      return this.$store.state.melinked_email.currentComponent;
    },
    // 当前打开的邮箱账号
    currentEmailAccount() {
      return this.$store.state.melinked_email.currentEmailAccount;
    },
    // 当前选中的小菜单
    currentSubMenuInfo() {
      return this.$store.state.melinked_email.currentSubMenuInfo;
    },
    // 邮箱列表类型
    emailBoxTypes() {
      return this.$store.state.melinked_email.emailBoxTypes;
    },
    // 邮箱中断请求令牌
    emailAxiosSources() {
      return this.$store.state.melinked_email.emailAxiosSources;
    },
    // 当前覆盖在模块上的遮罩层
    currentMaskComponent() {
      return this.$store.state.melinked_email.currentMaskComponent;
    },
    // 收到邮箱接收到邮件通知
    emailUnreadRecords() {
      return this.$store.state.melinked_email.emailUnreadRecords;
    },
    // 未读邮件总数
    unreadEmailNum() {
      return this.$store.state.melinked_email.unreadEmailNum;
    },
  },
  watch: {
    // 监听邮箱收到邮件通知，用于刷新当前浏览的邮箱数据
    emailUnreadRecords: {
      async handler(val, old) {
        if (old.includes(this.mailRecord.mailAddress)) {
          const dataInfo = await this.getLocalListByPage(this.page);
          this.list = dataInfo.data;
          await this.refreshTotalPage(dataInfo.count);
        }
      },
      deep: true,
    },
    currentMaskComponent: {
      async handler(val, old) {
        // 退出阅读模块后，重新截取新的数据
        if (old == "read") {
          const dataInfo = await this.getLocalListByPage(this.page);
          this.list = dataInfo.data;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.emailBoxTypes.indexOf(this.type) == -1) {
      this.$message({
        type: "error",
        message: "传入邮件文件夹类型错误",
      });
      return;
    }
    this.resetPage();
    // 获取当前邮箱账号信息
    this.mailRecord = _.cloneDeep(this.currentEmailAccount);
    // 获取当前小菜单信息
    this.subMenuRecord = _.cloneDeep(this.currentSubMenuInfo);
    // 当邮件已经迁移成功时，需要全量拉取
    let needFullPull = false,
      fullPullStatus = [-1, 0, 1];
    if (
      this.migrating_email &&
      fullPullStatus.includes(this.migrating_email.pulledStatus) &&
      ["inbox", "sent", "drafts"].includes(this.type)
    ) {
      let emailFullPullRecord =
        JSON.parse(localStorage.getItem("emailFullPullRecord")) || [];
      let existingRecordIndex = emailFullPullRecord.findIndex(
        (item) => item.mailAddress === this.migrating_email.mailAddress
      );
      if (existingRecordIndex === -1) {
        emailFullPullRecord.push({
          mailAddress: this.migrating_email.mailAddress,
          folders: [
            { name: "inbox", needPull: true },
            { name: "sent", needPull: true },
            { name: "drafts", needPull: true },
          ],
        });
        existingRecordIndex = 0;
      }
      let folder = emailFullPullRecord[existingRecordIndex].folders.find(
        (folder) => folder.name === this.type
      );
      if (folder) {
        needFullPull = folder.needPull;
      }
      localStorage.setItem(
        "emailFullPullRecord",
        JSON.stringify(emailFullPullRecord)
      );
    }
    // 获取当前列表数据抓取类型
    this.listsProperties = "all";
    if (this.type == "mark") {
      const dataInfo = await this.getLocalListByPage(this.page);
      this.totalPage = Math.ceil(dataInfo.count / this.pageSize);
      this.list = dataInfo.data;
    } else {
      // 获取本地邮箱数据库邮件数据
      let result = await getIndexedDBMailList({
        page: this.page,
        pageSize: this.pageSize,
        mailAddress: this.mailRecord.mailAddress,
        folder: this.subMenuRecord.folder_name,
        type: this.listsProperties,
      });
      let localEmails = result.data;
      // 如果本地已有缓存，则先将缓存数据展示出来
      if (localEmails && localEmails.length != 0) {
        this.list = await this.matchEmailAddressToContactName(localEmails);
      } else {
        localEmails = [];
      }
      // 计算总页数
      if (this.subMenuRecord.messageCount) {
        this.totalPage = Math.ceil(
          this.subMenuRecord.messageCount / this.pageSize
        );
      } else {
        const countNum = await getEmailLocalDataTotalNum(
          this.mailRecord.mailAddress
        );
        this.totalPage = Math.ceil(countNum / this.pageSize);
      }
      // 清除当前正在执行的请求
      await this.clearEmailAxiosSources();
      // 如果本地数据存在，则刷新时不干扰浏览数据，并记录本地最新的一封邮件
      let recentLocalTimestamp = 0;
      if (localEmails.length != 0) {
        this.listLoading = true;
        recentLocalTimestamp = localEmails[0].sendTimestamp;
      } else {
        this.$store.commit("setMailsListViewLoading", true);
      }
      // 需要全部拉取
      if (needFullPull) {
        recentLocalTimestamp = -2;
      }
      // 如果访问的是已删除或者垃圾箱，则每次都拉取所有邮件
      if (this.subMenuRecord.folder_name == "Trash") {
        recentLocalTimestamp = -1;
      }
      if (this.subMenuRecord.folder_name == "Spam") {
        recentLocalTimestamp = -1;
      }
      const CancelToken = Axios.CancelToken;
      const source = CancelToken.source();
      // 记录请求令牌
      try {
        const timeStamp = Date.now();
        const cancelUuid = `uuid_${timeStamp}_${parseInt(
          Math.random() * 1e12
        )}`;
        const axiosSources = {
          cancelUuid: cancelUuid,
          source: source,
          timeStamp: timeStamp,
        };
        const newEmailAxiosSources = _.cloneDeep(
          this.$store.state.melinked_email.emailAxiosSources
        );
        newEmailAxiosSources.push(axiosSources);
        this.$store.commit("setEmailAxiosSources", newEmailAxiosSources);
      } catch (error) {
        console.log(error);
      }
      // 用于请求数据的总页数，一页一百为基数
      const totalPage_request = Math.max(
        Math.ceil(this.subMenuRecord.messageCount / this.requestPageSize),
        1
      );
      const { mailAddress, pulledStatus } = this.migrating_email || {},
        type = this.migrating_email ? this.type : null;
      await this.batchPullEmailToIndexedDB({
        sourceToken: source.token,
        pageSize: this.requestPageSize,
        startPage: 1,
        totalPage: totalPage_request,
        folder_name: this.subMenuRecord.folder_name,
        recentLocalTimestamp: recentLocalTimestamp,
        mailAddress: mailAddress || null,
        type: type,
        pulledStatus: pulledStatus || 0,
        abortFn: (message) => {
          console.log(message);
          this.$store.commit("setMailsListViewLoading", false);
          this.listLoading = false;
        },
        outputFn: async (currentBatchData) => {
          await this.processBatchData(
            currentBatchData,
            this.mailRecord.mailAddress,
            this.subMenuRecord.folder_name,
            recentLocalTimestamp
          );
        },
        completeFn: () => {},
      });
    }
  },
  methods: {
    // 用于查找锚点邮件所在页
    async handlerAnchorMailPage(anchorMail, fn) {
      try {
        const result = await findMailPage({
          pageSize: this.pageSize,
          mailAddress: this.mailRecord.mailAddress,
          folder: this.subMenuRecord.folder_name,
          uniqueIdentifier: anchorMail.uniqueIdentifier,
        });
        this.page = result.page;
        if (fn) {
          fn(result.page);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async refreshTotalPage(size, fn) {
      // 刷新菜单列表
      this.$emit("listIsRefreshed", {
        type: this.type,
        size,
      });
      // 更新总页数
      this.totalPage = Math.ceil(size / this.pageSize);
      // 如果当前页数大于总页数，则重置为总页数最后一页
      if (this.page > this.totalPage) {
        this.page = this.totalPage;
      }
      await this.$forceUpdate();
      if (fn) {
        fn();
      }
    },
    resetPage() {
      this.page = 1;
      this.pageSize = 50;
      this.totalPage = 1;
      this.list = [];
    },
    // 按需要获取列表数据
    async retrieveOnDemand(type, fn) {
      this.listsProperties = type;
      this.resetPage();
      const dataInfo = await this.getLocalListByPage(this.page);
      this.totalPage = Math.ceil(dataInfo.count / this.pageSize);
      this.list = dataInfo.data;
      if (fn) {
        fn(dataInfo);
      }
    },
    // 上一页
    async prePage() {
      if (this.page > 1) {
        this.page--;
        const dataInfo = await this.getLocalListByPage(this.page);
        this.list = dataInfo.data;
      }
    },
    // 下一页
    async nextPage() {
      this.page++;
      const dataInfo = await this.getLocalListByPage(this.page);
      if (dataInfo.data.length > 0) {
        this.list = dataInfo.data;
      } else {
        this.list = [];
      }
    },
    // 跳转
    async jumpPage(page) {
      this.page = Number(page);
      const dataInfo = await this.getLocalListByPage(this.page);
      if (dataInfo.data.length > 0) {
        this.list = dataInfo.data;
      } else {
        this.list = [];
      }
    },
    // 根据页码获取本地数据库列表数据
    async getLocalListByPage(page) {
      const isStarred =
        this.subMenuRecord.name == "mark" || this.type == "mark";
      const commonParams = {
        page: page,
        pageSize: this.pageSize,
        mailAddress: this.mailRecord.mailAddress,
      };
      if (isStarred) {
        if (this.listsProperties == "all") {
          commonParams.isStarred = true;
        } else {
          const folder = this.mailRecord.subMenu.find(
            (item) => item.name === this.listsProperties
          );
          commonParams.folder = folder.folder_name;
          commonParams.isStarred = true;
        }
      } else {
        commonParams.folder = this.subMenuRecord.folder_name;
        commonParams.type = this.listsProperties;
      }
      try {
        let result = await getIndexedDBMailList(commonParams);
        const handlerData = await this.matchEmailAddressToContactName(
          result.data
        );
        this.currentListCount = result.total;
        return {
          data: handlerData,
          count: result.total,
        };
      } catch (error) {
        console.error("Error fetching local list:", error);
        return {
          data: [],
          count: 0,
        };
      }
    },
    async matchEmailAddressToContactName(data) {
      const contacts = await getLocalEmailContactsList();
      if (contacts.length > 0 && data.length > 0) {
        data.forEach((item) => {
          let showEmail = "";
          if (this.type == "sent") {
            showEmail = item.to ? item.to : item.cc;
          } else {
            showEmail = item.from ? item.from : item.cc;
          }
          const contact = contacts.find((contact) => {
            return contact.email == showEmail;
          });
          item.contactName = contact ? contact.name : null;
        });
      }
      return data;
    },
    // 批量处理获取到的邮箱数据
    async processBatchData(
      currentBatchData,
      mailAddress,
      folder_name,
      recentLocalTimestamp
    ) {
      // 拦截非当前邮箱的操作
      if (this.currentEmailAccount.mailAddress != mailAddress) {
        return;
      }
      // 拦截非当前邮箱的操作
      if (this.currentSubMenuInfo.folder_name != folder_name) {
        return;
      }
      // 这里注释的是通过本地最新邮件的时间截取这个时间之后的数据，由于服务器返回邮件时间不稳定，这个方法先注释，直接将获取的邮件批量放入数据库就行
      // let dexieTotalEmails = [];
      // 取出新邮件
      // if (recentLocalTimestamp > 0) {
      //   dexieTotalEmails = currentBatchData.filter(
      //     (email) => email.sendTimestamp > recentLocalTimestamp
      //   );
      // } else {
      //   dexieTotalEmails = currentBatchData;
      // }
      if (currentBatchData.length > 0) {
        // 根据新数据删除本地设置为发送中的邮件
        await this.updateSentFolderStatus(
          currentBatchData,
          this.currentEmailAccount.mailAddress,
          this.currentSubMenuInfo.folder_name
        );
        try {
          await bulkAddEmails({
            emails: currentBatchData,
            mailAddress: this.currentEmailAccount.mailAddress,
            folder_name: this.currentSubMenuInfo.folder_name,
          });
        } catch (error) {
          console.log(error);
        }
        await this.handleBouncedEmails(
          this.currentEmailAccount.mailAddress,
          this.currentSubMenuInfo.folder_name
        );
      }
      let locallyCachedData = [];
      try {
        locallyCachedData = await getIndexedDBMailList({
          page: this.page,
          pageSize: this.pageSize,
          mailAddress: mailAddress,
          folder: folder_name,
        });
      } catch (error) {
        console.log(error);
      }

      // 更新IM邮件提示标未读数
      await this.handlerUnreadEmailNum();
      // 更新菜单显示未读数
      this.$emit("listIsRefreshed", {
        type: this.type,
        size: null,
      });
      this.list = await this.matchEmailAddressToContactName(
        locallyCachedData.data
      );
      this.$store.commit("setMailsListViewLoading", false);
      this.listLoading = false;
    },
    // 如果是已发送或者草稿箱，则需要更新本地数据的一些状态
    async updateSentFolderStatus(data, mailAddress, folder_name) {
      if (folder_name == "Sent") {
        let uniqueKeyArray = [];
        data.forEach((item) => {
          const key = `${mailAddress}/${folder_name}/${item.messageId}`;
          uniqueKeyArray.push(key);
        });
        try {
          const result = await getEmailsByUniqueKeys(uniqueKeyArray);
          if (result.length > 0) {
            let bcc;
            let newItem = data.find((el) => {
              return result.some((ele) => {
                if (ele.messageId == el.messageId) {
                  bcc = ele.bcc;
                  return true;
                } else {
                  return false;
                }
              });
            });
            newItem.bcc = bcc;
          }
          await deleteEmailsByUniqueKey(uniqueKeyArray);
        } catch (error) {
          console.log(error);
        }
      }
    },
    // 获取退信邮件并标记被退信邮件为发送失败
    async handleBouncedEmails(mailAddress, folder_name) {
      if (folder_name == "Sent") {
        const messageIds = await getPostmasterEmails(mailAddress, "INBOX");
        await markSentEmailsAsFailed(messageIds);
      }
    },
    // 获取邮件的唯一标识
    getUniqueIdentifier(item) {
      return `${this.mailRecord.mailAddress}/${item.folder_name}/${item.uid}`;
    },
  },
};
