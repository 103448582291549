<template>
  <div class="sendEmailBox">
    <!-- 上传邮件内容进度 -->
    <div class="uploadProgress" v-if="editorLoading">
      <el-progress
        type="circle"
        :percentage="uploadPercent"
        class="progressInner"
        :width="60"
        :color="'#F39945'"
      ></el-progress>
    </div>
    <!-- 上传超大附件进度 -->
    <div class="uploadProgress" v-if="largeAttachmentProgress">
      <el-progress
        type="circle"
        :percentage="Number(largeAttachmentProgress.progress)"
        class="progressInner"
        :width="60"
        :color="'#F39945'"
      ></el-progress>
    </div>
    <div class="operationArea ">
      <div class="leftBox">
        <span
          class="iconfont_Me icon-return backStyle"
          @click.stop="goBack"
        ></span>
        <el-tooltip effect="dark" :content="$t('sending')" placement="top">
          <div
            class="sendEmail"
            :class="disable ? 'disableStyle' : ''"
            @click.stop="sendEmail(false)"
          >
            <span class="iconfont_Me icon-send-fill sendStyle"></span>
          </div>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :content="$t('cancelSending')"
          placement="top"
        >
          <el-image
            @click.stop="cancelSave"
            style="width: 20px; height: 20px"
            :src="
              require('../../../../../../assets/images/email/send/doNotSave.png')
            "
            :fit="'cover'"
          ></el-image>
        </el-tooltip>
      </div>
      <div class="centerBox">
        <div
          class="menu-content"
          @click.stop="openSelectModel"
          v-if="selectedFrom"
        >
          <span class="menu-title" :title="selectedFrom.name">{{
            selectedFrom.name
          }}</span>
          <span class="menu-note" :title="selectedFrom.mailAddress">
            <{{ selectedFrom.mailAddress }}>
          </span>
        </div>
        <el-select
          v-model="emailFromModel"
          popper-class="selectEmailFrom"
          ref="emailFromSelector"
        >
          <el-option
            v-for="item in emailFromOptions"
            :key="item.originProviderId"
            :label="item.name"
            :value="item.originProviderId"
          >
            <div class="selectEmailFromItem">
              <span class="EmailFromName commonItemStyle" :title="item.name">
                {{ item.name }}
              </span>
              <span
                class="EmailFromContent commonItemStyle"
                :title="item.mailAddress"
              >
                <{{ item.mailAddress }}>
              </span>
            </div>
          </el-option>
        </el-select>
      </div>
      <div class="windowChangeBox">
        <span
          class="imelink-miniscreen"
          v-show="this.IMWindowSize != 'mini'"
          @click="changeScreen('mini')"
        >
          <SizeButton :buttonType="'minimize'"></SizeButton>
        </span>
        <span
          class="imelink-fullscreen"
          v-show="this.IMWindowSize == 'medium'"
          @click="changeScreen('full')"
        >
          <SizeButton></SizeButton>
        </span>
        <span
          class="imelink-fullscreen"
          v-show="this.IMWindowSize == 'full'"
          @click="changeScreen('medium')"
        >
          <SizeButton :buttonType="'full'"></SizeButton>
        </span>
      </div>
    </div>
    <div class="containerBox">
      <div class="inputArea">
        <div class="innerInputBox">
          <div class="lineBox" v-for="item in lineInputs" :key="item.key">
            <span class="title"> {{ item.title }} : </span>
            <div class="lineContent">
              <template v-if="item.key == 0">
                <el-popover
                  placement="bottom"
                  trigger="manual"
                  v-model="recipientPopover"
                  popper-class="recipientPopover"
                >
                  <template v-if="recipientPromptTexts.length > 0">
                    <PopoverList
                      ref="recipientPopoverList"
                      :popoverWidth="recipientPopoverWidth"
                      :promptTexts="recipientPromptTexts"
                      @clickItem="clickRecipient"
                    ></PopoverList>
                  </template>
                  <div
                    class="lineBoxInputStyle"
                    ref="recipientInputBox"
                    slot="reference"
                  >
                    <div
                      class="lineBoxInner"
                      @click.stop="clickInputOutBox(item.inputRef)"
                    >
                      <draggable
                        v-model="recipients"
                        animation="1000"
                        :group="{ name: 'emails', pull: true, put: true }"
                        class="itemList"
                        :sort="false"
                        touchStartThreshold="0px"
                        ghostClass="draggablegGhostClass"
                      >
                        <div
                          class="item"
                          v-for="recipient in recipients"
                          :key="recipient.contactsId"
                          :style="{
                            border: recipient.isEmail
                              ? 'none'
                              : '1px solid red',
                          }"
                        >
                          <span
                            class="nickname commonStyle"
                            :title="recipient.name"
                            :style="{ color: recipient.isEmail ? '' : 'red' }"
                          >
                            {{ recipient.name }}
                          </span>
                          <span
                            class="email commonStyle"
                            :title="recipient.email"
                            :style="{ color: recipient.isEmail ? '' : 'red' }"
                          >
                            <{{ recipient.email }}>
                          </span>
                          <div
                            class="delete"
                            @click.stop="deleteRecipient(recipient)"
                          >
                            <span class="iconfont_Me icon-x iconStyle"></span>
                          </div>
                        </div>
                        <input
                          class="itemInput"
                          :ref="item.inputRef"
                          v-model="recipientEmailText"
                          @blur.stop="addRecipientEmail('blur')"
                          @keydown.enter="addRecipientEmail('keydown')"
                          @keydown.stop="handlerRecipientKeydown"
                          @compositionstart="handlerCompositionstart"
                          @compositionend="handlerCompositionend"
                          @input="inputRecipientEmail"
                          @keydown.up="
                            (event) => {
                              handlerKeyUp(
                                event,
                                'recipientPopoverList',
                                'recipientPromptTexts'
                              );
                            }
                          "
                          @keydown.down="
                            (event) => {
                              handlerKeyDown(
                                event,
                                'recipientPopoverList',
                                'recipientPromptTexts'
                              );
                            }
                          "
                        />
                      </draggable>
                      <div class="addIcon" @click.stop="addRecipient">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="
                            require('../../../../../../assets/images/email/send/add.png')
                          "
                          :fit="'cover'"
                        ></el-image>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </template>
              <template v-if="item.key == 1">
                <el-popover
                  placement="bottom"
                  trigger="manual"
                  v-model="ccPopover"
                  popper-class="ccAndbccPopover"
                >
                  <template v-if="ccPromptTexts.length > 0">
                    <PopoverList
                      ref="ccPopoverList"
                      :popoverWidth="ccPopoverWidth"
                      :promptTexts="ccPromptTexts"
                      @clickItem="
                        (item) => {
                          clickCcOrBcc(item, 'cc');
                        }
                      "
                    ></PopoverList>
                  </template>
                  <div
                    class="lineBoxInputStyle"
                    ref="ccInputBox"
                    slot="reference"
                  >
                    <div
                      class="lineBoxInner"
                      @click.stop="clickInputOutBox(item.inputRef)"
                    >
                      <draggable
                        v-model="Ccs"
                        animation="1000"
                        :group="{ name: 'emails', pull: true, put: true }"
                        class="itemList"
                        :sort="false"
                        touchStartThreshold="0px"
                        ghostClass="draggablegGhostClass"
                      >
                        <div
                          class="item"
                          v-for="(Cc, index) in Ccs"
                          :key="index"
                          :style="{
                            border: Cc.isEmail ? 'none' : '1px solid red',
                          }"
                        >
                          <span
                            class="nickname commonStyle"
                            :title="Cc.name"
                            :style="{ color: Cc.isEmail ? '' : 'red' }"
                          >
                            {{ Cc.name }}
                          </span>
                          <span
                            class="email commonStyle"
                            :title="Cc.email"
                            :style="{
                              color: Cc.isEmail ? '' : 'red',
                            }"
                          >
                            <{{ Cc.email }}>
                          </span>
                          <div
                            class="delete"
                            @click.stop="deleteCcOrBcc(index)"
                          >
                            <span class="iconfont_Me icon-x iconStyle"></span>
                          </div>
                        </div>
                        <input
                          class="itemInput"
                          :ref="item.inputRef"
                          v-model="CcEmailText"
                          @blur.stop="addCcOrBccEmail('blur')"
                          @keydown.enter="addCcOrBccEmail('keydown')"
                          @compositionstart="handlerCompositionstart"
                          @compositionend="handlerCompositionend"
                          @keydown.stop="
                            (event) => {
                              handlerCcOrBccKeydown(event, 'cc');
                            }
                          "
                          @input="inputCcOrBccEmail('cc')"
                          @keydown.up="
                            (event) => {
                              handlerKeyUp(
                                event,
                                'ccPopoverList',
                                'ccPromptTexts'
                              );
                            }
                          "
                          @keydown.down="
                            (event) => {
                              handlerKeyDown(
                                event,
                                'ccPopoverList',
                                'ccPromptTexts'
                              );
                            }
                          "
                        />
                      </draggable>
                      <div
                        class="bccButton"
                        @click="startInputBcc"
                        v-if="!showBccInput"
                      >
                        {{ $t("bcc") }}
                      </div>
                      <div class="addIcon" @click.stop="addCcOrBcc('cc')">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="
                            require('../../../../../../assets/images/email/send/add.png')
                          "
                          :fit="'cover'"
                        ></el-image>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </template>
              <template v-if="item.key == 2">
                <el-popover
                  placement="bottom"
                  trigger="manual"
                  v-model="bccPopover"
                  popper-class="ccAndbccPopover"
                >
                  <template v-if="bccPromptTexts.length > 0">
                    <PopoverList
                      ref="bccPopoverList"
                      :popoverWidth="bccPopoverWidth"
                      :promptTexts="bccPromptTexts"
                      @clickItem="
                        (item) => {
                          clickCcOrBcc(item, 'bcc');
                        }
                      "
                    ></PopoverList>
                  </template>
                  <div
                    class="lineBoxInputStyle"
                    ref="bccInputBox"
                    slot="reference"
                  >
                    <div
                      class="lineBoxInner"
                      @click.stop="clickInputOutBox(item.inputRef)"
                    >
                      <draggable
                        v-model="Bccs"
                        animation="1000"
                        :group="{ name: 'emails', pull: true, put: true }"
                        class="itemList"
                        @click.stop="clickInputOutBox(item.inputRef)"
                        :sort="false"
                        touchStartThreshold="0px"
                        ghostClass="draggablegGhostClass"
                      >
                        <div
                          class="item"
                          v-for="(bcc, index) in Bccs"
                          :key="index"
                          :style="{
                            border: bcc.isEmail ? 'none' : '1px solid red',
                          }"
                        >
                          <span
                            class="nickname commonStyle"
                            :title="bcc.name"
                            :style="{ color: bcc.isEmail ? '' : 'red' }"
                          >
                            {{ bcc.name }}
                          </span>
                          <span
                            class="email commonStyle"
                            :title="bcc.email"
                            :style="{
                              color: bcc.isEmail ? '' : 'red',
                            }"
                          >
                            <{{ bcc.email }}>
                          </span>
                          <div
                            class="delete"
                            @click.stop="deleteCcOrBcc(index, 'bcc')"
                          >
                            <span class="iconfont_Me icon-x iconStyle"></span>
                          </div>
                        </div>
                        <input
                          class="itemInput"
                          :ref="item.inputRef"
                          v-model="BccEmailText"
                          @blur.stop="addCcOrBccEmail('blur', 'bcc')"
                          @keydown.enter="addCcOrBccEmail('keydown', 'bcc')"
                          @compositionstart="handlerCompositionstart"
                          @compositionend="handlerCompositionend"
                          @keydown.stop="
                            (event) => {
                              handlerCcOrBccKeydown(event, 'bcc');
                            }
                          "
                          @input="inputCcOrBccEmail('bcc')"
                          @keydown.up="
                            (event) => {
                              handlerKeyUp(
                                event,
                                'bccPopoverList',
                                'bccPromptTexts'
                              );
                            }
                          "
                          @keydown.down="
                            (event) => {
                              handlerKeyDown(
                                event,
                                'bccPopoverList',
                                'bccPromptTexts'
                              );
                            }
                          "
                        />
                      </draggable>
                      <div class="addIcon" @click.stop="addCcOrBcc('bcc')">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="
                            require('../../../../../../assets/images/email/send/add.png')
                          "
                          :fit="'cover'"
                        ></el-image>
                      </div>
                      <div class="closeIcon" @click.stop="deleteBccInput">
                        <i class="iconfont_Me icon-x"></i>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </template>
              <template v-if="item.key == 3">
                <el-input
                  v-model="emailSubject"
                  :ref="item.inputRef"
                  maxlength="120"
                  :style="{
                    height: '26px',
                  }"
                ></el-input>
              </template>
            </div>
          </div>
        </div>
        <div class="sizeButtonBox">
          <i
            class="iconfont_Me iconStyle"
            @click.stop="changeInputAreaSize"
            :class="[hiddenInputArea ? 'icon-down' : 'icon-up']"
          ></i>
        </div>
      </div>
      <div class="richTextBox">
        <div class="richTextCore" v-resize-ob="resizeEditorBox">
          <Eleditor
            class="el-editorInner"
            :editorModel="editorModel"
            @ready="onEditorReady"
            @change="onEditorChange"
            @blur="onEditorBlur"
            @focus="onEditorFocus"
            @uploadAttachment="richAttachment"
            @addOriginalAttachment="addOriginalAttachment"
          >
            <template #footer>
              <div
                class="emailAttachment"
                v-if="waitingUploadedFiles.length != 0 || downLoading"
                v-loading="downLoading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="#FFFFFF"
              >
                <div class="attachmentCore">
                  <EmailAttachmentItem
                    v-for="(attachment, index) in handleWaitingUploadedFiles"
                    :key="index"
                    :showDelete="true"
                    :attachment="attachment"
                    :operationStatus="false"
                    @deleteAttachment="deleteAttachment"
                  />
                </div>
                <div
                  class="displayFiles"
                  v-if="waitingUploadedFiles.length > attachmentsSliceNumber"
                >
                  <div class="displayImg" @click="changeAttachmentList">
                    <img
                      :src="
                        require('../../../../../../assets/images/email/drop_down .png')
                      "
                      :style="{
                        transform: attachmentListStatus ? 'rotate(180deg)' : '',
                      }"
                    />
                  </div>
                  <div class="displayText" @click="changeAttachmentList">
                    {{
                      attachmentListStatus
                        ? $t("hide")
                        : $t("displayAttachments", {
                            m: $t("show"),
                            n: waitingUploadedFiles.length,
                          })
                    }}
                  </div>
                </div>
              </div>
            </template>
          </Eleditor>
        </div>
      </div>
    </div>
    <el-upload
      v-show="false"
      ref="uploadAttachment"
      action="/"
      :multiple="true"
      :show-file-list="false"
      :http-request="handlerUpload"
      :before-upload="beforeFileUpload"
    ></el-upload>
  </div>
</template>
<script>
import {
  sendEmail,
  attachmentDownload,
  markEmails,
  exportEmlFile,
} from "@/api/newVersion/melinked_email";
import EmailAttachmentItem from "../components/attachmentItem.vue";
import Eleditor from "./element_editor";
import moment from "moment";
import isEmail from "validator/lib/isEmail";
import { handlerUploadFileToQiniu } from "@/api/qiniu";
import {
  deleteEmailsByUniqueKey,
  storeSendingEmails,
} from "@/api/indexDB/api/email_api";
import { getLocalEmailContactsList } from "@/api/indexDB/api/email_address_book_api";
import draggable from "vuedraggable";
import PopoverList from "./components/popoverList.vue";
import SizeButton from "@/newVersion/components/im_size_button";
export default {
  components: {
    EmailAttachmentItem,
    Eleditor,
    draggable,
    PopoverList,
    SizeButton,
  },
  computed: {
    lineInputs() {
      const baseInputs = [
        {
          key: 0,
          inputRef: "recipientInput",
          title: this.$t("emailRecipient"),
        },
        {
          key: 1,
          inputRef: "ccInput",
          title: this.$t("CcTo"),
        },
        {
          key: 3,
          inputRef: "subjectInput",
          title: this.$t("emailSubject"),
        },
      ];
      return this.hiddenInputArea
        ? [...baseInputs.slice(0, 1)]
        : this.showBccInput
        ? [
            ...baseInputs.slice(0, 2),
            { key: 2, inputRef: "bccInput", title: this.$t("bcc") },
            ...baseInputs.slice(2),
          ]
        : baseInputs;
    },
    selectedFrom() {
      return this.emailFromOptions.filter((item) => {
        return item.originProviderId == this.emailFromModel;
      })[0];
    },
    currentEmailAccount() {
      return this.$store.state.melinked_email.currentEmailAccount;
    },
    openEmailView() {
      return this.$store.state.melinked_email.openEmailView;
    },
    emailAccounts() {
      return this.$store.state.melinked_email.emailAccounts;
    },
    melinked_email_token() {
      return this.$store.state.melinked_email.melinked_email_token;
    },
    sendModuleSelectedItems() {
      return this.$store.state.melinked_email.sendModuleSelectedItems;
    },
    listType() {
      return this.$store.state.melinked_email.addressBookDialogType;
    },
    disable() {
      return (
        this.recipients.length == 0 ||
        this.emailSubject == "" ||
        !this.selectedFrom ||
        this.recipients.some((recipient) => !recipient.isEmail) ||
        this.Ccs.some((Cc) => !Cc.isEmail) ||
        this.editorLoading ||
        this.uploadLargeAttachmentProgress
      );
    },
    // 子菜单
    emailSubMenus() {
      return this.$store.state.melinked_email.emailSubMenus;
    },
    // 配置项
    emailConfig() {
      return this.$store.state.melinked_email.emailConfig;
    },
    // 窗口尺寸
    IMWindowSize() {
      return this.$store.state.imStore.IMWindowSize;
    },
    handleWaitingUploadedFiles() {
      return this.attachmentListStatus
        ? this.waitingUploadedFiles
        : this.waitingUploadedFiles.slice(0, this.attachmentsSliceNumber);
    },
  },
  watch: {
    currentEmailAccount: {
      handler(val) {
        if (val) {
          this.emailFromModel = val.originProviderId;
        }
      },
      deep: true,
      immediate: true,
    },
    sendModuleSelectedItems: {
      handler(val, old) {
        if (val.length != 0) {
          switch (this.listType) {
            case 0:
              this.verifySelectedItemRepeat(val, this.recipients);
              break;
            case 1:
              this.verifySelectedItemRepeat(val, this.Ccs);
              break;
            case 2:
              this.verifySelectedItemRepeat(val, this.Bccs);
              break;
          }
        }
      },
      deep: true,
    },
    IMWindowSize: {
      handler(val, old) {
        if (val != old) {
          this.closeInputPopover();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      editor: null,
      editorModel: "",
      editorHtml: "",
      emailSubject: "",
      recipientEmailText: "",
      CcEmailText: "",
      recipients: [],
      emailSelectOpen: false,
      emailFromModel: "",
      emailFromOptions: [],
      Ccs: [],
      waitingUploadedFiles: [],
      editorLoading: false,
      downLoading: false,
      uploadPercent: 0,
      recipientPopover: false,
      recipientPromptTexts: [],
      recipientPopoverWidth: 0,
      ccPopover: false,
      ccPromptTexts: [],
      ccPopoverWidth: 0,
      Bccs: [],
      BccEmailText: [],
      bccPopover: false,
      bccPromptTexts: [],
      bccPopoverWidth: 0,
      largeAttachmentProgress: null,
      attachmentListStatus: false,
      attachmentsSliceNumber: 0,
      showBccInput: false,
      contactsList: [],
      compositionStatus: false,
      hiddenInputArea: false,
    };
  },
  async mounted() {
    if (this.emailAccounts.length != 0) {
      this.emailFromOptions = _.cloneDeep(this.emailAccounts);
    }
    this.contactsList = await getLocalEmailContactsList();
  },
  beforeDestroy() {
    Object.assign(this.$data, this.$options.data.call(this));
    window.deleteLargeAttachment = null;
    if (this.openEmailView) {
      this.$store.commit("setOpenEmailSendView", null);
    }
  },
  methods: {
    changeInputAreaSize() {
      this.hiddenInputArea = !this.hiddenInputArea;
    },
    handlerName(item) {
      let name = item.name;
      if (item.name == "") {
        name = item.mailAddress.split("@")[0];
      }
      return name;
    },
    changeScreen(type) {
      if (!["full", "medium", "mini"].includes(type)) return;
      this.$store.commit("setIMWindowSize", type);
    },
    handlerKeyUp(event, refKey, list) {
      event.preventDefault();
      if (this[list].length > 0 && this.$refs[refKey].length) {
        this.$refs[refKey][0].clickUp();
      }
    },
    handlerKeyDown(event, refKey, list) {
      event.preventDefault();
      if (this[list].length > 0 && this.$refs[refKey].length) {
        this.$refs[refKey][0].clickDown();
      }
    },
    // 点击输入框外
    clickInputOutBox(inputRef) {
      try {
        this.$refs[inputRef][0].focus();
      } catch (error) {}
    },
    changeAttachmentList() {
      this.attachmentListStatus = !this.attachmentListStatus;
    },
    async onEditorReady(editor) {
      setTimeout(async () => {
        this.editor = editor;
        if (!window.deleteLargeAttachment) {
          window.deleteLargeAttachment = this.deleteLargeAttachment;
        }
        if (this.openEmailView) {
          switch (this.openEmailView.type) {
            // 直接填充发送
            case "send":
              this.fillInSignature();
              if (
                this.openEmailView.emailAddress &&
                typeof this.openEmailView.emailAddress == "string"
              ) {
                this.recipients = [
                  {
                    name: "",
                    email: this.openEmailView.emailAddress,
                    isEmail: isEmail(this.openEmailView.emailAddress),
                  },
                ];
              }
              if (this.openEmailView.emailContent) {
                this.editorModel = this.handleContentWrap(
                  this.openEmailView.emailContent
                );
                this.editorHtml = this.editorModel;
              }
              break;
            // 回复单封
            case "reply":
              this.fillInSignature();
              const replyItemInfo = _.cloneDeep(
                this.openEmailView.replyItemInfo
              );
              this.emailSubject = `${this.$t("replay")} :  ${
                replyItemInfo.subject
              }`;
              if (typeof replyItemInfo.from == "string") {
                const hanlerName = this.getEmailAddressBookName(
                  replyItemInfo.from
                );
                this.recipients.push({
                  name: hanlerName || replyItemInfo.from.split("@")[0],
                  email: replyItemInfo.from,
                  isEmail: isEmail(replyItemInfo.from),
                });
              }
              try {
                // 切换cid图片
                replyItemInfo.content = this.handlerCidImageHtmlString(
                  replyItemInfo.content,
                  replyItemInfo
                );
              } catch (error) {}
              this.fillInOriginalInfo(replyItemInfo);
              break;
            // 回复所有
            case "replyAll":
              this.fillInSignature();
              const replyAllItemInfo = _.cloneDeep(
                this.openEmailView.replyAllItemInfo
              );
              this.emailSubject = `${this.$t("replay")} :  ${
                replyAllItemInfo.subject
              }`;
              if (typeof replyAllItemInfo.from == "string") {
                const hanlerName = this.getEmailAddressBookName(
                  replyAllItemInfo.from
                );
                this.recipients.push({
                  name: hanlerName || replyAllItemInfo.from.split("@")[0],
                  email: replyAllItemInfo.from,
                  isEmail: isEmail(replyAllItemInfo.from),
                });
              }
              if (
                typeof replyAllItemInfo.to == "string" &&
                replyAllItemInfo.to.trim() != ""
              ) {
                const tos = replyAllItemInfo.to.split(",");
                const newRecipients = tos.filter(
                  (to) => to != this.currentEmailAccount.mailAddress
                );
                if (newRecipients.length > 0) {
                  newRecipients.forEach((recipient) => {
                    const hanlerName = this.getEmailAddressBookName(recipient);
                    this.recipients.push({
                      name: hanlerName || recipient.split("@")[0],
                      email: recipient,
                      isEmail: isEmail(recipient),
                    });
                  });
                }
              }
              if (
                typeof replyAllItemInfo.cc == "string" &&
                replyAllItemInfo.cc.trim() != ""
              ) {
                const Ccs = replyAllItemInfo.cc.split(",");
                const newCcs = Ccs.filter(
                  (cc) => cc != this.currentEmailAccount.mailAddress
                );
                if (newCcs.length > 0) {
                  newCcs.forEach((recipient) => {
                    const hanlerName = this.getEmailAddressBookName(recipient);
                    this.Ccs.push({
                      name: hanlerName || recipient.split("@")[0],
                      email: recipient,
                      isEmail: isEmail(recipient),
                    });
                  });
                }
              }
              try {
                // 切换cid图片
                replyAllItemInfo.content = this.handlerCidImageHtmlString(
                  replyAllItemInfo.content,
                  replyAllItemInfo
                );
              } catch (error) {}
              this.fillInOriginalInfo(replyAllItemInfo);
              break;
            // 单封转发邮件
            case "forward":
              this.fillInSignature();
              const forwardItemInfo = _.cloneDeep(
                this.openEmailView.replyItemInfo
              );
              this.emailSubject = `${this.$t("forward")} :  ${
                forwardItemInfo.subject
              }`;
              if (
                forwardItemInfo.attachments &&
                forwardItemInfo.attachments.length != 0
              ) {
                await this.download(forwardItemInfo);
              }
              // 切换cid图片
              forwardItemInfo.content = this.handlerCidImageHtmlString(
                forwardItemInfo.content,
                forwardItemInfo
              );
              this.fillInOriginalInfo(forwardItemInfo);
              break;
            // 草稿邮件再编辑
            case "drafts":
              const itemInfo = _.cloneDeep(this.openEmailView.itemInfo);
              this.defaultFillDraftOrResendEmailContent(itemInfo);
              break;
            // 已发送邮件再编辑
            case "edit":
              const editItemInfo = _.cloneDeep(this.openEmailView.editItemInfo);
              this.defaultFillDraftOrResendEmailContent(editItemInfo);
              break;
            // 批量转发
            case "batchForward":
              this.fillInSignature();
              const batchForwardList = _.cloneDeep(
                this.openEmailView.batchForwardList
              );
              this.downloadfiles_EML(batchForwardList);
              break;
          }
        } else {
          this.fillInSignature();
        }
        if (this.editor && this.editorModel != "") {
          this.editor.focus();
        }
        this.changeEditorBarStyle();
      }, 0);
    },

    async downloadfiles_EML(itemList) {
      const password = await this.globalVerifyEmailPassword(
        this.currentEmailAccount,
        false
      );
      const createdToken = {
        u: this.currentEmailAccount.mailAddress,
        p: password,
      };
      const promises = itemList.map((item) => {
        return new Promise(async (resolve, reject) => {
          try {
            let params = {
              uid: item.uid,
              folder: item.folder,
              fileName: item.fileName,
              mailboxId: item.mailboxId,
              individualAesToten: JSON.stringify(createdToken),
            };
            const result = await exportEmlFile(params);
            const file = this.resultToFile(result, `${item.fileName}.eml`);
            resolve(file);
          } catch (err) {
            this.$message({
              message: `${item.fileName}:转化EML失败`,
              type: "error",
            });
            resolve(null);
          }
        }).catch((e) => {});
      });
      this.downLoading = true;
      const results = await Promise.all(promises);
      this.downLoading = false;
      results.map((result) => {
        result && this.waitingUploadedFiles.push(result);
      });
    },
    // 将 result 字符串转换为 File 对象
    resultToFile(result, filename) {
      const arrayBuffer = new TextEncoder().encode(result);
      const fileBlob = new Blob([arrayBuffer], { type: "message/rfc822" });
      return new File([fileBlob], filename);
    },
    // 填充签名
    fillInSignature() {
      if (
        this.currentEmailAccount &&
        this.currentEmailAccount.signInfo[0] &&
        this.currentEmailAccount.signInfo[0].sign
      ) {
        const selector = document.getElementsByClassName(
          "el-tiptap-editor__content"
        );
        const selectorInner = document.getElementsByClassName("ProseMirror");
        let newNode = document.createElement("div");
        newNode.setAttribute("data-tag", "melinkedSignature");
        newNode.setAttribute("contenteditable", "false");
        newNode.setAttribute(
          "style",
          "width:100%; min-height:100px; overflow:hidden;line-height: normal;position: relative;box-sizing: border-box;padding: 18px 0;border-top: 1px solid #f0f0f0;"
        );
        newNode.id = "signatureBlock";
        newNode.innerHTML = this.currentEmailAccount.signInfo[0].sign;
        if (selector[0] && selectorInner[0]) {
          var nextSibling = selectorInner[0].nextSibling;
          if (nextSibling) {
            selector[0].insertBefore(newNode, nextSibling);
          } else {
            selector[0].appendChild(newNode);
          }
        }
      }
    },
    // 默认填充草稿邮件内容或者填充再次编辑的已发送邮件
    async defaultFillDraftOrResendEmailContent(itemInfo) {
      this.emailSubject = itemInfo.subject;
      if (typeof itemInfo.to == "string" && itemInfo.to.trim() != "") {
        const recipients = itemInfo.to.split(",");
        recipients.map((item) => {
          if (item) {
            const hanlerName = this.getEmailAddressBookName(item);
            this.recipients.push({
              name: hanlerName || item.split("@")[0],
              email: item,
              isEmail: isEmail(item),
            });
          }
        });
      }
      if (typeof itemInfo.cc == "string" && itemInfo.cc.trim() != "") {
        const Ccs = itemInfo.cc.split(",");
        Ccs.map((item) => {
          if (item) {
            const hanlerName = this.getEmailAddressBookName(item);
            this.Ccs.push({
              name: hanlerName || "",
              email: item,
              isEmail: isEmail(item),
            });
          }
        });
      }
      if (typeof itemInfo.bcc == "string" && itemInfo.bcc.trim() != "") {
        const Bccs = itemInfo.bcc.split(",");
        Bccs.map((item) => {
          if (item) {
            const hanlerName = this.getEmailAddressBookName(item);
            this.Bccs.push({
              name: hanlerName || "",
              email: item,
              isEmail: isEmail(item),
            });
          }
        });
        if (this.Bccs.length > 0) {
          this.showBccInput = true;
        }
      }
      if (itemInfo.content) {
        itemInfo.content = this.handlerCidImageHtmlString(
          itemInfo.content,
          itemInfo
        );
        this.processDraftsContent(itemInfo.content);
      }
      if (itemInfo.attachments && itemInfo.attachments.length != 0) {
        await this.download(itemInfo);
      }
    },
    clickCcOrBcc(item, type = "cc") {
      let arr = [];
      switch (type) {
        case "cc":
          this.CcEmailText = "";
          arr = this.Ccs;
          break;
        case "bcc":
          this.BccEmailText = "";
          arr = this.Bccs;
          break;
      }
      this.$nextTick(async () => {
        const repeat = this.verifyEmailRepeat(arr, item.email);
        if (!repeat) {
          const hanlerName = this.getEmailAddressBookName(item.email);
          arr.push({
            name: hanlerName || item.name,
            email: item.email,
            isEmail: isEmail(item.email),
          });
          try {
            if (type == "cc") {
              this.$refs.ccInput[0].focus();
            } else {
              this.$refs.bccInput[0].focus();
            }
          } catch (error) {}
        } else {
          this.$message({
            type: "error",
            message: this.$t("doNotRepeatInput"),
          });
        }
        this.closeInputPopover();
      });
    },
    async handleInputEmail(emailText, inputBoxRef, promptTextsKey) {
      if (emailText !== "") {
        try {
          let inputPromptTexts = [];
          console.time("查询总耗时");
          const result = await this.searchLocalEmailAddressBook(emailText);
          result.data.map((data) => {
            let name = "",
              email = "";
            if (
              data.email.indexOf(emailText) != -1 ||
              data.name.indexOf(emailText) != -1
            ) {
              name = data.name.replace(
                new RegExp(emailText, "g"),
                "<span style='color:#FF0000'>" + emailText + "</span>"
              );
              email = data.email.replace(
                new RegExp(emailText, "g"),
                "<span style='color:#FF0000'>" + emailText + "</span>"
              );
            }
            inputPromptTexts.push({
              name: data.name,
              email: data.email,
              HTML: `<span style='color:#292D32;font-size:14px;line-height:20px'>${name}</span><span style='color:#B3B3B3;font-size:14px;line-height:20px'>&lt;${email}&gt;</span>`,
            });
          });
          // 如果通讯录有数据，就先展示通讯录数据
          if (inputPromptTexts.length > 0) {
            this[promptTextsKey] = this.filterAnArrayOfObjects(
              inputPromptTexts,
              "email"
            );
            await this.updatePopoverWidth(inputBoxRef);
            this[inputBoxRef.replace("InputBox", "Popover")] =
              this[promptTextsKey].length > 0 ? true : false;
          }
          const dexie_result = await this.searchLocalDbEmailAddress(emailText);
          const promptTexts = [...inputPromptTexts, ...dexie_result];
          this[promptTextsKey] = this.filterAnArrayOfObjects(
            promptTexts,
            "email"
          );
        } catch (error) {}
        await this.updatePopoverWidth(inputBoxRef);
        console.timeEnd("查询总耗时");
        this[inputBoxRef.replace("InputBox", "Popover")] =
          this[promptTextsKey].length > 0 ? true : false;
      } else {
        this.closeInputPopover();
      }
    },
    // 更新 Popover 宽度的辅助方法
    updatePopoverWidth(inputBoxRef) {
      return new Promise((resolve) => {
        try {
          const inputBox = this.$refs[inputBoxRef][0];
          const { width } = inputBox.getBoundingClientRect();
          this[inputBoxRef.replace("InputBox", "PopoverWidth")] = width;
        } catch (error) {
          this[inputBoxRef.replace("InputBox", "PopoverWidth")] = 800;
        }
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async inputCcOrBccEmail(type = "cc") {
      await this.handleInputEmail(
        type == "cc" ? this.CcEmailText : this.BccEmailText,
        type == "cc" ? "ccInputBox" : "bccInputBox",
        type == "cc" ? "ccPromptTexts" : "bccPromptTexts"
      );
    },
    async inputRecipientEmail() {
      await this.handleInputEmail(
        this.recipientEmailText,
        "recipientInputBox",
        "recipientPromptTexts"
      );
    },
    clickRecipient(item) {
      this.recipientEmailText = "";
      this.$nextTick(async () => {
        const repeat = this.verifyEmailRepeat(this.recipients, item.email);
        if (!repeat) {
          const hanlerName = this.getEmailAddressBookName(item.email);
          this.recipients.push({
            name: hanlerName || item.name,
            email: item.email,
            isEmail: isEmail(item.email),
          });
          try {
            this.$refs.recipientInput[0].focus();
          } catch (error) {}
        } else {
          this.$message({
            type: "error",
            message: this.$t("doNotRepeatInput"),
          });
        }
        this.closeInputPopover();
      });
    },
    async closeInputPopover() {
      this.recipientPromptTexts = [];
      this.ccPromptTexts = [];
      this.bccPromptTexts = [];
      this.recipientPopover = false;
      this.ccPopover = false;
      this.bccPopover = false;
    },
    // 验证发件邮箱密码
    async verifySenderEmailPassword() {
      return new Promise(async (resolve, reject) => {
        try {
          const password = await this.globalVerifyEmailPassword(
            this.selectedFrom
          );
          resolve(password);
        } catch (error) {
          reject();
        }
      });
    },
    verifySelectedItemRepeat(selectedArr, handleArr) {
      selectedArr.map((item) => {
        const repeat = this.verifyEmailRepeat(handleArr, item.email);
        if (!repeat) {
          const hanlerName = this.getEmailAddressBookName(item.email);
          handleArr.push({
            name: hanlerName || item.name,
            email: item.email,
            isEmail: isEmail(item.email),
          });
        } else {
          this.$message({
            type: "error",
            message: this.$t("doNotRepeatInput"),
          });
        }
      });
    },
    verifyEmailRepeat(arr, text) {
      const repeat = arr.some((item) => {
        return item.email == text;
      });
      return repeat;
    },
    // 根据邮箱地址获取通讯录内备注名
    getEmailAddressBookName(email) {
      if (this.contactsList.length > 0) {
        const contact = this.contactsList.find((contact) => {
          return contact.email == email;
        });
        return contact ? contact.name : null;
      }
      return null;
    },
    addRecipientEmail(type = "keydown") {
      if (
        type == "blur" &&
        this.recipientPopover &&
        this.recipientPromptTexts.length > 0
      ) {
        return;
      }
      if (this.recipientPopover && this.recipientPromptTexts.length > 0) {
        if (this.$refs.recipientPopoverList[0]) {
          const item = this.$refs.recipientPopoverList[0].getSelectedItem();
          if (item) {
            this.recipients.push({
              name: item.name,
              email: item.email,
              isEmail: isEmail(item.email),
            });
          }
        }
        this.recipientEmailText = "";
        this.closeInputPopover();
      } else if (this.recipientEmailText != "") {
        let emailText = this.recipientEmailText;
        const repeat = this.verifyEmailRepeat(this.recipients, emailText);
        if (!repeat) {
          const handlerName = this.getEmailAddressBookName(emailText);
          this.recipients.push({
            name: handlerName || emailText.split("@")[0],
            email: emailText,
            isEmail: isEmail(emailText),
          });
          try {
            this.$refs.recipientInput[0].focus();
          } catch (error) {}
        } else {
          this.$message({
            type: "error",
            message: this.$t("doNotRepeatInput"),
          });
        }
        this.recipientEmailText = "";
      }
    },
    handlerCompositionstart() {
      this.compositionStatus = true;
    },
    handlerCompositionend() {
      this.compositionStatus = false;
    },
    handlerRecipientKeydown(event) {
      // 删除键
      if (event.code === "Backspace") {
        if (this.recipientEmailText === "" && this.recipients.length > 0) {
          event.preventDefault();
          this.recipients.pop();
        }
      }
      // 逗号分号
      const codes = ["Comma", "Semicolon"];
      if (codes.includes(event.code)) {
        event.preventDefault();
        this.addRecipientEmail("keydown");
      }
      // 空格
      if (event.code === "Space") {
        event.preventDefault();
        // 如果有popover则关闭
        if (
          !this.compositionStatus &&
          this.recipientPopover &&
          this.recipientPromptTexts.length > 0 &&
          this.recipientEmailText != ""
        ) {
          this.closeInputPopover();
          this.addRecipientEmail("keydown");
        }
      }
    },
    deleteBccInput() {
      this.Bccs = [];
      this.BccEmailText = [];
      this.bccPopover = false;
      this.bccPromptTexts = [];
      this.bccPopoverWidth = 0;
      this.showBccInput = false;
    },
    addCcOrBccEmail(type = "keydown", fromType = "cc") {
      if (
        type == "blur" &&
        fromType == "cc" &&
        this.ccPopover &&
        this.ccPromptTexts.length > 0
      ) {
        return;
      }
      if (
        type == "blur" &&
        fromType == "bcc" &&
        this.bccPopover &&
        this.bccPromptTexts.length > 0
      ) {
        return;
      }
      let emailText = "",
        arr = [],
        promptTexts = [],
        refName = "",
        popoverFlag = null;
      switch (fromType) {
        case "cc":
          emailText = this.CcEmailText;
          arr = this.Ccs;
          popoverFlag = this.ccPopover;
          refName = "ccPopoverList";
          promptTexts = this.ccPromptTexts;
          break;
        case "bcc":
          emailText = this.BccEmailText;
          arr = this.Bccs;
          popoverFlag = this.bccPopover;
          refName = "bccPopoverList";
          promptTexts = this.bccPromptTexts;
          break;
      }
      if (popoverFlag && promptTexts.length > 0) {
        if (this.$refs[refName][0]) {
          const item = this.$refs[refName][0].getSelectedItem();
          if (item) {
            arr.push({
              name: item.name,
              email: item.email,
              isEmail: isEmail(item.email),
            });
          }
        }
        switch (fromType) {
          case "cc":
            this.CcEmailText = "";
            break;
          case "bcc":
            this.BccEmailText = "";
            break;
        }
        this.closeInputPopover();
      } else if (emailText != "") {
        const repeat = this.verifyEmailRepeat(arr, emailText);
        if (!repeat) {
          const handlerName = this.getEmailAddressBookName(emailText);
          arr.push({
            name: handlerName || emailText.split("@")[0],
            email: emailText,
            isEmail: isEmail(emailText),
          });
          try {
            if (fromType == "cc") {
              this.$refs.ccInput[0].focus();
            } else {
              this.$refs.bccInput[0].focus();
            }
          } catch (error) {}
        } else {
          this.$message({
            type: "error",
            message: this.$t("doNotRepeatInput"),
          });
        }
        if (fromType == "cc") {
          this.CcEmailText = "";
        } else {
          this.BccEmailText = "";
        }
      }
    },
    handlerCcOrBccKeydown(event, type = "cc") {
      let emailText = "",
        arr = [],
        promptTexts = [],
        popoverFlag = null;
      switch (type) {
        case "cc":
          emailText = this.CcEmailText;
          arr = this.Ccs;
          popoverFlag = this.ccPopover;
          promptTexts = this.ccPromptTexts;
          break;
        case "bcc":
          emailText = this.BccEmailText;
          arr = this.Bccs;
          popoverFlag = this.bccPopover;
          promptTexts = this.bccPromptTexts;
          break;
      }
      // 删除键
      if (event.code === "Backspace") {
        if (emailText === "" && arr.length > 0) {
          event.preventDefault();
          arr.pop();
        }
      }
      // 逗号分号
      const codes = ["Comma", "Semicolon"];
      if (codes.includes(event.code)) {
        event.preventDefault();
        this.addCcOrBccEmail("keydown", type);
      }
      // 空格
      if (event.code === "Space") {
        event.preventDefault();
        // 如果有popover则关闭
        if (
          !this.compositionStatus &&
          popoverFlag &&
          promptTexts.length > 0 &&
          emailText != ""
        ) {
          this.closeInputPopover();
          this.addCcOrBccEmail("keydown", type);
        }
      }
    },
    deleteAttachment(attachment) {
      this.waitingUploadedFiles = this.waitingUploadedFiles.filter(
        (item) =>
          `${item.name}${item.lastModified}` !=
          `${attachment.name}${attachment.lastModified}`
      );
    },
    // ----------------------------------------------上传相关功能-------------------------------------------------
    richAttachment() {
      this.$refs.uploadAttachment.clearFiles();
      this.$refs.uploadAttachment.$children[0].$refs.input.click();
    },
    beforeFileUpload(attachment) {
      const flag = this.waitingUploadedFiles.every(
        (file) => file.name !== attachment.name
      );
      if (!flag) {
        this.$message({
          message: this.$t("doNotRepeatInput"),
          type: "error",
        });
      }
      return flag;
    },
    async handlerUpload(file) {
      try {
        let totalSize = file.file.size;
        this.waitingUploadedFiles.forEach((f) => {
          totalSize += f.size;
        });

        let attachmentSize = this.emailConfig
          ? this.emailConfig.attachmentSize * 1024 * 1024
          : 20 * 1024 * 1024;

        if (totalSize <= attachmentSize) {
          const newName = file.file.name.replace(/[/\\]/g, "_");
          const newFile = new File([file.file], newName, {
            type: file.file.type,
            lastModified: file.file.lastModified,
          });
          this.waitingUploadedFiles.push(newFile);
        } else {
          const uploadResult = await this.uploadLargeFile(file);
          this.largeAttachmentProgress = uploadResult;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async uploadLargeFile(file) {
      return handlerUploadFileToQiniu(
        file,
        (item) => {
          this.largeAttachmentProgress = null;
          this.fillInLargeAttachment(item);
        },
        "email"
      );
    },
    // 添加原始附件
    async addOriginalAttachment() {
      const { replyItemInfo, replyAllItemInfo, type } = this.openEmailView;
      const handleItem = type === "replyAll" ? replyAllItemInfo : replyItemInfo;
      if (handleItem.attachments.length > 0) {
        await this.download(handleItem);

        let totalSize = 0;
        const maxAttachmentSize = this.emailConfig
          ? this.emailConfig.attachmentSize * 1024 * 1024
          : 20 * 1024 * 1024;
        const validFiles = [];
        const oversizedFiles = [];

        this.waitingUploadedFiles.forEach((file) => {
          if (totalSize + file.size <= maxAttachmentSize) {
            validFiles.push(file);
            totalSize += file.size;
          } else {
            oversizedFiles.push(file);
          }
        });

        this.waitingUploadedFiles = validFiles;

        for (const file of oversizedFiles) {
          await this.uploadLargeFile({ file });
        }
      }
    },
    // ----------------------------------------------上传相关功能END-------------------------------------------------
    // 插入大附件
    fillInLargeAttachment(itemInfo) {
      setTimeout(() => {
        const largeAttachmentElement = document.getElementById(
          "emailLargeAttachment"
        );
        // 如果已经插入过大附件，则不在新创建
        if (largeAttachmentElement && largeAttachmentElement.innerHTML) {
          const attachmentHtml = this.createLargeAttachmentHtml(itemInfo);
          let handleArr = attachmentHtml.split("\n");
          handleArr = handleArr.map((e) =>
            e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
          );
          largeAttachmentElement.insertAdjacentHTML(
            "beforeend",
            handleArr.join("")
          );
        } else {
          const selector = document.getElementsByClassName(
            "el-tiptap-editor__content"
          );

          // 如果有回复或者转发主体，则不对输入区域高度作处理
          const replyContent = document.getElementById("replied_iframe");
          if (!replyContent) {
            const selectorInner = document.getElementsByClassName(
              "ProseMirror"
            );
            // 获取元素当前的高度
            if (
              selectorInner[0].clientHeight + 120 <
              selector[0].clientHeight
            ) {
              selectorInner[0].setAttribute(
                "style",
                `min-height:${selector[0].clientHeight -
                  120 -
                  selectorInner[0].clientHeight}px`
              );
            }
          }
          const node = document.createElement("div");
          node.setAttribute(
            "style",
            "width:100%; min-height:0px; overflow:hidden;line-height: normal;position: relative;"
          );
          node.setAttribute("contenteditable", "false");
          node.id = "emailLargeAttachment";
          const titleHtml = `
            <div data-tag="largeAttachmentOriginal" style="width: 100%;height: 28px;text-align: center;position: relative;display: flex;align-items: center;justify-content: center;margin: 16px 0">
              <div style="position: absolute;top: 50%;left:0;right: 0;border-top: 1px dashed #D9D9D9;border-bottom: 1px dashed #D9D9D9;content:'';transform: translate3d(0, -50%, 0);"></div>
              <div style="position: relative;z-index: 1;background-color: white;color:#858585;font-size:14px">${this.$t(
                "largeAttachment"
              )}</div>
            </div>
          `;
          const attachmentHtml = this.createLargeAttachmentHtml(itemInfo);
          let handleArr = `${titleHtml}${attachmentHtml}`.split("\n");
          handleArr = handleArr.map((e) =>
            e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
          );
          node.innerHTML = handleArr.join("");
          selector[0].appendChild(node);
        }
      }, 0);
    },
    // 创建大附件HTML
    createLargeAttachmentHtml(itemInfo) {
      const htmlStr = `
        <a data-tag="large_attachment_item" download href="${
          itemInfo.path
        }" target="_blank" style="max-width: calc(100% - 32px);position: relative;height: 50px;cursor:pointer;display: inline-flex;padding: 0 16px; border: 1px solid #D9D9D9;background: #F5F5F5; align-items: center; justify-content: flex-start;border-radius: 4px;margin:0 20px 10px 12px">
          <img style="display: block;width: 24px;height: 24px;margin-right: 4px;flex-shrink: 0;object-fit: cover" src=${this.getIconByFileName(
            itemInfo.name
          )} />
          <div style="font-size: 14px; color: #292d32; flex: 1; max-width: 240px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap">${
            itemInfo.name
          }</div>
          <div style="font-size: 14px; color: #858585; max-width: 120px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap">(${this.changeLimit(
            itemInfo.size
          )})</div>
          <div style="position: absolute;width:14px;height:14px;border-radius:50%;background:rgba(0,0,0,0.5);top:-7px;right:-7px;display: flex;align-items: center; justify-content: center;color: white;font-size: 10px;cursor: pointer;" onclick='deleteLargeAttachment(event, this)'>X</div>
        </a>
      `;
      return htmlStr;
    },
    deleteLargeAttachment(event, element) {
      event.preventDefault();
      event.stopPropagation();
      element.parentNode.remove();
      const largeAttachmentElement = document.getElementById(
        "emailLargeAttachment"
      );
      const attachmentHtml = largeAttachmentElement.innerHTML;
      if (attachmentHtml.indexOf("large_attachment_item") == -1) {
        largeAttachmentElement.remove();
      }
    },
    // 返回并存入草稿箱
    async goBack() {
      try {
        const filteredString = this.editorHtml.replace(/<\/?p>/gi, "");
        if (filteredString != "" || this.emailSubject != "") {
          await this.sendEmail(true);
        } else {
          throw new Error();
        }
      } catch (err) {
        this.$store.commit("setEmailHistoryComponentGoBack");
      }
    },
    sendEmail: _.debounce(async function(isDrifts) {
      if (this.editorLoading) {
        return;
      }
      if (this.downLoading) {
        return;
      }
      if (this.disable && !isDrifts) {
        return;
      }
      if (this.recipients.length == 0 && !isDrifts) {
        return;
      }
      if (this.largeAttachmentProgress) {
        return;
      }
      // 更新一下当前邮箱账号的子菜单
      try {
        let newAccount = _.cloneDeep(this.selectedFrom);
        if (this.emailSubMenus.length != 0) {
          if (newAccount && !newAccount.subMenu) {
            newAccount.subMenu = _.cloneDeep(this.emailSubMenus);
            this.$store.commit("setCurrentEmailAccount", newAccount);
          }
        } else {
          throw new Error("默认子菜单装载失败");
        }
      } catch (error) {
        console.log(error);
        return;
      }
      this.editorLoading = true;
      // 如果来自草稿邮件，则需要先将草稿邮件删除，再保存新的草稿邮件
      if (this.openEmailView && this.openEmailView.type == "drafts") {
        // 先删除草稿箱的邮件
        await this.deleteDrafts(this.openEmailView.itemInfo);
      }
      let params = {
        account: this.selectedFrom,
      };
      if (this.recipients.length != 0) {
        params.toStr = "";
        this.recipients.map((item) => {
          params.toStr += `${item.email},`;
        });
      }
      if (this.Ccs.length != 0) {
        params.ccStr = "";
        this.Ccs.map((item) => {
          params.ccStr += `${item.email},`;
        });
      }
      if (this.Bccs.length != 0) {
        params.bccStr = "";
        this.Bccs.map((item) => {
          params.bccStr += `${item.email},`;
        });
      }
      params.subject = this.emailSubject;
      let editorHtml = this.editorHtml;
      // 处理table样式
      editorHtml = this.handleTableStyle(editorHtml);
      // 获取签名模块
      try {
        const signatureBlock = document.getElementById("signatureBlock");
        if (signatureBlock) {
          editorHtml = editorHtml + signatureBlock.outerHTML;
        }
      } catch (err) {}
      // 获取回复或转发的内容
      try {
        if (this.openEmailView) {
          const replyContent = document.getElementById("replied_iframe");
          if (replyContent) {
            const iframeDocument =
              replyContent.contentDocument ||
              replyContent.contentWindow.document;
            editorHtml = editorHtml + iframeDocument.body.innerHTML;
          }
        }
      } catch (err) {}
      // 获取超大附件
      try {
        const largeAttachmentElement = document.getElementById(
          "emailLargeAttachment"
        );
        if (largeAttachmentElement && largeAttachmentElement.innerHTML) {
          let attachmentHtml = largeAttachmentElement.innerHTML;
          attachmentHtml = attachmentHtml.replace(
            /background:rgba[(]0,0,0,0.5[)]/g,
            "visibility:hidden"
          );
          editorHtml = editorHtml + attachmentHtml;
        }
      } catch (err) {}
      params.content = editorHtml;
      params.isDrifts = isDrifts;
      params.files = this.waitingUploadedFiles;
      try {
        const result = await this.globalSendEmail(
          params,
          this.onUploadProgress
        );
        if (result.code == 200) {
          // 根据情况存入缓存到草稿箱或已发送邮箱
          if (!isDrifts) {
            await this.storeSendingEmails(
              result.formData,
              result.messageId,
              "Sent"
            );
          }
          this.$refs.uploadAttachment.clearFiles();
          this.waitingUploadedFiles = [];
          this.emailSubject = "";
          this.editorHtml = "";
          this.editorModel = "";
          this.recipients = [];
          this.Ccs = [];
          const replyContent = document.getElementById("replied_iframe");
          if (replyContent) {
            replyContent.remove();
          }
          const emailLargeAttachment = document.getElementById(
            "emailLargeAttachment"
          );
          if (emailLargeAttachment) {
            emailLargeAttachment.remove();
          }
          this.$message({
            type: "success",
            message: isDrifts
              ? this.$t("storedInTheDraftBox")
              : this.$t("successfullySent"),
          });
          this.uploadPercent = 0;
          this.editorLoading = false;
          this.$store.commit("setCurrent_list_module_to_enter", {
            emailProviderId: this.selectedFrom.originProviderId,
            pageType: isDrifts ? "drafts" : "sent",
          });
          this.$store.commit("setEmailCurrentComponentHistory", "main");
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          throw new Error();
        }
      } catch (error) {
        this.uploadPercent = 0;
        this.editorLoading = false;
      }
    }, 500),
    // 处理内容中表格样式
    handleTableStyle(html) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(html, "text/html");
      let tables = doc.querySelectorAll("table");

      tables.forEach((table) => {
        let trs = table.querySelectorAll("tr");
        let ths = trs.length > 0 ? trs[0].querySelectorAll("th") : [];
        let tableWidth = 0;

        // 计算表格宽度
        ths.forEach((th) => {
          tableWidth += parseInt(th.getAttribute("data-colWidth")) || 150;
        });

        // 设置表格样式
        table.setAttribute(
          "style",
          `width:${tableWidth}px;border-collapse: collapse;table-layout: fixed;margin: 10px 0 20px;overflow: hidden;`
        );

        let commonStyle =
          "box-sizing: border-box;padding: 3px 5px;position: relative;vertical-align: top;border: 2px solid #ebeef5;";
        let cells = [...table.querySelectorAll("th, td")];
        cells.forEach((cell) => {
          let cellWidth = parseInt(cell.getAttribute("data-colWidth")) || 150;
          let isHeader = cell.tagName.toLowerCase() === "th";
          let cellStyle = `${
            isHeader ? "font-weight: 500;text-align: left;" : ""
          }width:${cellWidth}px;${commonStyle}`;
          cell.setAttribute("style", cellStyle);
        });
      });

      return doc.body.innerHTML;
    },
    // 存入发送中邮件
    async storeSendingEmails(formData, messageId, folder_name) {
      const realTime = await this.getRealTime();
      let storeData = {
        from: this.selectedFrom.mailAddress,
        to: formData.get("to"),
        cc: formData.get("cc"),
        bcc: formData.get("bcc"),
        subject: formData.get("subject"),
        content: formData.get("content"),
        sourceEmail: this.selectedFrom.mailAddress,
        messageId: messageId,
        sendTimestamp: realTime,
        attachments: [],
      };
      if (this.waitingUploadedFiles.length != 0) {
        this.waitingUploadedFiles.map((item) => {
          storeData.attachments.push(item);
        });
      }
      await storeSendingEmails(storeData, folder_name);
    },
    async deleteDrafts(item) {
      // 如果有uid说明是正式数据，需要服务器删除
      if (item.uid) {
        let params = {
          folder: item.folder_name,
          flag: "DELETED",
          set: true,
          uids: item.uid,
        };
        const result = await markEmails(params);
        if (result.code == 200 || result.code == 501) {
          try {
            await deleteEmailsByUniqueKey([item.uniqueIdentifier]);
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    onUploadProgress(percent) {
      if (this.editorLoading && percent == 100) {
        this.uploadPercent = 99;
      } else {
        this.uploadPercent = percent;
      }
    },
    cancelSave() {
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("cancelSendingTips"),
        showDialog: true,
        color: "#F39945",
        from: "email",
      });
    },
    deleteRecipient(recipient) {
      this.recipients = this.recipients.filter((item) => {
        return item.email != recipient.email;
      });
    },
    addRecipient() {
      this.$store.commit("setAddressBookDialogType", 0);
      this.$store.commit("setEmailAddressBookDialog", true);
    },
    deleteCcOrBcc(index, type = "cc") {
      switch (type) {
        case "cc":
          this.Ccs.splice(index, 1);
          break;
        case "bcc":
          this.Bccs.splice(index, 1);
          break;
      }
    },
    addCcOrBcc(type = "cc") {
      this.$store.commit("setAddressBookDialogType", type == "cc" ? 1 : 2);
      this.$store.commit("setEmailAddressBookDialog", true);
    },
    // 开始输入bcc
    startInputBcc() {
      this.showBccInput = true;
    },
    resizeEditorBox(ob) {
      try {
        this.attachmentsSliceNumber = Math.floor((ob.width + 15) / 375);
      } catch (e) {}
    },
    // 打开下拉选择框
    openSelectModel() {
      this.$refs.emailFromSelector[0].toggleMenu();
    },
    onEditorBlur(editor) {
      this.handleInputOverflow();
    },
    onEditorFocus(editor) {},
    // 处理草稿邮件内容
    processDraftsContent(content) {
      let handlerContent = content.replace(
        /line-height:\s*(\d+(\.\d+)?%|\d+px)/g,
        ""
      );
      // 回复转发内容部分
      const melinkedOriginalTag = `<div data-tag="melinkedOriginal"`;
      // 超大邮件部分
      const largeAttachmentTag = `<div data-tag="largeAttachmentOriginal"`;
      // 签名部分
      const melinkedSignatureTag = `<div data-tag="melinkedSignature"`;
      // 获取转发回复内容起始位置
      const tagIndex = handlerContent.indexOf(melinkedOriginalTag);
      if (tagIndex != -1) {
        // 获取回复内容部分以及超大附件部分
        let replayContent = handlerContent.slice(tagIndex);
        // 判断是否有超大附件，如果有的话，则切割出超大附件部分
        let large_attachment_content;
        const large_attachment_index = replayContent.indexOf(
          largeAttachmentTag
        );
        // 获取超大附件内容部分
        if (large_attachment_index !== -1) {
          large_attachment_content = replayContent.slice(
            large_attachment_index
          );
          replayContent = replayContent.slice(0, large_attachment_index);
        }
        // 获取邮件正文内容部分
        handlerContent = handlerContent.slice(0, tagIndex);
        // 获取签名在正文内容部分的位置
        const signatureTag_index = handlerContent.indexOf(melinkedSignatureTag);
        let signatureTag_content;
        // 获取超大附件内容部分
        if (signatureTag_index !== -1) {
          signatureTag_content = handlerContent.slice(signatureTag_index);
          handlerContent = handlerContent.slice(0, signatureTag_index);
        }
        if (signatureTag_content) {
          this.fillInSignature();
        }
        // 将邮件正文内容填入编辑器
        this.editorModel = this.handleContentWrap(handlerContent);
        this.editorHtml = this.editorModel;
        // 将回复或转发内容拼接到正文后
        this.fillInOriginalInfo(replayContent);
        // 依次放入超大附件内容和回复内容
        if (large_attachment_content) {
          large_attachment_content = large_attachment_content.replace(
            "visibility:hidden",
            "background:rgba(0,0,0,0.5)"
          );
          this.fillInLargeAttachmentInfo(large_attachment_content);
        }
      } else {
        // 判断是否有超大附件，如果有的话，则切割出超大附件部分
        let large_attachment_content;
        const large_attachment_index = handlerContent.indexOf(
          largeAttachmentTag
        );
        if (large_attachment_index !== -1) {
          large_attachment_content = handlerContent.slice(
            large_attachment_index
          );
          handlerContent = handlerContent.slice(0, large_attachment_index);
        }
        // 获取签名在正文内容部分的位置
        const signatureTag_index = handlerContent.indexOf(melinkedSignatureTag);
        let signatureTag_content;
        if (signatureTag_index !== -1) {
          signatureTag_content = handlerContent.slice(signatureTag_index);
          handlerContent = handlerContent.slice(0, signatureTag_index);
        }
        if (signatureTag_content) {
          this.fillInSignature();
        }
        this.editorModel = this.handleContentWrap(handlerContent);
        this.editorHtml = this.editorModel;
        if (large_attachment_content) {
          large_attachment_content = large_attachment_content.replace(
            "visibility:hidden",
            "background:rgba(0,0,0,0.5)"
          );
          this.fillInLargeAttachmentInfo(large_attachment_content);
        }
      }
    },
    // 处理默认文本换行问题
    handleContentWrap(content) {
      const handlerContent = content.replace(/\n/g, "<br/>");
      const handlerArr = handlerContent.split("<br/>");
      let resultContent = "";
      if (handlerArr.length > 0) {
        handlerArr.forEach((item) => {
          let text = item;
          if (text.indexOf("<p>") == -1 || text.indexOf("</p>") == -1) {
            text = `<p>${text}</p>`;
          }
          resultContent += text;
        });
      } else {
        resultContent = content;
      }
      return resultContent;
    },
    changeEditorBarStyle() {
      setTimeout(() => {
        const selector = document.getElementsByClassName(
          "el-tiptap-editor__menu-bar"
        )[0];
        if (selector) {
          selector.setAttribute(
            "style",
            "display:block;white-space:nowrap;overflow-x: auto;overflow-y: hidden;"
          );
          const tooltips = selector.querySelectorAll(".el-tooltip");
          Array.from(tooltips).map((item) => {
            item.setAttribute(
              "style",
              "display:inline-flex;width: 30px;height: 30px"
            );
          });
        }
      }, 0);
    },
    async download(itemInfo) {
      const promises = itemInfo.attachments.map((attachment) => {
        return new Promise(async (resolve, reject) => {
          try {
            if (attachment instanceof File) {
              resolve(attachment);
            } else if (
              typeof attachment.sectionId == "number" &&
              this.waitingUploadedFiles.every(
                (file) => file.name !== attachment.name
              )
            ) {
              try {
                const params = {
                  fileName: attachment.name,
                  sectionId: attachment.sectionId,
                  messageId: itemInfo.messageId,
                  from: itemInfo.from,
                };
                const result = await attachmentDownload(
                  params,
                  async (progressEvent) => {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                  }
                );
                const reader = new FileReader();
                reader.readAsArrayBuffer(result);
                reader.onload = function(event) {
                  const arrayBuffer = event.target.result;
                  resolve(new File([arrayBuffer], attachment.name));
                };
              } catch (error) {
                throw new Error("File is not a valid attachment");
              }
            } else {
              throw new Error("File is not a valid attachment");
            }
          } catch (error) {
            resolve(null);
          }
        });
      });
      this.downLoading = true;
      const results = await Promise.all(promises);
      this.downLoading = false;
      results
        .filter((result) => result)
        .map((result) => {
          this.waitingUploadedFiles.push(result);
        });
    },
    fillInLargeAttachmentInfo(content) {
      setTimeout(() => {
        const selector = document.getElementsByClassName(
          "el-tiptap-editor__content"
        );
        const node = document.createElement("div");
        node.setAttribute(
          "style",
          "width:100%; min-height:0px; overflow:hidden;line-height: normal;position: relative;"
        );
        node.setAttribute("contenteditable", "false");
        node.id = "emailLargeAttachment";
        node.innerHTML = content;
        selector[0].appendChild(node);
        try {
          selector[0].click();
        } catch (err) {}
      }, 0);
    },
    fillInOriginalInfo(content) {
      // 将任务放到本帧最后执行
      setTimeout(() => {
        const selector = document.getElementsByClassName(
          "el-tiptap-editor__content"
        );
        const node = document.createElement("div");
        node.setAttribute(
          "style",
          "width:100%; min-height:0px; overflow:hidden;line-height: normal;position: relative;"
        );
        if (typeof content == "string") {
          node.innerHTML = content;
        } else {
          node.innerHTML = this.transformValue(content);
        }
        const htmlString = node.outerHTML;
        const iframe = document.createElement("iframe");
        iframe.id = "replied_iframe";
        iframe.style.width = "100%";
        iframe.setAttribute("frameborder", "0");
        selector[0].appendChild(iframe);
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.head.innerHTML += `<meta charset="UTF-8">`;
        iframeDocument.head.innerHTML += `<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">`;
        iframeDocument.head.innerHTML += `<meta name="viewport" content="width=device-width, initial-scale=1.0">`;
        iframeDocument.head.innerHTML += `<meta http-equiv="X-UA-Compatible" content="IE=edge">`;
        iframeDocument.head.innerHTML += `<meta name="referrer" content="no-referrer">`;
        iframeDocument.body.innerHTML = htmlString;
        const images = iframeDocument.querySelectorAll("img");
        let imagesLoaded = 0;
        images.forEach((img) => {
          if (img.complete) {
            imagesLoaded++;
            if (imagesLoaded === images.length) {
              this.adjustIframeHeight(iframe);
            }
          } else {
            img.addEventListener("load", () => {
              imagesLoaded++;
              if (imagesLoaded === images.length) {
                this.adjustIframeHeight(iframe);
              }
            });
          }
        });
        // 获取 iframe 中的所有超链接，并兼容访问逻辑
        var links = iframeDocument.getElementsByTagName("a");
        for (var i = 0; i < links.length; i++) {
          links[i].setAttribute("target", "_blank");
        }
        iframeDocument.body.setAttribute(
          "style",
          "word-wrap: break-word;overflow-wrap: break-word;word-break: keep-all;"
        );
        this.adjustIframeHeight(iframe);
        try {
          selector[0].click();
        } catch (err) {}
      }, 0);
    },
    // 将iframe的高度适应内部
    adjustIframeHeight(iframe) {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      iframe.style.height = iframeDocument.body.scrollHeight + 40 + "px";
    },
    transformValue(itemInfo) {
      const replayInfo = _.cloneDeep(itemInfo);
      const date = moment(replayInfo.sendTimestamp).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const htmlStr = `
        <div data-tag="melinkedOriginal" style="width: 100%;height: 28px;text-align: center;position: relative;display: flex;align-items: center;justify-content: center;margin: 16px 0">
          <div style="position: absolute;top: 50%;left:0;right: 0;border-top: 1px dashed #D9D9D9;border-bottom: 1px dashed #D9D9D9;content:'';transform: translate3d(0, -50%, 0);"></div>
          <div style="position: relative;z-index: 1;background-color: white;color:#858585;font-size:14px">${this.$t(
            "originalMail"
          )}</div>
        </div>
      `;
      const subjectHtml = `<div style="width:100%;min-height:25px;color:#292D32;font-size:18px;font-weight:600;line-height:25px;" title="${replayInfo.subject}">${replayInfo.subject}</div>`;
      const fromHtml = `
      <div style="width:100%;min-height:22px;;line-height:22px;color:#858585;font-size: 14px;margin-top:6px;word-break:break-all">
        ${this.$t("emailFrom")}: &lt;${replayInfo.from}&gt;;
      </div>`;
      const toHtml = `
      <div style="width:100%;min-height:22px;;line-height:22px;color:#858585;font-size: 14px;margin-top:6px;word-break:break-all">
        ${this.$t("emailRecipient")}: &lt;${replayInfo.to}&gt;;
      </div>`;
      const ccHtml = replayInfo.cc
        ? `
      <div style="width:100%;min-height:22px;;line-height:22px;color:#858585;font-size: 14px;margin-top:6px;">
        ${this.$t("CcTo")}: &lt;${replayInfo.cc}&gt;;
      </div>`
        : "";
      const dateHtml = `
      <div style="width:100%;min-height:22px;;line-height:22px;color:#858585;font-size: 14px;margin-top:6px;">
        ${this.$t("time")}: ${date}
      </div>`;
      const infoBlock = `<div style="width: 100%;box-sizing: border-box;min-height: 0px;background: #F5F5F5;border-radius: 4px;padding: 12px;margin-bottom:16px">${subjectHtml}${fromHtml}${toHtml}${ccHtml}${dateHtml}</div>`;
      let handleArr = `${htmlStr}${infoBlock}`.split("\n");
      handleArr = handleArr.map((e) =>
        e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
      );
      const content = replayInfo.content ? replayInfo.content : "";
      return handleArr.join("") + content;
    },
    onEditorChange(html) {
      const LINE_HEIGHT_PATTERN = /line-height:\s*\d+%?/gi;
      let realHtml = html.replace(LINE_HEIGHT_PATTERN, (match) => {
        if (match) {
          return "";
        }
      });
      this.editorHtml = realHtml;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.sendEmailBox
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 0 16px 0;
  transition: padding 0.3s;
  position: relative;
  .uploadProgress
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.3);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
  .operationArea
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-shrink: 0;
    position: relative;
    border-bottom: 1px solid #EDEDED;
    padding: 0 16px;
    .centerBox
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%,-50%,0);
      display: flex;
      align-items: center;
      justify-content: center;
      .menu-content
        flex: 1;
        height: 100%;
        line-height: 54px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: absolute;
        top: 0;
        left: 0;
        .menu-title
          font-size: 16px;
        .menu-note
          font-size: 14px;
    .leftBox
      flex: 1;
      min-width: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .backStyle
        font-size: 20px;
        cursor: pointer;
        margin-right: 32px;
      .disableStyle
        background: #D9D9D9 !important;
        cursor: not-allowed !important;
      .sendEmail
        width: 68px;
        height: 32px;
        background: #F39945;
        border-radius: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 32px;
        .sendStyle
          color: #FFFFFF;
          font-size: 20px;
    .windowChangeBox
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 54px;
      flex-shrink: 0;
      .imelink-miniscreen,.imelink-fullscreen,.imelink-fullscreen
        top: 12px;
  .containerBox
    padding: 0 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    .inputArea
      width: 100%;
      flex-shrink: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .sizeButtonBox
        width: 28px;
        height: 28px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        .iconStyle
          font-size 18px;
          cursor: pointer;
      .innerInputBox
        flex: 1;
        min-width: 0;
        .lineBox
          min-height: 0;
          max-height: 108px;
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #D9D9D9;
          margin-bottom: 6px;
          flex-shrink: 0;
          padding-left: 16px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          overflow: hidden;
          .title
            display: inline-flex;
            height: 28px;
            font-size: 12px;
            color: #858585;
            flex-shrink: 0;
            align-items: center;
            justify-content: flex-start;
            padding: 0 8px 0 0;
          .lineContent
            flex: 1;
            min-width: 0;
            height: 100%;
            min-height: 28px;
            position: relative;
            .lineBoxInputStyle
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              padding-right: 10px;
              .lineBoxInner
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .draggablegGhostClass
                  display: none !important;
                .itemList
                  flex: 1;
                  min-width: 0;
                  max-height: 100px;
                  box-sizing: border-box;
                  padding:0 0 2px 5px;
                  margin-right: 5px;
                  cursor: pointer;
                  overflow-x: hidden;
                  overflow-y: auto;
                  &::-webkit-scrollbar
                    width: 4px;
                  .item
                    height: 24px;
                    max-width: 360px;
                    min-width: 0;
                    box-sizing: border-box;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px;
                    margin: 2px 8px 0 0;
                    transition: all .3s;
                    background: #F5F5F5;
                    border-radius: 12px;
                    vertical-align: middle;
                    cursor: move;
                    .delete
                      overflow: hidden;
                      flex-shrink: 0;
                      min-height: 0;
                      cursor: pointer;
                      margin-left: 4px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      .iconStyle
                        font-size: 12px;
                        color: #858585;
                    .commonStyle
                      display: inline-block;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    .nickname
                      font-size: 14px;
                      color: #2e3033;
                      max-width: 100px;
                      white-space: nowrap;
                      margin-right: 10px;
                    .email
                      font-size: 12px;
                      color: #858585;
                      max-width: 200px;
                      white-space: nowrap;
                  .itemInput
                    max-width: 360px;
                    min-width: 200px;
                    height: 24px;
                    border: none;
                    outline: none;
                    font-size: 12px;
                    margin: 2px 8px 0 0;
                    vertical-align: middle;
                .bccButton
                  width: 45px;
                  flex-shrink: 0;
                  height: 20px;
                  cursor: pointer;
                  font-size: 14px;
                  text-decoration: underline;
                  cursor: pointer;
                  margin-right: 20px;
                .addIcon
                  width: 20px;
                  flex-shrink: 0;
                  height: 20px;
                  cursor: pointer;
                .closeIcon
                  width: 20px;
                  flex-shrink: 0;
                  height: 20px;
                  cursor: pointer;
                  margin-left: 20px;
    .richTextBox
      width: 100%;
      flex: 1;
      min-height: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      border: 1px solid #D9D9D9;
      border-radius: 8px;
      .richTextCore
        flex: 1;
        width: 100%;
        min-height: 0;
        overflow: hidden;
      .emailAttachment
        width: 100%;
        border-top: 1px solid #D9D9D9;
        border-bottom: 1px solid #D1D1D1;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 30px 10px 30px;
        .attachmentCore
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(360px, 360px));
          grid-gap: 5px 15px;
          max-height: 150px;
          overflow-x: hidden;
          overflow-y: auto;
          scrollbar-light();
        .displayFiles
          width: 100%;
          height: 40px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .displayImg
            width: 12px;
            flex-shrink: 0;
            height: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: flex-start;
            img
              width: 12px;
              height: 12px;
              transition: transform 0.2s ease-in-out;
          .displayText
            flex: 1;
            min-width: 0;
            cursor: pointer;
            margin-left: 10px;
            color: #858585;
            font-size: 14px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
</style>
<style lang="stylus">
.signatureBlock
  ol
    list-style-type: decimal;
    li
      list-style-type: inherit;
  ul
    padding-left: 20px;
    list-style-type: inherit !important;
    li
      list-style-type: inherit;
.recipientPopover,.ccAndbccPopover
  padding: 0 !important;
  .popoverContent
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar
      width 6px;
    &::-webkit-scrollbar-thumb
      border-radius: 3px;
      box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
      background: rgba(217, 217, 217, 1);
    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 5px rgba(0,0,0,0);
      border-radius: 0;
      background: rgba(240,240,240,0);
    .popoverItem
      cursor: pointer;
      height: 52px;
      width: 100%;
      padding: 0 15px;
      &:hover
        background: rgba(245,247,250,1);
      .itemInner
        height: 52px;
        width: 100%;
        border-bottom: 1px solid #F0F0F0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
.sendEmailBox
  .uploadProgress
    .progressInner
      .el-progress__text
        font-size: 16px !important;
  .containerBox
    .richTextCore
    .el-editorInner
      padding: 0 !important;
      .el-tiptap-editor__menu-bar
        &::-webkit-scrollbar
          height: 6px;
        &::-webkit-scrollbar-thumb
          border-radius: 3px;
          box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
          background: rgba(100, 100, 100, 0.1);
        &::-webkit-scrollbar-track
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
          border-radius: 0;
          background: rgba(0, 0, 0, 0);
      .el-tiptap-editor__content
        overflow-y: scroll;
        overflow-x: hidden;
        font-family: 'iconfont_Me';
        padding: 5px 20px;
        user-select: auto;
        &::-webkit-scrollbar
          width: 4px;
        &::-webkit-scrollbar-thumb
          border-radius: 2px;
          box-shadow: inset 0 0 5px rgba(100, 100, 100, 0);
          background: #ADADAD;
        &::-webkit-scrollbar-track
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
          border-radius: 0;
          background: rgba(0, 0, 0, 0);
        .ProseMirror
          padding: 10px 0;
          min-height: 300px;
    .lineBox
      .lineContent
        .el-input
          height: 100%;
          width: 100%;
          .el-input__inner
            height: 100%;
            border: none;
            color: #292D32;
            font-size: 14px;
        .el-select
          width: 100%;
          height: 100%;
          .el-input
            .el-input__inner
              opacity: 0;
              padding-right: 35px;
            .el-input__suffix
              right: 8px;
              height: 28px;
              .el-input__suffix-inner
                display: inline-block;
                height: 100%;
                overflow: hidden;
                .el-select__caret
                  color: #292D32;
                  font-size: 20px;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                  justify-content: center;
</style>
