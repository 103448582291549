var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "emailBox",
      style: {
        "padding-left": _vm.IMWindowSize == "full" ? "56px" : ""
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.emailModuleLoading,
              expression: "emailModuleLoading"
            },
            {
              name: "resize-ob",
              rawName: "v-resize-ob",
              value: _vm.handlerSize,
              expression: "handlerSize"
            }
          ],
          staticClass: "emailContent",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255,255,255,0.5)"
          }
        },
        [
          _vm.showMainView
            ? [
                _c(
                  "div",
                  {
                    staticClass: "treeMenu",
                    style: {
                      width: _vm.showTreeMenu ? `${_vm.treeWidth}px` : "0px"
                    }
                  },
                  [
                    _vm.emailAccounts.length != 0
                      ? _c("TreeMenu", {
                          ref: "TreeMenu",
                          attrs: { subMenu: _vm.subMenu }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.mailsListViewLoading,
                        expression: "mailsListViewLoading"
                      }
                    ],
                    staticClass: "contentView",
                    attrs: {
                      "element-loading-spinner": "el-icon-loading",
                      "element-loading-background": "rgba(255,255,255,0.5)"
                    }
                  },
                  [
                    _vm.showContentHeader
                      ? [
                          _c("div", { staticClass: "emailHeader" }, [
                            _c("div", { staticClass: "titleBox" }, [
                              _c("span", { staticClass: "titleFonts" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.currentTitle) +
                                    "\n                "
                                ),
                                _vm.currentMessageCount != 0
                                  ? _c("span", { staticClass: "titleCount" }, [
                                      _vm._v(_vm._s(_vm.currentMessageCount))
                                    ])
                                  : _vm._e()
                              ]),
                              _vm.currentEmailAccount
                                ? _c("span", { staticClass: "accountInfo" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.currentEmailAccount.name) +
                                        "(" +
                                        _vm._s(
                                          _vm.currentEmailAccount.mailAddress
                                        ) +
                                        ")\n              "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "headerOperation" }, [
                              _c(
                                "div",
                                { staticClass: "operationBox" },
                                [
                                  _vm.needMigrating
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "migrating_button",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.startMigrating.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "button_box" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("mailboxMigrate")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _c("div", { staticClass: "triangle" })
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t("contacts"),
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "waves",
                                              rawName: "v-waves"
                                            }
                                          ],
                                          staticClass: "button",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.showContacts.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: { height: "17px" },
                                            attrs: {
                                              src: require("../../../../../assets/images/email/contacts.png"),
                                              fit: "cover"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t("search"),
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "waves",
                                              rawName: "v-waves"
                                            }
                                          ],
                                          staticClass: "button",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.geSearch.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: { height: "17px" },
                                            attrs: {
                                              src: require("../../../../../assets/images/email/search.png"),
                                              fit: "cover"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t("setting"),
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "waves",
                                              rawName: "v-waves"
                                            }
                                          ],
                                          staticClass: "button",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.goSetting.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: { height: "17px" },
                                            attrs: {
                                              src: require("../../../../../assets/images/email/setting.png"),
                                              fit: "cover"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("div", { staticClass: "windowChangeBox" }, [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: this.IMWindowSize != "mini",
                                        expression:
                                          "this.IMWindowSize != 'mini'"
                                      }
                                    ],
                                    staticClass: "imelink-miniscreen",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeScreen("mini")
                                      }
                                    }
                                  },
                                  [
                                    _c("SizeButton", {
                                      attrs: { buttonType: "minimize" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: this.IMWindowSize == "medium",
                                        expression:
                                          "this.IMWindowSize == 'medium'"
                                      }
                                    ],
                                    staticClass: "imelink-fullscreen",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeScreen("full")
                                      }
                                    }
                                  },
                                  [_c("SizeButton")],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: this.IMWindowSize == "full",
                                        expression:
                                          "this.IMWindowSize == 'full'"
                                      }
                                    ],
                                    staticClass: "imelink-fullscreen",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeScreen("medium")
                                      }
                                    }
                                  },
                                  [
                                    _c("SizeButton", {
                                      attrs: { buttonType: "full" }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ])
                        ]
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "view" },
                      [
                        _vm.currentComponent == "main"
                          ? _c(
                              "div",
                              { staticClass: "emptyBlock" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "120px",
                                    height: "120px"
                                  },
                                  attrs: {
                                    src: require("../../../../../assets/images/email/treeMenu/empty.png"),
                                    fit: "cover"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.currentComponent == "inbox"
                          ? _c("Inbox", {
                              attrs: { migrating_email: _vm.migrating_email },
                              on: { listIsRefreshed: _vm.listIsRefreshed }
                            })
                          : _vm._e(),
                        _vm.currentComponent == "mark"
                          ? _c("MarkEmail", {
                              on: { listIsRefreshed: _vm.listIsRefreshed }
                            })
                          : _vm._e(),
                        _vm.currentComponent == "sent"
                          ? _c("SentMails", {
                              attrs: { migrating_email: _vm.migrating_email },
                              on: { listIsRefreshed: _vm.listIsRefreshed }
                            })
                          : _vm._e(),
                        _vm.currentComponent == "drafts"
                          ? _c("Drafts", {
                              attrs: { migrating_email: _vm.migrating_email },
                              on: { listIsRefreshed: _vm.listIsRefreshed }
                            })
                          : _vm._e(),
                        _vm.currentComponent == "trash"
                          ? _c("Trash", {
                              on: { listIsRefreshed: _vm.listIsRefreshed }
                            })
                          : _vm._e(),
                        _vm.currentComponent == "spam"
                          ? _c("Spam", {
                              on: { listIsRefreshed: _vm.listIsRefreshed }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.contactsView
                        ? _c(
                            "div",
                            { staticClass: "contactsView" },
                            [
                              _c("ContactsView", {
                                attrs: { showHeader: false, showGoBack: true },
                                on: { goBack: _vm.hiddenContacts }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ],
                  2
                )
              ]
            : _vm._e(),
          _vm.currentComponent == "send"
            ? [_c("SendMail", { ref: "sendMail" })]
            : _vm._e(),
          _vm.currentMaskComponent == "read"
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.readingViewLoading,
                        expression: "readingViewLoading"
                      }
                    ],
                    staticClass: "readComponent",
                    attrs: {
                      "element-loading-spinner": "el-icon-loading",
                      "element-loading-background": "rgba(255,255,255,0.5)"
                    }
                  },
                  [_c("ReadEmailBox")],
                  1
                )
              ]
            : _vm._e(),
          _vm.currentComponent == "bind" ? [_c("BindAccount")] : _vm._e(),
          _vm.currentComponent == "setting" ? [_c("EmailSetting")] : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }