var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: { effect: "dark", content: _vm.$t("attchFile"), placement: "top" }
    },
    [
      _vm.showType
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-start",
                width: "125",
                trigger: "hover",
                "popper-class": "attchFilePopover"
              }
            },
            [
              _c("div", { staticClass: "buttonBox" }, [
                _c(
                  "div",
                  {
                    staticClass: "buttonText",
                    on: { click: _vm.addAttachment }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("attchFile")) + "\n      "
                    )
                  ]
                ),
                _c("div", {
                  style: {
                    width: "85px",
                    height: "1px",
                    "background-color": "#D9D9D9"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "buttonText",
                    on: { click: _vm.addOriginalAttachment }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("addOriginalAttachment")) +
                        "\n      "
                    )
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "AttachmentButton",
                  attrs: { slot: "reference" },
                  slot: "reference"
                },
                [
                  _c("img", {
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: {
                      src:
                        "https://imfile.melinked.com/2023/10/2ffd927f-4a55-4b4d-bf2c-7c8276f87d25.png?imageView2/2/w/150/h/100/format/png|imageslim"
                    }
                  })
                ]
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "AttachmentButton",
              on: { click: _vm.addAttachment }
            },
            [
              _c("img", {
                staticStyle: { width: "20px", height: "20px" },
                attrs: {
                  src:
                    "https://imfile.melinked.com/2023/10/2ffd927f-4a55-4b4d-bf2c-7c8276f87d25.png?imageView2/2/w/150/h/100/format/png|imageslim"
                }
              })
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }