<template>
  <div
    class="popoverContent"
    :style="{ width: `${popoverWidth}px` }"
    ref="popoverContent"
  >
    <div
      class="popoverItem"
      :class="item.selected ? 'selectedStyle' : ''"
      v-for="(item, index) in handlerList"
      :key="index"
    >
      <div
        class="itemInner"
        v-html="item.HTML"
        @click.stop="clickItem(item)"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    popoverWidth: {
      type: Number,
      default: 0,
    },
    promptTexts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      popoverIndex: 0,
    };
  },
  computed: {
    handlerList() {
      let promptTexts = _.cloneDeep(this.promptTexts);
      promptTexts.forEach((item) => {
        item.selected = false;
      });
      promptTexts[this.popoverIndex].selected = true;
      return promptTexts;
    },
  },
  mounted() {},
  methods: {
    clickItem(item) {
      this.$emit("clickItem", item);
    },
    getSelectedItem() {
      return this.handlerList[this.popoverIndex];
    },
    clickUp() {
      if (this.popoverIndex > 0) {
        this.popoverIndex--;
      } else {
        this.popoverIndex = 0;
      }
    },
    clickDown() {
      if (this.popoverIndex < this.handlerList.length - 1) {
        this.popoverIndex++;
      } else {
        this.popoverIndex = this.handlerList.length - 1;
      }
    },
  },
};
</script>
<style lang="stylus">
.popoverContent
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar
    width 6px;
  &::-webkit-scrollbar-thumb
    border-radius: 3px;
    box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
    background: rgba(217, 217, 217, 1);
  &::-webkit-scrollbar-track
    box-shadow: inset 0 0 5px rgba(0,0,0,0);
    border-radius: 0;
    background: rgba(240,240,240,0);
  .selectedStyle
    background: rgba(245,245,245,1) !important;
  .popoverItem
    cursor: pointer;
    height: 52px;
    width: 100%;
    padding: 0 15px;
    &:hover
      background: rgba(245,247,250,1);
    .itemInner
      height: 52px;
      width: 100%;
      border-bottom: 1px solid #F0F0F0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
</style>
