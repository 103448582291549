<template>
  <div class="emailSetting">
    <div class="settingHeader">
      <span
        @click.stop="goBack"
        class="iconfont_Me icon-return iconStyle"
      ></span>
      <div class="addAccount" @click.stop="addAccount">
        <el-image
          style="width: 100%; height: 100%"
          :src="require('../../../../../../assets/images/email/bind/add.png')"
          :fit="'cover'"
        ></el-image>
      </div>
    </div>
    <div class="settingContent">
      <div
        class="itemBox"
        v-for="(item, mainIndex) in accounts"
        :key="item.originProviderId"
      >
        <div class="emailAccount">
          <el-image
            style="width: 36px; height: 36px; flex-shrink: 0;"
            :src="
              require('../../../../../../assets/images/email/bind/logo_icon.png')
            "
            :fit="'fill'"
          ></el-image>
          <div class="showBox">
            <div class="accountTitle" v-if="item.name">{{ item.name }}</div>
            <div class="mailAddress">
              {{ item.mailAddress }}
            </div>
          </div>
          <div
            class="deleteBox"
            @click.stop="deleteAccount(item)"
            v-if="item.bindCondition == 2"
          >
            <el-image
              style="width: 100%; height: 100%"
              :src="
                require('../../../../../../assets/images/email/list/trash_operational.png')
              "
              :fit="'cover'"
            ></el-image>
          </div>
        </div>
        <div
          class="infoBox"
          v-for="(info, index) in item.userSecurityInformation"
          :key="index"
        >
          <div class="infoIcon">
            <el-image
              style="width: 100%; height: 100%"
              :src="
                require(`../../../../../../assets/images/email/bind/item_${index}.png`)
              "
              :fit="'cover'"
            ></el-image>
          </div>
          <div class="infoContent">
            <div class="inputBox" :title="info.val">
              <template v-if="!info.showInput">
                <template v-if="info.type == 'question'">
                  <span class="iconfont_Me icon-check iconStyle"></span>
                </template>
                <template v-else>
                  {{ info.val }}
                </template>
              </template>
              <el-input
                v-else
                v-model="info.val"
                :ref="`input-${mainIndex}-${index}`"
                @keydown.enter.native="changeNickname(mainIndex, index)"
                @input="inputChange($event, mainIndex, index)"
              ></el-input>
            </div>
            <div class="editBox" @click.stop="edit(mainIndex, index)">
              <el-image
                style="width: 100%; height: 100%"
                :src="
                  require(`../../../../../../assets/images/email/bind/edit_2.png`)
                "
                :fit="'cover'"
              ></el-image>
            </div>
          </div>
        </div>
        <div class="autographBox">
          <div class="infoIcon">
            <el-image
              style="width: 100%;"
              :src="
                require(`../../../../../../assets/images/email/bind/autograph.png`)
              "
              :fit="'cover'"
            ></el-image>
          </div>
          <div
            class="boxInner"
            :style="{
              borderBottom:
                item.editorAutograph.sign == '' ? '1px solid #F0F0F0' : '',
            }"
          >
            <div class="operationArea">
              <el-image
                style="width: 30px; height: 30px"
                :src="
                  require(`../../../../../../assets/images/email/bind/edit_2.png`)
                "
                :fit="'cover'"
                @click="editAutograph(item)"
              ></el-image>
            </div>
            <div
              class="showAndEditArea"
              v-if="item.editorAutograph.sign || item.showEditor"
              :style="{
                height: item.showEditor ? '400px' : 'auto',
                maxHeight:
                  item.editorAutograph.sign && !item.showEditor ? '210px' : '',
              }"
            >
              <template v-if="item.showEditor">
                <Eleditor
                  :editorModel="item.editorAutograph.sign"
                  :extensionsProps="'simple'"
                  @uploadMelinkedImage="
                    () => {
                      uploadMelinkedImage(item);
                    }
                  "
                  @ready="
                    (editor) => {
                      onEditorReady(editor, item);
                    }
                  "
                  @change="
                    (html) => {
                      onEditorChange(html, item);
                    }
                  "
                >
                  <template #footer>
                    <div class="editorFooter">
                      <el-button class="buttonStyle" @click="cancelSign(item)">
                        <span class="iconfont_Me icon-x iconStyle"></span>
                      </el-button>
                      <el-button
                        class="buttonStyle"
                        type="primary"
                        @click="confirmSign(item)"
                      >
                        <span class="iconfont_Me icon-check iconStyle"></span>
                      </el-button>
                    </div>
                  </template>
                </Eleditor>
              </template>
              <template v-else>
                <div
                  class="autographView"
                  v-html="item.editorAutograph.sign"
                ></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-upload
      v-show="false"
      ref="uploadImageRef"
      action="/"
      accept="image/*"
      :multiple="false"
      :show-file-list="false"
      :http-request="handlerImageUpload"
      :before-upload="beforeImageUpload"
    ></el-upload>
  </div>
</template>
<script>
import {
  modifyEmailName,
  addMailSign,
  editMailSign,
} from "@/api/newVersion/melinked_email";
import Eleditor from "../sendMail/element_editor";
import { handlerUploadFileToQiniu } from "@/api/qiniu";
export default {
  name: "EmailSetting",
  components: {
    Eleditor,
  },
  data() {
    return {
      accounts: [],
      types: ["password", "phone", "email", "question"],
      editorList: [],
      uploadingItem_email: null,
      uploadImageItem: null,
    };
  },
  computed: {
    emailAccounts() {
      return this.$store.state.melinked_email.emailAccounts;
    },
    // 当前用户信息
    userInfo() {
      return this.$store.getters.userInfo;
    },
    // 在修改后是否需要更新安全信息
    needGetUserinfoStatus() {
      return this.$store.state.accountSecurity.needGetUserinfoStatus;
    },
    // 当前登录用户的安全信息
    secureInfo() {
      return this.$store.state.accountStore.secureInfo;
    },
  },
  watch: {
    // 监听是否需要执行更新用户安全信息方法 中文
    needGetUserinfoStatus: {
      async handler(val, old) {
        if (val && val !== old) {
          await this.handlerGetUserinfo();
          this.refreshEmailAccounts();
          this.$store.commit("setNeedGetUserinfoStatus", false);
        }
      },
      deep: true,
    },
    emailAccounts: {
      handler(val) {
        if (val) {
          if (val.length == 0) {
            this.$store.commit("setEmailCurrentComponentHistory", "bind");
            return;
          }
          this.handlerAccounts(val);
        } else {
          this.accounts = [];
        }
      },
      deep: true,
      immediate: true,
    },
    uploadImageItem: {
      handler(val) {
        if (val.path && parseInt(val.progress) == 100) {
          this.insertImageAtCursor(val.path);
        }
      },
      deep: true,
    },
  },
  methods: {
    // 处理列表展示队列
    handlerAccounts(val) {
      this.accounts = _.cloneDeep(val);
      this.accounts.map((account) => {
        account.showEditor = false;
        // 找到原生绑定的邮箱账号
        if (
          account.originProviderId == this.userInfo.id &&
          account.bindCondition == 1
        ) {
          account.userSecurityInformation = [
            {
              type: "nickname",
              showInput: false,
              val: account.name,
            },
            {
              type: "password",
              realVal: account.password,
              val: "*********",
            },
            {
              type: "phone",
              val: account.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2"),
            },
            {
              type: "email",
              val: this.desensitizationEmail(account.email),
            },
            {
              type: "question",
              val: account.hasQues,
            },
          ];
        } else {
          account.userSecurityInformation = [
            {
              type: "nickname",
              showInput: false,
              val: account.name,
            },
          ];
        }
        account.editorAutograph = {
          sign: "",
        };
        if (account.signInfo[0]) {
          account.editorAutograph = _.cloneDeep(account.signInfo[0]);
        }
      });
    },
    // 确认编辑签名 如何通过js过滤掉字符串中空P标签
    async confirmSign(item) {
      if (item.editorAutograph.id) {
        let data = {
          id: item.editorAutograph.id,
          sign: item.editorAutograph.sign.replace(/<p>\s*?<\/p>/gi, ""),
          signName: "",
        };
        const result = await editMailSign(data);
        if (result.code == 200) {
          await this.globalGetBindEmailList();
          item.showEditor = false;
          this.$forceUpdate();
        } else {
          this.$message.error(result.message);
        }
      } else {
        let data = {
          mailAddress: item.mailAddress,
          sign: item.editorAutograph.sign.replace(/<p>\s*?<\/p>/gi, ""),
          signName: "",
        };
        const result = await addMailSign(data);
        if (result.code == 200) {
          await this.globalGetBindEmailList();
          item.showEditor = false;
          this.$forceUpdate();
        } else {
          this.$message.error(result.message);
        }
      }
    },
    // 取消编辑签名
    cancelSign(item) {
      item.editorAutograph = {
        sign: "",
      };
      if (item.signInfo[0]) {
        item.editorAutograph = _.cloneDeep(item.signInfo[0]);
      }
      item.showEditor = false;
      this.$forceUpdate();
    },
    // 编辑签名
    editAutograph(item) {
      this.accounts.forEach((account) => {
        account.showEditor = false;
      });
      item.showEditor = true;
      this.$forceUpdate();
    },
    onEditorChange(html, item) {
      item.editorAutograph.sign = html;
    },
    onEditorReady(editor, item) {
      const handler = this.editorList.find((el) => {
        return el.mailAddress == item.mailAddress;
      });
      if (!handler) {
        this.editorList.push({
          mailAddress: item.mailAddress,
          editor: editor,
        });
      } else {
        handler.editor = editor;
      }
    },
    uploadMelinkedImage(item) {
      this.uploadingItem_email = item;
      this.$refs.uploadImageRef.clearFiles();
      this.$refs.uploadImageRef.$children[0].$refs.input.click();
    },
    beforeImageUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) === "image" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(1) < 5
      ) {
        return true;
      }
      if (this.matchFileSuffixType(file.name) !== "image") {
        this.$message({
          type: "error",
          message: this.$t("uploadImageErrTips"),
        });
      } else {
        this.$message({
          type: "error",
          message: "<5MB",
        });
      }
      return false;
    },
    handlerImageUpload(file) {
      try {
        handlerUploadFileToQiniu(file).then(async (item) => {
          this.uploadImageItem = item;
        });
      } catch (error) {}
    },
    //
    insertImageAtCursor(src) {
      if (this.editorList.length > 0 && this.uploadingItem_email) {
        const handlerItem = this.editorList.find(
          (item) => item.mailAddress == this.uploadingItem_email.mailAddress
        );
        if (!handlerItem) {
          return;
        }
        const { state, view } = handlerItem.editor;
        const { schema, tr } = state;
        const node = schema.nodes.image.create({ src });
        console.log(node);
        const transaction = tr.replaceSelectionWith(node);
        view.dispatch(transaction);
      }
    },
    async inputChange(val, mainIndex, index) {
      this.accounts[mainIndex].userSecurityInformation[index].val = val;
      await this.$forceUpdate();
    },
    desensitizationEmail(email) {
      if (email) {
        let emailArray = email.split("@");
        return (
          emailArray[0].replace(/(\w{3})\w*(\w{2})/, "$1****$2") +
          "@" +
          emailArray[1]
        );
      }
      return email;
    },
    async changeNickname(mainIndex, index) {
      this.accounts[mainIndex].userSecurityInformation[index].showInput = false;
      let new_nickname = this.accounts[mainIndex].userSecurityInformation[index]
        .val;
      let mailAddress = this.accounts[mainIndex].mailAddress;
      if (mailAddress == "" || new_nickname == "") {
        return;
      }
      let params = {
        mailAddress: mailAddress,
        name: new_nickname,
      };
      let result = await modifyEmailName(params);
      if (result.code === 200 && result.data.data) {
        let localAccounts = _.cloneDeep(this.emailAccounts);
        localAccounts.map((item) => {
          if (
            item.originProviderId == this.accounts[mainIndex].originProviderId
          ) {
            item.name = new_nickname;
          }
        });
        this.$store.commit("setEmailAccounts", localAccounts);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      await this.$forceUpdate();
    },
    goBack() {
      this.$store.commit("setEmailHistoryComponentGoBack");
    },
    addAccount() {
      this.$store.commit("setEmailCurrentComponentHistory", "bind");
    },
    deleteAccount(item) {
      this.$store.commit("setEmailPasswordVerificationDialog", {
        type: "unbind",
        item: _.cloneDeep(item),
      });
    },
    async edit(mainIndex, index) {
      if (
        this.accounts[mainIndex].userSecurityInformation[index].type ==
        "nickname"
      ) {
        this.accounts[mainIndex].userSecurityInformation[
          index
        ].showInput = true;
      } else {
        this.handlerShowDialog(
          this.accounts[mainIndex].userSecurityInformation[index].type,
          this.accounts[mainIndex]
        );
      }
      await this.$forceUpdate();
      try {
        if (this.$refs[`input-${mainIndex}-${index}`]) {
          this.$refs[`input-${mainIndex}-${index}`][0].focus();
        }
      } catch (error) {}
    },
    async handlerShowDialog(type, item) {
      if (!this.types.includes(type)) {
        return;
      }
      this.$store.commit("setCurrentModificationEmailAccount", item);
      await this.$nextTick();
      this.$store.commit("setAccountSecurityDialogStatus", true);
      this.$store.commit("setNeedShowAccountSecurityComponentName", type);
    },
    // 获取用户安全信息
    handlerGetUserinfo() {
      return new Promise(async (resolve, reject) => {
        await this.$store.dispatch(
          "commitSetSecureInfo",
          this.userInfo.account || this.userInfo.id
        );
        if (this.secureInfo.securePhone && this.secureInfo.securePhone !== "") {
          try {
            this.$store.commit(
              "setSecurityPhoneAreaCode",
              this.secureInfo.securePhone.split("-")[0]
            );
          } catch (e) {}
        }
        resolve("success");
      });
    },
    // 刷新本地缓存的邮箱账号列表信息
    refreshEmailAccounts() {
      let cloneData = _.cloneDeep(this.emailAccounts);
      cloneData = cloneData.map((data) => {
        // 找到列表当中的原始邮箱信息
        if (
          data.originProviderId == this.userInfo.id &&
          data.bindCondition == 1
        ) {
          data.phone = this.secureInfo.securePhone;
          data.email = this.secureInfo.secureEmail;
          data.hasQues = this.secureInfo.hasSecureQuestion;
        }
        return data;
      });
      this.$store.commit("setEmailAccounts", cloneData);
    },
  },
};
</script>
<style lang="stylus" scoped>
.emailSetting
  width: 100%;
  height: 100%;
  display: flex;
  background: #FFFFFF;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  .settingHeader
    width: 100%;
    height: 55px;
    flex-shrink: 0;
    border-bottom: 1px solid #D9D9D9;
    padding: 0 32px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iconStyle
      font-size: 20px;
      color: #292D32;
      cursor: pointer;
    .addAccount
      width: 32px;
      height: 32px;
      cursor: pointer;
      border-radius: 50%;
      background: #F0F0F0;
  .settingContent
    width: 100%;
    min-height: 0;
    flex: 1;
    padding: 0 150px 20px 150px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-light();
    .itemBox
      width: 100%;
      overflow: hidden;
      margin-bottom: 40px;
      .emailAccount
        width: 100%;
        margin-top: 35px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .showBox
          min-width: 0;
          flex: 1;
          height: 25px;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 16px;
          .accountTitle
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            margin-right: 4px;
            user-select: all;
          .mailAddress
            display: inline-block;
            font-size: 16px;
            color: #858585;
            user-select: all;
        .deleteBox
          width: 20px;
          height: 20px;
          flex-shrink: 0;
      .infoBox
        width: 100%;
        height: 46px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .infoIcon
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 12px;
          flex-shrink: 0;
        .infoContent
          min-width: 0;
          flex: 1;
          height: 100%;
          box-sizing: border-box;
          border-bottom: 1px solid #F0F0F0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .inputBox
            flex: 1;
            min-width: 0;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            color: #000000;
            padding: 0 20px;
            line-height: 46px;
            :deep .el-input
              width: 100%;
              height: 100%;
              overflow: hidden;
              .el-input__inner
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                font-size: 16px;
                color: #000000;
                padding: 0;
            .iconStyle
              font-size: 20px;
              color: #3c6;
          .editBox
            width: 30px;
            height: 30px;
            flex-shrink: 0;
            cursor: pointer;
      .autographBox
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .infoIcon
          width: 30px;
          height: 50px;
          overflow: hidden;
          margin-right: 12px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        .boxInner
          min-width: 0;
          flex: 1;
          overflow: hidden;
          min-height: 50px;
          .operationArea
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          .showAndEditArea
            width: 100%;
            border-bottom: 1px solid #F0F0F0;
            ::v-deep .el-tiptap-editor__content
              border-radius: 0 !important;
              &::-webkit-scrollbar
                width: 4px;
              &::-webkit-scrollbar-thumb
                border-radius: 2px;
                box-shadow: inset 0 0 5px rgba(100, 100, 100, 0);
                background: #ADADAD;
              &::-webkit-scrollbar-track
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
                border-radius: 0;
                background: rgba(0, 0, 0, 0);
            .editorFooter
              width: 100%;
              height: 40px;
              box-sizing: border-box;
              border-left: 1px solid #F0F0F0;
              border-right: 1px solid #F0F0F0;
              padding: 0 20px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .buttonStyle
                height: 36px;
                width: 90px;
                margin-left: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                .iconStyle
                  font-size: 20px;
            .autographView
              width: 100%;
              height: auto;
              max-height: 209px;
              background-color: #FFFFFF;
              border-top: 1px solid #F0F0F0;
              box-sizing: border-box;
              padding: 18px;
              overflow-x: hidden;
              overflow-y: auto;
              &::-webkit-scrollbar
                width: 4px;
              &::-webkit-scrollbar-thumb
                border-radius: 2px;
                box-shadow: inset 0 0 5px rgba(100, 100, 100, 0);
                background: #ADADAD;
              &::-webkit-scrollbar-track
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
                border-radius: 0;
                background: rgba(0, 0, 0, 0);
</style>
<style lang="stylus">
.autographView
  ol
    list-style-type: decimal;
    li
      list-style-type: inherit;
  ul
    padding-left: 20px;
    list-style-type: inherit !important;
    li
      list-style-type: inherit;
</style>
