<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="$t('attchFile')"
    placement="top"
  >
    <el-popover
      placement="bottom-start"
      width="125"
      trigger="hover"
      popper-class="attchFilePopover"
      v-if="showType"
    >
      <div class="buttonBox">
        <div class="buttonText" @click="addAttachment">
          {{ $t("attchFile") }}
        </div>
        <div
          :style="{
            width: '85px',
            height: '1px',
            'background-color': '#D9D9D9',
          }"
        ></div>
        <div class="buttonText" @click="addOriginalAttachment">
          {{ $t("addOriginalAttachment") }}
        </div>
      </div>
      <div class="AttachmentButton" slot="reference">
        <img
          style="width:20px;height:20px;"
          src="https://imfile.melinked.com/2023/10/2ffd927f-4a55-4b4d-bf2c-7c8276f87d25.png?imageView2/2/w/150/h/100/format/png|imageslim"
        />
      </div>
    </el-popover>
    <div class="AttachmentButton" v-else @click="addAttachment">
      <img
        style="width:20px;height:20px;"
        src="https://imfile.melinked.com/2023/10/2ffd927f-4a55-4b4d-bf2c-7c8276f87d25.png?imageView2/2/w/150/h/100/format/png|imageslim"
      />
    </div>
  </el-tooltip>
</template>
<script>
export default {
  computed: {
    openEmailView() {
      return this.$store.state.melinked_email.openEmailView;
    },
    showType() {
      const replyTypes = ["reply", "replyAll"];
      if (!this.openEmailView) return false;

      const { replyItemInfo, replyAllItemInfo, type } = this.openEmailView;
      const itemInfo = replyItemInfo || replyAllItemInfo;

      if (itemInfo) {
        const { attachments } = itemInfo;
        return (
          attachments && attachments.length > 0 && replyTypes.includes(type)
        );
      }

      return false;
    },
  },
  methods: {
    addAttachment() {
      this.$emit("addAttachment");
    },
    addOriginalAttachment() {
      this.$emit("addOriginalAttachment");
    },
  },
};
</script>
<style lang="stylus" scoped>
.AttachmentButton
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;
  &:hover
    background: #ecf5ff;
</style>
<style lang="stylus">
.attchFilePopover {
  width: 125px !important;
  min-width: 125px !important;
  padding: 0 !important;
  .buttonBox {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .buttonText {
      width: 100%;
      padding: 10px 0;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      word-break: keep-all;
      &:hover{
        color: #52bd68;
      }
    }
  }
}
</style>
