<template>
  <div class="readEmailBox">
    <div class="functionButtonArea">
      <div class="leftBox">
        <div class="buttonBox" @click.stop="goBack">
          <span class="iconfont_Me icon-return backStyle"></span>
        </div>
        <div
          class="buttonBox"
          v-for="(item, index) in functionButtons"
          :key="index"
          @click.stop="clickEmailButton(item)"
          :style="{
            width: item.name == 'edit' ? '30px' : '',
            marginRight: item.name == 'edit' ? '20px' : '',
          }"
        >
          <el-tooltip
            effect="dark"
            :content="showImg2(item) ? item.title_2 : item.title"
            placement="top"
          >
            <el-image
              style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;"
              :src="showImg2(item) ? item.img_2 : item.img"
              :fit="'contain'"
            ></el-image>
          </el-tooltip>
        </div>
      </div>
      <div class="rightBox">
        <div class="operationPaging">
          <!-- <div class="button" @click.stop="prePage">
            <i
              class="iconfont_Me icon-left iconStyle"
              :style="{
                color: notPre ? '#D9D9D9' : '#858585',
                cursor: notPre ? 'not-allowed' : 'pointer',
              }"
            ></i>
          </div>
          <div class="button" @click.stop="nextPage">
            <i
              class="iconfont_Me icon-right iconStyle"
              :style="{
                color: notNext ? '#D9D9D9' : '#858585',
                cursor: notNext ? 'not-allowed' : 'pointer',
              }"
            ></i>
          </div> -->
        </div>
      </div>
    </div>
    <div class="emailContentArea">
      <div class="emailContent" ref="emailContent">
        <div class="emailHeader" ref="emailHeader">
          <div class="subTitle">
            <span
              class="titleText"
              :title="currentReadItemInfo.subject"
              v-html="currentReadItemInfo.subject"
            ></span>
            <el-image
              style="width: 20px; height: 20px; margin-left: 8px;flex-shrink: 0"
              :src="
                currentReadItemInfo.mark
                  ? require('../../../../../../assets/images/email/list/star_bright.png')
                  : require('../../../../../../assets/images/email/list/star_grey.png')
              "
              :fit="'cover'"
            ></el-image>
          </div>
          <div class="subInfoBox">
            <template v-if="refreshContactName">
              <div class="commonLine" v-if="currentReadItemInfo.from">
                <span> {{ $t("emailFrom") }}: </span>
                <span
                  v-for="(item, index) in handlerShowName(
                    currentReadItemInfo.from
                  )"
                  :key="index"
                >
                  <template v-if="currentReadItemInfo.folder_name == 'Sent'">
                    <span class="fromName">
                      {{ `${item.name}<${item.email}>` }}
                    </span>
                  </template>
                  <template v-else>
                    <el-popover
                      placement="bottom"
                      trigger="click"
                      popper-class="emailFromOperatePopover"
                    >
                      <div class="operatetitle">
                        {{ item.email }}
                      </div>
                      <div class="operateBox">
                        <div
                          class="buttonStyle"
                          @click.stop="sendAnEmail(item.email)"
                        >
                          {{ $t("sending") }}
                        </div>
                        <div
                          class="buttonStyle"
                          @click.stop="setContactData(item.email)"
                        >
                          {{ $t("saveAsAddressBook") }}
                        </div>
                      </div>
                      <span
                        class="fromName"
                        slot="reference"
                        :style="{ cursor: 'pointer' }"
                      >
                        {{ `${item.name}<${item.email}>` }}
                      </span>
                    </el-popover>
                  </template>
                </span>
              </div>
              <div class="commonLine" v-if="currentReadItemInfo.to">
                <span>
                  {{ $t("emailRecipient") }}:
                  <span
                    v-for="(item, index) in handlerShowName(
                      currentReadItemInfo.to
                    )"
                    :key="index"
                  >
                    <span v-if="currentEmailAccount.mailAddress == item.email">
                      {{ `${item.name}<${item.email}>` }}
                    </span>
                    <template v-else>
                      <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="emailFromOperatePopover"
                      >
                        <div class="operatetitle">
                          {{ item.email }}
                        </div>
                        <div class="operateBox">
                          <div
                            class="buttonStyle"
                            @click.stop="sendAnEmail(item.email)"
                          >
                            {{ $t("sending") }}
                          </div>
                          <div
                            class="buttonStyle"
                            @click.stop="setContactData(item.email)"
                          >
                            {{ $t("saveAsAddressBook") }}
                          </div>
                        </div>
                        <span slot="reference" :style="{ cursor: 'pointer' }">
                          {{ `${item.name}<${item.email}>` }}
                        </span>
                      </el-popover>
                    </template>
                  </span>
                </span>
              </div>
              <div class="commonLine" v-if="currentReadItemInfo.cc">
                <span> {{ $t("CcTo") }}: </span>
                <span
                  v-for="(item, index) in handlerShowName(
                    currentReadItemInfo.cc
                  )"
                  :key="index"
                >
                  <span v-if="currentEmailAccount.mailAddress == item.email">
                    {{ `${item.name}<${item.email}>` }}
                  </span>
                  <template v-else>
                    <el-popover
                      placement="bottom"
                      trigger="click"
                      popper-class="emailFromOperatePopover"
                    >
                      <div class="operatetitle">
                        {{ item.email }}
                      </div>
                      <div class="operateBox">
                        <div
                          class="buttonStyle"
                          @click.stop="sendAnEmail(item.email)"
                        >
                          {{ $t("sending") }}
                        </div>
                        <div
                          class="buttonStyle"
                          @click.stop="setContactData(item.email)"
                        >
                          {{ $t("saveAsAddressBook") }}
                        </div>
                      </div>
                      <span slot="reference" :style="{ cursor: 'pointer' }">
                        {{ `${item.name}<${item.email}>` }}
                      </span>
                    </el-popover>
                  </template>
                </span>
              </div>
              <div
                class="commonLine"
                v-if="
                  currentReadItemInfo.bcc &&
                    currentReadItemInfo.folder_name == 'Sent'
                "
              >
                <span> {{ $t("bcc") }}: </span>
                <span
                  v-for="(item, index) in handlerShowName(
                    currentReadItemInfo.bcc
                  )"
                  :key="index"
                >
                  <span v-if="currentEmailAccount.mailAddress == item.email">
                    {{ `${item.name}<${item.email}>` }}
                  </span>
                  <template v-else>
                    <el-popover
                      placement="bottom"
                      trigger="click"
                      popper-class="emailFromOperatePopover"
                    >
                      <div class="operatetitle">
                        {{ item.email }}
                      </div>
                      <div class="operateBox">
                        <div
                          class="buttonStyle"
                          @click.stop="sendAnEmail(item.email)"
                        >
                          {{ $t("sending") }}
                        </div>
                        <div
                          class="buttonStyle"
                          @click.stop="setContactData(item.email)"
                        >
                          {{ $t("saveAsAddressBook") }}
                        </div>
                      </div>
                      <span slot="reference" :style="{ cursor: 'pointer' }">
                        {{ `${item.name}<${item.email}>` }}
                      </span>
                    </el-popover>
                  </template>
                </span>
              </div>
            </template>
            <div class="commonLine">
              <span> {{ $t("time") }}: </span>
              <span>{{
                formatTimestamp(currentReadItemInfo.sendTimestamp)
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="ICSFileBox"
          ref="ICSFileBox"
          v-if="haveICSFile && ICSInfoData"
          v-loading="ICFLoading"
          element-loading-spinner="el-icon-loading"
        >
          <div class="ICSCardInfo">
            <div class="header">
              <img
                :src="
                  require('../../../../../../assets/images/email/calendar.png')
                "
                class="ICSImg"
              />
              {{ ICSText }}
            </div>
            <!-- 如果是ics回复文件则不显示操作按钮 -->
            <template v-if="icsFileStatus != 'reply'">
              <!-- 如果当前邮件ics操作状态为默认并且ics文件状态为请求状态则显示操作按钮 -->
              <div
                class="buttons"
                v-if="
                  calendarEmailFlags == 'default' && icsFileStatus == 'request'
                "
              >
                <div
                  class="buttonStyle button_1"
                  v-waves
                  @click.stop="confirmCalendar"
                >
                  <i class="iconfont_Me icon-check iconStyle"></i>
                </div>
                <div
                  class="buttonStyle button_2"
                  v-waves
                  @click.stop="cancelCalendar"
                >
                  <i class="iconfont_Me icon-x iconStyle"></i>
                </div>
              </div>
              <!-- 如果当前邮件ics操作状态为接受或者拒绝并且ics文件状态不为取消状态，则显示状态按钮 -->
              <div
                class="buttons"
                v-else-if="
                  (calendarEmailFlags == 'accepted' ||
                    calendarEmailFlags == 'declined') &&
                    icsFileStatus != 'cancel'
                "
              >
                <div class="buttonStyle button_result" v-waves>
                  <img
                    style="width: 100%"
                    :src="
                      calendarEmailFlags == 'accepted'
                        ? `https://imfile.melinked.com/2024/07/97282497-995f-4ce3-8bec-4eb5d1e0e9fd.png`
                        : `https://imfile.melinked.com/2024/07/cd167ae8-2103-4122-8d6b-63a73ad6764b.png`
                    "
                  />
                </div>
              </div>
              <!-- 如果不满足上述条件，全部显示拒绝按钮 -->
              <div class="buttons" v-else>
                <div class="buttonStyle button_result" v-waves>
                  <img
                    style="width: 100%"
                    :src="
                      `https://imfile.melinked.com/2024/07/cd167ae8-2103-4122-8d6b-63a73ad6764b.png`
                    "
                  />
                </div>
              </div>
            </template>
          </div>
          <div
            class="ICSContent"
            v-if="ICSInfoData.summary || ICSInfoData.description"
          >
            <div class="summaryBox" v-if="ICSInfoData.summary">
              {{ ICSInfoData.summary }}
            </div>
            <div
              class="descriptionBox"
              v-if="ICSInfoData.description"
              v-html="handlerDescription(ICSInfoData.description)"
            ></div>
            <div class="locationBox" v-if="ICSInfoData.location">
              <i class="iconfont_Me icon-position iconStyle"></i
              >{{ ICSInfoData.location }}
            </div>
          </div>
        </div>
        <div
          class="emailAttachment"
          ref="emailAttachment"
          v-if="handlerAttachments.length != 0"
        >
          <div class="attachmentBox" v-resize-ob="attachmentBoxResize">
            <EmailAttachmentItem
              v-for="(attachment, index) in handlerShowAttachments"
              :key="index"
              :preview="true"
              :attachment="attachment"
              :emailInfo="currentReadItemInfo"
            />
          </div>
          <div
            class="displayFiles"
            v-if="handlerAttachments.length > this.attachmentsSliceNumber"
          >
            <div class="displayImg" @click="changeAttachmentList">
              <img
                :src="
                  require('../../../../../../assets/images/email/drop_down .png')
                "
                :style="{
                  transform: attachmentListStatus ? 'rotate(180deg)' : '',
                }"
              />
            </div>
            <div class="displayText" @click="changeAttachmentList">
              {{
                attachmentListStatus
                  ? $t("hide")
                  : $t("displayAttachments", {
                      m: $t("show"),
                      n: handlerAttachments.length,
                    })
              }}
            </div>
          </div>
        </div>
        <div
          class="read_view"
          v-loading="htmlLoading"
          element-loading-spinner="el-icon-loading"
        >
          <iframe
            id="email_iframe"
            ref="email_iframe"
            frameborder="0"
            :allowpaymentrequest="true"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmailAttachmentItem from "../components/attachmentItem.vue";
import {
  markEmails,
  moveEmails,
  exportEmlFile,
  attachmentDownload,
} from "@/api/newVersion/melinked_email";
import moment from "moment";
import momentTimezone from "moment-timezone";
import {
  modifyLocalEmailData,
  deleteEmailsByUniqueKey,
  updateEmailStarredByUniqueKey,
} from "@/api/indexDB/api/email_api";
import { getLocalEmailContactsList } from "@/api/indexDB/api/email_address_book_api";
import ICAL from "ical.js/dist/ical.es5.min.cjs";
import Bus from "@/utils/bus.js";
export default {
  name: "ReadEmailBox",
  components: {
    EmailAttachmentItem,
  },
  data() {
    return {
      isMark: true,
      completelyDelete: false,
      handlerAttachments: [],
      downloadSources: {},
      handlerEmailContent: "",
      htmlLoading: false,
      attachmentListStatus: false,
      attachmentsSliceNumber: 0,
      contactsList: [],
      imageViewer: null,
      haveICSFile: false,
      ICSText: "",
      ICSInfoData: null,
      calendarStatus: {
        // 接受
        accepted: "PARTSTAT=ACCEPTED",
        // 暂定
        tentative: "PARTSTAT=TENTATIVE",
        // 拒绝
        declined: "PARTSTAT=DECLINED",
        // 需要处理
        needsAction: "PARTSTAT=NEEDS-ACTION",
        // 委派
        delegated: "PARTSTAT=DELEGATED",
      },
      ICFLoading: false,
      vcalendar: null,
      refreshContactName: true,
    };
  },
  computed: {
    notPre() {
      return true;
    },
    notNext() {
      return false;
    },
    defaultImgs() {
      return [
        {
          title: this.$t("editpost"),
          name: "edit",
          img: require("../../../../../../assets/images/email/list/edit.png"),
        },
        {
          title: this.$t("markEmail"),
          title_2: this.$t("cancelMark"),
          name: "star_grey",
          img: require("../../../../../../assets/images/email/list/star_operational.png"),
          img_2: require("../../../../../../assets/images/email/list/star_bright.png"),
        },
        {
          title: this.$t("replyEmail"),
          name: "reply",
          img: require("../../../../../../assets/images/email/read/reply.png"),
        },
        {
          title: this.$t("replyAllEmail"),
          name: "replyAll",
          img: require("../../../../../../assets/images/email/read/replyAll.png"),
        },
        {
          title: this.$t("forwardEmail"),
          name: "forward",
          img: require("../../../../../../assets/images/email/list/forward_operational.png"),
        },
        {
          title: this.$t("putTrash"),
          title_2: this.$t("completelyDelete"),
          name: "trash",
          img: require("../../../../../../assets/images/email/list/trash_operational.png"),
          img_2: require("../../../../../../assets/images/email/list/completely_delete_operational.png"),
        },
        {
          title: this.$t("mark_as_spam"),
          name: "spam",
          img: require("../../../../../../assets/images/email/list/spam_operational.png"),
        },
        {
          title: this.$t("mark_as_not_spam_email"),
          name: "recall_spam",
          img: require("../../../../../../assets/images/email/list/recall_spam_operational.png"),
        },
        {
          title: this.$t("downloadEML"),
          name: "download",
          img: require("../../../../../../assets/images/email/list/downloadEML.png"),
        },
      ];
    },
    functionButtons() {
      let buttons = [];
      switch (this.currentReadItemInfo.folder_name) {
        case "Spam":
          buttons = [
            "star_grey",
            "reply",
            "replyAll",
            "forward",
            "trash",
            "recall_spam",
            "download",
          ];
          break;
        case "INBOX":
          buttons = [
            "star_grey",
            "reply",
            "replyAll",
            "forward",
            "trash",
            "spam",
            "download",
          ];
          break;
        case "Sent":
          buttons = [
            "edit",
            "star_grey",
            "reply",
            "replyAll",
            "forward",
            "trash",
            "download",
          ];
          break;
        default:
          buttons = [
            "star_grey",
            "reply",
            "replyAll",
            "forward",
            "trash",
            "download",
          ];
          break;
      }
      return this.defaultImgs.filter((item) => {
        return buttons.includes(item.name);
      });
    },
    // 当前阅读的对象
    currentReadItemInfo() {
      return this.$store.state.melinked_email.currentReadItemInfo;
    },
    // 当前选中的小菜单
    currentSubMenuInfo() {
      return this.$store.state.melinked_email.currentSubMenuInfo;
    },
    // 当前打开的邮箱账号
    currentEmailAccount() {
      return this.$store.state.melinked_email.currentEmailAccount;
    },
    // 邮箱列表类型
    emailBoxTypes() {
      return this.$store.state.melinked_email.emailBoxTypes;
    },
    // 当前附件下载状态记录队列
    readingEmailAttachmentDownloads() {
      return this.$store.state.melinked_email.readingEmailAttachmentDownloads;
    },
    handlerShowAttachments() {
      return this.attachmentListStatus
        ? this.handlerAttachments
        : this.handlerAttachments.slice(0, this.attachmentsSliceNumber);
    },
    calendarEmailFlags() {
      let flags = this.currentReadItemInfo.flags,
        status = "default";
      if (flags.indexOf(this.calendarStatus.accepted) != -1) {
        status = "accepted";
      } else if (flags.indexOf(this.calendarStatus.declined) != -1) {
        status = "declined";
      }
      return status;
    },
    icsFileStatus() {
      let status = "";
      switch (this.ICSInfoData.method) {
        case "REQUEST":
          status = "request";
          break;
        case "CANCEL":
          status = "cancel";
          break;
        case "REPLY":
          status = "reply";
          break;
        default:
          status = "default";
          break;
      }
      return status;
    },
  },
  async beforeMount() {
    // 获取本地联系人列表
    this.contactsList = await getLocalEmailContactsList();
  },
  async mounted() {
    if (this.emailBoxTypes.indexOf(this.currentSubMenuInfo.name) == -1) {
      this.$message({
        type: "error",
        message: "传入邮件文件夹类型错误",
      });
      return;
    }
    if (
      this.currentReadItemInfo.folder_name == "Trash" ||
      this.currentReadItemInfo.folder_name == "Spam"
    ) {
      this.completelyDelete = true;
    }
    if (this.currentReadItemInfo.folder_name == "Sent") {
      // 如果是收件箱的邮件，需要从header获取密送人
      try {
        const headers = this.currentReadItemInfo.headers;
        headers.find((header) => {
          if (header.name == "RCPT-BCC" && header.value) {
            const info = _.cloneDeep(this.currentReadItemInfo);
            info.bcc = header.value;
            this.$store.commit("setCurrentReadItemInfo", info);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    await this.startHandleEmailMemory();
    // 绑定修改通讯录名称后刷新邮箱地址
    Bus.$on("refreshEmailContactName", async () => {
      this.contactsList = await getLocalEmailContactsList();
      this.refreshContactName = false;
      this.$nextTick(() => {
        this.refreshContactName = true;
      });
    });
  },
  beforeDestroy() {
    Bus.$off("refreshEmailContactName");
    this.clearImageViewer();
  },
  methods: {
    // 处理描述
    handlerDescription(description) {
      return this.handlerHtmlByDompurify(
        this.hyperlinksCheck(description, true)
      );
    },
    // 日程确认
    async confirmCalendar() {
      // 同步一下最新状态
      this.ICFLoading = true;
      await this.updateCurrentReadItemInfo();
      if (this.calendarEmailFlags == "default") {
        let params = {
          folder:
            this.currentReadItemInfo.folder_name ||
            this.currentReadItemInfo.mailboxName,
          flag: this.calendarStatus.accepted,
          set: true,
          uids: this.currentReadItemInfo.uid,
        };
        const result = await markEmails(params);
        if (result.code == 200) {
          // 更新本地邮件flags
          const uniqueIdentifier = this.currentReadItemInfo.uniqueIdentifier;
          await modifyLocalEmailData(uniqueIdentifier, {
            flags: `${this.currentReadItemInfo.flags},${this.calendarStatus.accepted}`,
          });
          const info = _.cloneDeep(this.currentReadItemInfo);
          info.flags = `${info.flags},${this.calendarStatus.accepted}`;
          this.$store.commit("setCurrentReadItemInfo", info);
          await this.sendCalendarEmail(1);
          this.ICFLoading = false;
        } else if (result.code == 501) {
          await this.deleteNonExistentData(result);
          this.ICFLoading = false;
        } else {
          this.ICFLoading = false;
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } else {
        this.ICFLoading = false;
        this.$message({
          type: "error",
          message: this.$t("calendarTips"),
        });
      }
    },
    // 日程拒绝
    async cancelCalendar() {
      // 同步一下最新状态
      this.ICFLoading = true;
      await this.updateCurrentReadItemInfo();
      if (this.calendarEmailFlags == "default") {
        let params = {
          folder:
            this.currentReadItemInfo.folder_name ||
            this.currentReadItemInfo.mailboxName,
          flag: this.calendarStatus.declined,
          set: true,
          uids: this.currentReadItemInfo.uid,
        };
        const result = await markEmails(params);
        if (result.code == 200) {
          // 更新本地邮件flags
          const uniqueIdentifier = this.currentReadItemInfo.uniqueIdentifier;
          await modifyLocalEmailData(uniqueIdentifier, {
            flags: `${this.currentReadItemInfo.flags},${this.calendarStatus.declined}`,
          });
          const info = _.cloneDeep(this.currentReadItemInfo);
          info.flags = `${info.flags},${this.calendarStatus.declined}`;
          this.$store.commit("setCurrentReadItemInfo", info);
          await this.sendCalendarEmail(0);
          this.ICFLoading = false;
        } else if (result.code == 501) {
          await this.deleteNonExistentData(result);
          this.ICFLoading = false;
        } else {
          this.ICFLoading = false;
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } else {
        this.ICFLoading = false;
        this.$message({
          type: "error",
          message: this.$t("calendarTips"),
        });
      }
    },
    // 生成回复的ICS文件
    async generateICSFile(partstat) {
      return new Promise((resolve, reject) => {
        try {
          if (this.vcalendar) {
            const vevent = this.vcalendar.getFirstSubcomponent("vevent");
            const event = new ICAL.Event(vevent);
            if (event.attendees.length > 0) {
              event.attendees.forEach((attendee) => {
                const email = attendee.getFirstValue();
                if (email.indexOf(this.currentReadItemInfo.sourceEmail) != -1) {
                  attendee.setParameter("partstat", partstat);
                }
              });
            }
            let methodProperty = this.vcalendar.getFirstProperty("method");
            if (methodProperty) {
              methodProperty.setValue("REPLY");
            } else {
              this.vcalendar.addPropertyWithValue("method", "REPLY");
            }
            const updatedIcs = this.vcalendar.toString();
            const file = new File([updatedIcs], "calendar.ics", {
              type: "text/calendar",
            });
            resolve(file);
          } else {
            throw new Error("Invalid ICS file");
          }
        } catch (error) {
          reject();
        }
      });
    },
    async deleteNonExistentData(result) {
      if (result.message != "") {
        const uids = result.message.split(",");
        if (uids.length != 0) {
          try {
            await deleteEmailsByUniqueKey([
              this.currentReadItemInfo.uniqueIdentifier,
            ]);
          } catch (error) {
            console.log(error);
          }
        }
      }
      this.$message({
        type: "error",
        message: this.$t("emailDoesNotExist"),
      });
    },
    // 发送日程邮件(默认取消)
    async sendCalendarEmail(type = 1) {
      let imageUrl = "",
        partstat = "";
      switch (type) {
        case 0:
          imageUrl =
            "https://imfile.melinked.com/2024/07/cd167ae8-2103-4122-8d6b-63a73ad6764b.png";
          partstat = "DECLINED";
          break;
        case 1:
          imageUrl =
            "https://imfile.melinked.com/2024/07/97282497-995f-4ce3-8bec-4eb5d1e0e9fd.png";
          partstat = "ACCEPTED";
          break;
      }
      const icsFile = await this.generateICSFile(partstat);
      const contentBlock = document.createElement("div");
      const imgBlock = document.createElement("img");
      imgBlock.setAttribute("src", imageUrl);
      imgBlock.setAttribute("style", "width: 70px");
      contentBlock.appendChild(imgBlock);
      contentBlock.setAttribute(
        "style",
        "width: 100%;height:100%;background: #FFFFFF;"
      );
      let params = {
        account: this.currentEmailAccount,
        toStr: this.currentReadItemInfo.from,
        subject: this.currentReadItemInfo.subject,
        content: contentBlock.outerHTML,
        files: [icsFile],
      };
      contentBlock.remove();
      const result = await this.globalSendEmail(params);
      if (result.code == 200) {
        this.$message({
          type: "success",
          message: "success",
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    handlerShowName(email) {
      const getEmailInfo = (str) => {
        return {
          name: this.getEmailAddressBookName(str) || this.splitName(str),
          email: str,
        };
      };
      if (email.indexOf(",") != -1) {
        const emails = email.split(",");
        let handlerEmails = emails
          .filter((item) => item)
          .map((item) => {
            return getEmailInfo(item);
          });
        return handlerEmails;
      } else {
        return [getEmailInfo(email)];
      }
    },
    // 根据邮箱地址获取通讯录内备注名
    getEmailAddressBookName(email) {
      if (this.contactsList.length > 0) {
        const contact = this.contactsList.find((contact) => {
          return contact.email == email;
        });
        return contact ? contact.name : null;
      }
      return null;
    },
    attachmentBoxResize(ob) {
      this.attachmentsSliceNumber = Math.floor((ob.width + 15) / 375);
    },
    changeAttachmentList() {
      this.attachmentListStatus = !this.attachmentListStatus;
    },
    // 开始处理需要阅读的邮件内容
    async startHandleEmailMemory() {
      this.htmlLoading = true;
      // 处理附件列表
      this.createAttachmentList();
      // 如果本地没有内容，则先获取邮件内容
      await this.updateCurrentReadItemInfo();
      await this.handlerContent(this.currentReadItemInfo.content);
      this.htmlLoading = false;
      this.createImageViewer();
      this.loadHtmlStringIntoIframe(this.handlerEmailContent);
    },
    // 根据情况更新当前邮件信息
    async updateCurrentReadItemInfo() {
      const fetchAndStoreEmailContent = async () => {
        try {
          const params = await this.getEmailContentAndStore(
            this.currentEmailAccount,
            this.currentReadItemInfo
          );
          const info = _.cloneDeep(this.currentReadItemInfo);
          if (params.content) {
            info.content = params.content;
          }
          if (params.flags) {
            info.flags = params.flags;
          }
          this.$store.commit("setCurrentReadItemInfo", info);
        } catch (error) {
          console.log(error);
        }
      };
      if (!this.currentReadItemInfo.content) {
        await fetchAndStoreEmailContent();
      } else if (this.haveICSFile && this.calendarEmailFlags === "default") {
        await fetchAndStoreEmailContent();
      }
      await this.$forceUpdate();
    },
    createAttachmentList() {
      try {
        if (this.currentReadItemInfo.attachments.length != 0) {
          // 生成附件下载进度对象
          const downloads = _.cloneDeep(this.readingEmailAttachmentDownloads);
          this.currentReadItemInfo.attachments.map((attachment) => {
            const key = `${this.currentReadItemInfo.uniqueIdentifier}_${attachment.sectionId}`;
            // 如果后台下载进度队列已经有这个文件的记录，则保持进度不变
            if (!downloads[key]) {
              downloads[key] = -1;
            }
          });
          this.$store.commit("setReadingEmailAttachmentDownloads", downloads);
          this.handlerAttachments = this.currentReadItemInfo.attachments.filter(
            (item) => {
              return (
                item.name.indexOf(".ics") == -1 &&
                item.name.indexOf(".ICS") == -1
              );
            }
          );
          this.haveICSFile = false;
          this.ICSText = "";
          this.vcalendar = null;
          // 获取日程文件
          const icsFile = this.currentReadItemInfo.attachments.find((item) => {
            return (
              item.name.indexOf(".ics") != -1 || item.name.indexOf(".ICS") != -1
            );
          });
          if (icsFile) {
            this.haveICSFile = true;
            this.handlerICSFile(icsFile);
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        this.handlerAttachments = [];
      }
    },
    //
    async handlerICSFile(file) {
      try {
        this.ICSInfoData = await this.autoDownloadICSFile(file);
        const language = localStorage.getItem("locale");
        moment.locale(language.toLowerCase());
        const startDate = moment(this.ICSInfoData.start).format("YYYY/MM/DD");
        const endDate = moment(this.ICSInfoData.end).format("YYYY/MM/DD");
        if (startDate != endDate) {
          this.ICSText = `${moment(this.ICSInfoData.start).format(
            "dddd"
          )},${startDate},${moment(this.ICSInfoData.start).format(
            "HH:mm"
          )}-${endDate},${moment(this.ICSInfoData.end).format(
            "HH:mm"
          )},GMT${momentTimezone().format("Z")}(${momentTimezone.tz.guess()})`;
        } else {
          this.ICSText = `${moment(this.ICSInfoData.start).format(
            "dddd"
          )},${startDate},${moment(this.ICSInfoData.start).format(
            "HH:mm"
          )}-${moment(this.ICSInfoData.end).format(
            "HH:mm"
          )},GMT${momentTimezone().format("Z")}(${momentTimezone.tz.guess()})`;
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: `获取日程文件失败: ${error}`,
        });
      }
    },
    autoDownloadICSFile(attachment) {
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            fileName: attachment.name,
            sectionId: attachment.sectionId,
            messageId: this.currentReadItemInfo.messageId,
            from: this.currentReadItemInfo.from,
          };
          const result = await attachmentDownload(
            params,
            async (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log("ICS下载进度", percentCompleted);
            }
          );
          const reader = new FileReader();
          reader.readAsText(result);
          reader.onload = (e) => {
            const icsData = e.target.result;
            const jcalData = ICAL.parse(icsData);
            this.vcalendar = new ICAL.Component(jcalData);
            // 获取 METHOD 属性
            const method = this.vcalendar.getFirstPropertyValue("method");
            // 获取 VEVENT 组件
            const vevent = this.vcalendar.getFirstSubcomponent("vevent");
            // 获取 VEVENT 属性
            const event = new ICAL.Event(vevent);
            let attendees = [];
            event.attendees.forEach((attendee) => {
              const cn = attendee.getParameter("cn");
              const email = attendee.getFirstValue();
              const partstat = attendee.getParameter("partstat");
              attendees.push({
                name: cn,
                email: email,
                partstat: partstat,
              });
            });
            // 获取日程信息
            // summary: 事件标题
            // start: 事件开始时间
            // end: 事件结束时间
            // location: 事件地点
            // description: 事件描述
            // organizer: 事件组织者
            // duration: 事件持续时间
            // attendees: 事件参与者
            const handlerEvent = {
              summary: event.summary,
              start: new Date(event.startDate.toUnixTime() * 1000),
              end: new Date(event.endDate.toUnixTime() * 1000),
              location: event.location,
              description: event.description,
              organizer: event.organizer,
              duration: event.endDate.subtractDate(event.startDate),
              uid: event.uid,
              method: method,
              attendees: attendees,
            };
            resolve(handlerEvent);
          };
          reader.onerror = function(e) {
            throw new Error(e);
          };
        } catch (error) {
          reject(error.message || error);
        }
      });
    },
    async handlerContent(content) {
      const realContent = this.extractBodyContent(content);
      let handlerEmailContent = "";
      try {
        handlerEmailContent = await this.handlerCidImage(realContent);
      } catch (error) {
        handlerEmailContent = realContent;
      }
      this.handlerEmailContent = handlerEmailContent;
    },
    clearImageViewer() {
      if (this.imageViewer) {
        this.imageViewer.destroy();
        this.imageViewer = null;
      }
      const viewerContainers = document.querySelectorAll(
        "#email-reader-image-viewer"
      );
      if (viewerContainers.length > 0) {
        viewerContainers.forEach((viewerContainer) => {
          viewerContainer.remove();
        });
      }
    },
    // 创建图片查看器
    createImageViewer() {
      this.clearImageViewer();
      const viewerContainer = document.createElement("div");
      viewerContainer.setAttribute("id", "email-reader-image-viewer");
      viewerContainer.style.display = "none";
      document.body.appendChild(viewerContainer);
    },
    // 将HTML渲染到iframe中
    loadHtmlStringIntoIframe(htmlString) {
      const iframe = this.$refs.email_iframe;
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      iframeDocument.head.innerHTML += `<meta charset="UTF-8">`;
      iframeDocument.head.innerHTML += `<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">`;
      iframeDocument.head.innerHTML += `<meta name="viewport" content="width=device-width, initial-scale=1.0">`;
      iframeDocument.head.innerHTML += `<meta http-equiv="X-UA-Compatible" content="IE=edge">`;
      iframeDocument.head.innerHTML += `<meta name="referrer" content="no-referrer">`;
      iframeDocument.body.innerHTML = this.handlerHtmlByDompurify(htmlString);
      const viewerContainer = document.getElementById(
        "email-reader-image-viewer"
      );
      const images = iframeDocument.querySelectorAll("img");
      if (images.length > 0) {
        let imagesLoaded = 0;
        images.forEach((img, index) => {
          const clonedImg = img.cloneNode();
          clonedImg.dataset.index = index;
          viewerContainer.appendChild(clonedImg);
          img.addEventListener("click", () => {
            if (this.imageViewer) {
              this.imageViewer.view(index);
            }
          });
          if (img.complete) {
            imagesLoaded++;
            if (imagesLoaded === images.length) {
              this.adjustIframeHeight(iframe);
            }
          } else {
            img.addEventListener("load", () => {
              imagesLoaded++;
              if (imagesLoaded === images.length) {
                this.adjustIframeHeight(iframe);
              }
            });
          }
        });
        this.imageViewer = this.$viewer(viewerContainer);
      }
      var links = iframeDocument.getElementsByTagName("a");
      for (var i = 0; i < links.length; i++) {
        links[i].setAttribute("target", "_blank");
      }
      iframeDocument.body.setAttribute(
        "style",
        "word-wrap: break-word;overflow-wrap: break-word;word-break: keep-all;"
      );
      this.adjustIframeHeight(iframe);
    },
    // 将iframe的高度适应内部
    adjustIframeHeight(iframe) {
      let minIframeHeight = 0;
      try {
        const contentHeight = this.$refs.emailContent.offsetHeight;
        const headerHeight = this.$refs.emailHeader.offsetHeight;
        const innerHeight_1 = this.$refs.ICSFileBox
          ? this.$refs.ICSFileBox.offsetHeight
          : 0;
        const innerHeight_2 = this.$refs.emailAttachment
          ? this.$refs.emailAttachment.offsetHeight
          : 0;
        minIframeHeight =
          contentHeight - headerHeight - innerHeight_1 - innerHeight_2;
      } catch (error) {}
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      if (
        minIframeHeight &&
        iframeDocument.body.scrollHeight + 40 < minIframeHeight
      ) {
        iframe.style.height = minIframeHeight + "px";
      } else {
        iframe.style.height = iframeDocument.body.scrollHeight + 40 + "px";
      }
    },
    splitName(email) {
      return email.split("@")[0];
    },
    isCompleteHTML(htmlString) {
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        return (
          doc &&
          doc.documentElement &&
          doc.documentElement.nodeName.toLowerCase() === "html"
        );
      } catch (e) {
        return false;
      }
    },
    extractBodyContent(htmlString) {
      if (this.isCompleteHTML(htmlString)) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const bodyContent = doc.body.innerHTML;
        return bodyContent == "null" ? "" : bodyContent;
      } else {
        const bodyContentRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
        const matches = htmlString.match(bodyContentRegex);
        if (matches && matches.length >= 2) {
          return matches[1];
        } else {
          return htmlString ? htmlString : "";
        }
      }
    },
    // 处理邮件内容中的图片问题
    async handlerCidImage(htmlString) {
      const handlerHtmlStr = this.handlerCidImageHtmlString(
        htmlString,
        this.currentReadItemInfo
      );
      return handlerHtmlStr;
    },
    showImg2(item) {
      return (
        (item.name == "star_grey" && this.currentReadItemInfo.mark) ||
        (item.name == "trash" && this.completelyDelete) ||
        (item.name == "spam" && this.completelyDelete)
      );
    },
    formatTimestamp(timestamp) {
      const language = localStorage.getItem("locale");
      moment.locale(language.toLowerCase());
      if (typeof timestamp == "string") {
        timestamp = parseInt(timestamp);
      }
      return `${moment(timestamp).format("YYYY-MM-DD HH:mm:ss")} ${moment(
        timestamp
      ).format("dddd")}`;
    },
    async clickEmailButton(item) {
      switch (item.name) {
        case "reply":
          this.$store.commit("setOpenEmailSendView", {
            open: true,
            type: "reply",
            replyItemInfo: this.currentReadItemInfo,
          });
          this.$store.commit("setEmailCurrentComponentHistory", "send");
          break;
        case "replyAll":
          this.$store.commit("setOpenEmailSendView", {
            open: true,
            type: "replyAll",
            replyAllItemInfo: this.currentReadItemInfo,
          });
          this.$store.commit("setEmailCurrentComponentHistory", "send");
          break;
        case "star_grey":
          await this.changeSingleMark(_.cloneDeep(this.currentReadItemInfo));
          break;
        case "trash":
          await this.deleteEmail(_.cloneDeep(this.currentReadItemInfo));
          break;
        case "forward":
          this.$store.commit("setOpenEmailSendView", {
            open: true,
            type: "forward",
            replyItemInfo: this.currentReadItemInfo,
          });
          this.$store.commit("setEmailCurrentComponentHistory", "send");
          break;
        case "recall_spam":
          await this.recallSpam(_.cloneDeep(this.currentReadItemInfo));
          break;
        case "spam":
          await this.markAsSpam(_.cloneDeep(this.currentReadItemInfo));
          break;
        case "download":
          this.downloadEML();
          break;
        case "edit":
          this.editEmail();
          break;
      }
    },
    // 下载EML
    async downloadEML() {
      this.htmlLoading = true;
      const password = await this.globalVerifyEmailPassword(
        this.currentEmailAccount,
        false
      );
      const createdToken = {
        u: this.currentEmailAccount.mailAddress,
        p: password,
      };
      let params = {
        uid: this.currentReadItemInfo.uid,
        folder: this.currentReadItemInfo.folder_name,
        fileName: this.currentReadItemInfo.subject,
        mailboxId: this.currentEmailAccount.mailboxId,
        individualAesToten: JSON.stringify(createdToken),
      };
      const result = await exportEmlFile(params);
      if (result) {
        const arrayBuffer = new TextEncoder().encode(result);
        const fileBlob = new Blob([arrayBuffer], { type: "message/rfc822" });
        const fileUrl = URL.createObjectURL(fileBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.download = `${this.currentReadItemInfo.subject}.eml`;
        downloadLink.click();
        URL.revokeObjectURL(fileUrl);
        downloadLink.remove();
      }
      this.htmlLoading = false;
    },
    // 编辑当前邮件
    editEmail() {
      this.$store.commit("setOpenEmailSendView", {
        open: true,
        type: "edit",
        editItemInfo: this.currentReadItemInfo,
      });
      this.$store.commit("setEmailCurrentComponentHistory", "send");
    },
    async markAsSpam(item) {
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("isMarkedAsSpam"),
        showDialog: true,
        color: "#F39945",
        confirmFn: async () => {
          const toFromFolder = this.currentEmailAccount.subMenu.filter(
            (item) => item.name == "spam"
          )[0].folder_name;
          let params = {
            fromFolder: item.folder_name,
            toFromFolder: toFromFolder,
            uids: item.uid,
          };
          this.$store.commit("setReadingViewLoading", true);
          const result = await moveEmails(params);
          this.$store.commit("setReadingViewLoading", false);
          if (result.code == 200) {
            this.$store.commit("setRefreshEmailSubMenu", true);
            try {
              await deleteEmailsByUniqueKey([item.uniqueIdentifier]);
            } catch (error) {
              console.log(error);
            }
            await this.$forceUpdate();
            this.goBack();
          } else if (result.code == 501) {
            await this.deleteNonExistentData(result);
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        },
      });
    },
    async recallSpam(item) {
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("unMarkAsSpam"),
        showDialog: true,
        color: "#F39945",
        confirmFn: async () => {
          const toFromFolder = this.currentEmailAccount.subMenu.filter(
            (item) => item.name == "inbox"
          )[0].folder_name;
          let params = {
            fromFolder: item.folder_name,
            toFromFolder: toFromFolder,
            uids: item.uid,
          };
          this.$store.commit("setReadingViewLoading", true);
          const result = await moveEmails(params);
          this.$store.commit("setReadingViewLoading", false);
          if (result.code == 200) {
            this.$store.commit("setRefreshEmailSubMenu", true);
            try {
              await deleteEmailsByUniqueKey([item.uniqueIdentifier]);
            } catch (error) {
              console.log(error);
            }
            await this.$forceUpdate();
            this.goBack();
          } else if (result.code == 501) {
            await this.deleteNonExistentData(result);
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        },
      });
    },
    async deleteEmail(item) {
      if (this.completelyDelete) {
        this.$confirm(
          <div
            style={{
              width: "100%",
              display: "flex",
              "align-items": "center",
              "justify-content": "flex-start",
            }}
          >
            <img
              style={{
                width: "24px",
                height: "24px",
                "margin-right": "8px",
                "flex-shrink": "0",
              }}
              src={require("../../../../../../assets/images/email/send/tipsIcon.png")}
            />
            <div style={{ flex: 1, "text-align": "left" }}>
              {this.$t("completelyDeleteTips")}
            </div>
          </div>,
          this.$t("tips"),
          {
            customClass: "emailOperationTips",
            confirmButtonText: " ",
            center: true,
            showClose: true,
            confirmButtonClass: "el-icon-check my-confirm-btn",
            showCancelButton: false,
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            let params = {
              folder: this.currentSubMenuInfo.folder_name,
              flag: "DELETED",
              set: true,
              uids: item.uid,
            };
            this.$store.commit("setReadingViewLoading", true);
            const result = await markEmails(params);
            this.$store.commit("setReadingViewLoading", false);
            if (result.code == 200) {
              try {
                await deleteEmailsByUniqueKey([item.uniqueIdentifier]);
              } catch (error) {
                console.log(error);
              }
              this.goBack();
            } else if (result.code == 501) {
              await this.deleteNonExistentData(result);
            } else {
              this.$message({
                type: "error",
                message: result.message,
              });
            }
          })
          .catch(() => {});
      } else if (this.currentSubMenuInfo.name == "mark") {
        this.deleteEmailInMark(item);
      } else {
        const toFromFolder = this.currentEmailAccount.subMenu.filter(
          (item) => item.name == "trash"
        )[0].folder_name;
        let params = {
          fromFolder: item.folder_name || this.currentSubMenuInfo.folder_name,
          toFromFolder: toFromFolder,
          uids: item.uid,
        };
        this.$store.commit("setReadingViewLoading", true);
        const result = await moveEmails(params);
        this.$store.commit("setReadingViewLoading", false);
        if (result.code == 200) {
          this.$store.commit("setRefreshEmailSubMenu", true);
          try {
            await deleteEmailsByUniqueKey([item.uniqueIdentifier]);
          } catch (error) {
            console.log(error);
          }
          await this.$forceUpdate();
          this.goBack();
        } else if (result.code == 501) {
          await this.deleteNonExistentData(result);
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    // 将星标邮件删除
    async deleteEmailInMark(item) {
      // 获取邮件原始文件夹
      const originalFolder = this.currentEmailAccount.subMenu.filter(
        (menu) => menu.folder_name == item.folder_name
      )[0];
      // 需要先将星标邮件的原始文件夹内的星标去除
      let params = {
        folder: item.folder_name,
        flag: "FLAGGED",
        set: false,
        uids: item.uid,
      };
      this.$store.commit("setReadingViewLoading", true);
      const result = await markEmails(params);
      this.$store.commit("setReadingViewLoading", false);
      if (result.code == 200) {
        try {
          await updateEmailStarredByUniqueKey(item.uniqueIdentifier, "cancel");
        } catch (error) {
          console.log(error);
        }
      } else if (result.code == 501) {
        await this.deleteNonExistentData(result);
        return;
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        return;
      }
      if (originalFolder.name != "trash") {
        const toFromFolder = this.currentEmailAccount.subMenu.filter(
          (item) => item.name == "trash"
        )[0].folder_name;
        let params = {
          fromFolder: originalFolder.folder_name,
          toFromFolder: toFromFolder,
          uids: item.uid,
        };
        this.$store.commit("setReadingViewLoading", true);
        const result = await moveEmails(params);
        this.$store.commit("setReadingViewLoading", false);
        if (result.code == 200) {
          // 将被删除的邮件删除
          try {
            await deleteEmailsByUniqueKey([item.uniqueIdentifier]);
          } catch (error) {
            console.log(error);
          }
          this.goBack();
        } else if (result.code == 501) {
          await this.deleteNonExistentData(result);
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    // 删除不存在的数据
    async deleteNonExistentData(result) {
      if (result.message != "") {
        const uids = result.message.split(",");
        if (uids.length != 0) {
          try {
            // 获取当前uids对应的数据
            const checkedLists = uids.map((uid) => {
              return `${this.currentEmailAccount.mailAddress}/${this.currentSubMenuInfo.folder_name}/${uid}`;
            });
            await deleteEmailsByUniqueKey(checkedLists);
          } catch (error) {
            console.log(error);
          }
        }
      }
      this.$message({
        type: "error",
        message: this.$t("emailDoesNotExist"),
      });
    },
    // 更改单个邮件的星标状态
    async changeSingleMark(item) {
      let params = {
        folder: item.folder_name || this.currentSubMenuInfo.folder_name,
        flag: "FLAGGED",
        set: !item.mark,
        uids: item.uid,
      };
      this.$store.commit("setReadingViewLoading", true);
      const result = await markEmails(params);
      if (result.code == 200) {
        try {
          let action = item.mark ? "cancel" : "mark";
          await updateEmailStarredByUniqueKey(item.uniqueIdentifier, action);
        } catch (error) {
          console.log(error);
        }
        const newCurrentReadInfo = _.cloneDeep(this.currentReadItemInfo);
        newCurrentReadInfo.mark = !newCurrentReadInfo.mark;
        if (item.mark) {
          newCurrentReadInfo.flags = newCurrentReadInfo.flags
            .replace("FLAGGED", "")
            .trim();
          newCurrentReadInfo.starred = 0;
        } else {
          newCurrentReadInfo.flags = this.recombineFlags(
            newCurrentReadInfo.flags,
            "FLAGGED"
          );
          newCurrentReadInfo.starred = 1;
        }
        this.$store.commit("setCurrentReadItemInfo", newCurrentReadInfo);
        await this.$forceUpdate();
      } else if (result.code == 501) {
        await this.deleteNonExistentData(result);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.$store.commit("setReadingViewLoading", false);
    },
    // 重组flags
    recombineFlags(oldFlags, newFlags) {
      let handlerFlags = oldFlags;
      if (handlerFlags.indexOf(newFlags) == -1) {
        if (handlerFlags.length == 0) {
          handlerFlags = `${newFlags}`;
        } else {
          handlerFlags = `${newFlags},${handlerFlags}`;
        }
      }
      return handlerFlags.replace(/,+/g, ",").replace(/(^,)|(,$)/g, "");
    },
    sendAnEmail(email) {
      this.$store.commit("setOpenEmailSendView", {
        open: true,
        type: "send",
        emailAddress: email,
      });
      this.$store.commit("setEmailCurrentComponentHistory", "send");
    },
    setContactData(email) {
      const contact = this.contactsList.find((contact) => {
        return contact.email == email;
      });
      if (!contact) {
        this.$store.commit("setContactDialogData", {
          dialog: true,
          type: "add",
          data: {
            email: email,
          },
          fromType: "readMail",
        });
      } else {
        this.$store.commit("setContactDialogData", {
          dialog: true,
          type: "edit",
          data: contact,
          fromType: "readMail",
        });
      }
    },
    prePage() {
      console.log("上一个");
    },
    nextPage() {
      console.log("下一个");
    },
    goBack() {
      this.$store.commit("setEmailHistoryComponentGoBack");
    },
  },
};
</script>
<style lang="stylus">
.readEmailBox
  .descriptionBox
    width: 100%;
    font-size: 16px;
    a
      color: blue;
      text-decoration: underline;
</style>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.readEmailBox
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .functionButtonArea
    width: 100%;
    height: 50px;
    padding: 0 16px 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    .leftBox
      height: 20px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .buttonBox
        width: 20px;
        margin-right: 30px;
        .backStyle
          font-size: 20px;
          font-weight: 700;
          color: #292D32;
          cursor: pointer;
    .rightBox
      height: 20px;
      .operationPaging
        width: 56px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button
          width: 20px;
          height: 20px;
          .iconStyle
            font-size: 20px;
  .emailContentArea
    width: 100%;
    min-height: 0;
    flex: 1;
    padding: 0 16px;
    .emailContent
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      .emailHeader
        width: 100%;
        max-height: 159px;
        flex-shrink: 0;
        border-bottom: 1px solid #D1D1D1;
        padding: 20px 24px 12px 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .subTitle
          width: 100%;
          height: 25px;
          color: #292D32;
          font-size: 18px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 6px;
          .titleText
            max-width: calc(100% - 28px)
            min-width: 0;
            height: 100%;
            line-height: 25px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        .subInfoBox
          width: 100%;
          flex: 1;
          min-height: 0;
          overflow-x: hidden;
          overflow-y: auto;
          scrollbar-light();
          .commonLine
            width: 100%;
            min-height: 22px;
            margin-bottom: 2px;
            color: #858585;
            font-size: 14px;
            word-break: break-all;
            padding:0 5px 0 0;
            .fromName
              font-size: 16px;
              color: #F39945;
      .ICSFileBox
        width: 100%;
        overflow: hidden;
        user-select: auto;
        .ICSCardInfo
          color: #000000;
          font-size: 16px;
          width: 100%;
          border-bottom: 1px solid #D1D1D1;
          padding: 10px 24px 10px 24px;
          .header
            width: 100%;
            .ICSImg
              width: 16px;
              vertical-align: -2px;
              margin-right: 5px;
          .buttons
            width: 100%;
            padding-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .buttonStyle
              width: 70px;
              height: 35px;
              border-radius: 5px;
              margin-right: 10px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              .iconStyle
                font-size: 24px;
                color: #FFFFFF;
            .button_result
              height: auto !important;
              overflow: hidden;
              border-radius: 0;
              img
                cursor default;
            .button_1
              background: #52BD68;
            .button_2
              background: #E42B2B;
        .ICSContent
          width: 100%;
          border-bottom: 1px solid #D1D1D1;
          padding: 20px 24px;
          .summaryBox
            width: 100%;
            color: #000000;
            font-size: 14px;
            text-align: left;
          .descriptionBox
            width: 100%;
            font-size: 16px;
          .locationBox
            width: 100%;
            font-size: 14px;
            padding-top: 10px;
            color: rgba(0, 0, 0, 0.7);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .iconStyle
              font-size: 16px;
              color: rgba(0, 0, 0, 0.7);
      .emailAttachment
        width: 100%;
        flex-shrink: 0;
        border-bottom: 1px solid #D1D1D1;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 30px 10px 30px;
        .attachmentBox
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(360px, 360px));
          grid-gap: 5px 15px;
          overflow: hidden;
        .displayFiles
          width: 100%;
          height: 40px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .displayImg
            width: 12px;
            flex-shrink: 0;
            height: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: flex-start;
            img
              width: 12px;
              height: 12px;
              transition: transform 0.2s ease-in-out;
          .displayText
            flex: 1;
            min-width: 0;
            cursor: pointer;
            margin-left: 10px;
            color: #858585;
            font-size: 14px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
      // 邮件内容样式
      .read_view
        flex: 1;
        width: 100%;
        min-height: 0;
        padding: 16px 25px;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-light();
        #email_iframe
          width: 100%;
          height: 100%;
          overflow: hidden;
</style>

<style lang="stylus">
.emailFromOperatePopover
  min-width: 100px;
  height: 126px;
  padding: 0;
  border-radius: 4px;
  .operatetitle
    min-width: 100%;
    height: 30px;
    padding: 0 16px;
    font-size: 16px;
    color: #858585;
    line-height: 30px;
    text-align: center;
  .operateBox
    width: 100%;
    height: 96px;
    overflow: hidden;
    .buttonStyle
      width: 100%;
      height: 50%;
      cursor: pointer;
      background: #FFFFFF;
      font-size: 14px;
      color: #292D32;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: background .3s,color .3s;
      line-height: 48px;
      text-align: center;
      padding: 0 10px;
      &:hover
        background: #F0F0F0;
        color: #000000;
</style>
