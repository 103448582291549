var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.uploadLoading,
          expression: "uploadLoading"
        }
      ],
      staticClass: "tiptap_editor",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "#FFFFFF"
      }
    },
    [
      _c("el-tiptap", {
        attrs: {
          extensions: _vm.dynamicExtensions,
          charCounterCount: false,
          lang: _vm.localLang,
          width: "100%",
          height: "100%"
        },
        on: {
          onInit: _vm.onInit,
          onFocus: _vm.onFocus,
          onBlur: _vm.onBlur,
          onPaste: _vm.onPaste,
          onTransaction: _vm.onTransaction,
          onUpdate: _vm.onEditorUpdate
        },
        scopedSlots: _vm._u(
          [
            {
              key: "footer",
              fn: function() {
                return [_vm._t("footer")]
              },
              proxy: true
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }