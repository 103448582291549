<template>
  <div
    class="tiptap_editor"
    v-loading="uploadLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#FFFFFF"
  >
    <el-tiptap
      v-model="content"
      :extensions="dynamicExtensions"
      :charCounterCount="false"
      :lang="localLang"
      width="100%"
      height="100%"
      @onInit="onInit"
      @onFocus="onFocus"
      @onBlur="onBlur"
      @onPaste="onPaste"
      @onTransaction="onTransaction"
      @onUpdate="onEditorUpdate"
    >
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </el-tiptap>
  </div>
</template>
<script>
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  History, // 历史记录
  Blockquote, // 引用
  TextAlign, // 文案对齐
  Indent, // 缩进
  HorizontalRule, // 分割线
  TextColor, // 文字颜色
  TextHighlight, //文字背景高亮
  Preview, //预览
  Print, // 打印
  SelectAll, // 全选
  FontType, // 字体
  FontSize, // 字体大小
  Image, // 图片
  Link, // 链接
  Table, // 表格
  TableHeader, // 表格头
  TableCell, // 表格单元格
  TableRow, // 表格行
  FormatClear,
} from "element-tiptap";
import AttachmentExtension from "./extensions/attachment/index";
import UploadImageExtension from "./extensions/uploadImages/index";
import PxFontSize from "./extensions/fontSize/PxFontSize";
import { columnResizing } from "prosemirror-tables";
import { handlerUploadFileToQiniu } from "@/api/qiniu";
import UUID from "uuidjs";
export default {
  name: "Eleditor",
  data() {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new AttachmentExtension(
          this.uploadAttachment,
          this.addOriginalAttachment
        ),
        new FontType(),
        new PxFontSize(),
        new TextColor(),
        new TextHighlight(),
        new History(),
        new Heading({ level: 5 }),
        new Bold(),
        new Underline(),
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Blockquote(),
        new TextAlign(),
        new Indent(),
        new HorizontalRule(),
        new Table({
          resizable: true,
        }),
        new TableHeader(), // 表格头
        new TableCell(), // 表格单元格
        new TableRow(), // 表格行
        new Image(),
      ],
      content: ``,
      editorLangs: ["en", "zh", "ru", "de", "ko", "es", "fr", "pl", "pt_br"],
      timer: null,
      selector: null,
      editor: null,
      uploadLoading: false,
      observer: null,
    };
  },
  props: {
    editorModel: {
      type: String,
      default: "",
    },
    extensionsProps: {
      type: String,
      default: "default",
    },
  },
  watch: {
    editorModel: {
      handler(val, old) {
        if (val) {
          this.content = val;
        } else {
          this.content = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    localLang() {
      let localLang = "en";
      const messages = this.getGlobalLanguages();
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      const handleCode = messages[localeLang].encode;
      this.editorLangs.map((item) => {
        if (item.indexOf(handleCode) != -1) {
          localLang = item;
        }
      });
      return localLang;
    },
    dynamicExtensions() {
      if (this.extensionsProps == "default") {
        return this.extensions;
      }
      if (this.extensionsProps == "simple") {
        return [
          new Doc(),
          new Text(),
          new Paragraph(),
          new FontType(),
          new TextHighlight(),
          new History(),
          new Bold(),
          new Underline(),
          new Italic(),
          new Strike(),
          new ListItem(),
          new BulletList(),
          new OrderedList(),
          new UploadImageExtension(this.uploadMelinkedImage),
        ];
      }
    },
  },
  beforeDestroy() {
    if (this.selector) {
      this.selector.removeEventListener("click", this.bind);
    }
  },
  methods: {
    bindingEvents() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.selector = document.getElementsByClassName(
          "el-tiptap-editor__content"
        )[0];
        this.selector.addEventListener("click", this.bind);
        this.createObserver();
      }, 0);
    },
    uploadMelinkedImage() {
      this.$emit("uploadMelinkedImage");
    },
    uploadAttachment() {
      this.$emit("uploadAttachment");
    },
    addOriginalAttachment() {
      this.$emit("addOriginalAttachment");
    },
    bind() {
      this.editor.focus();
    },
    createObserver() {
      if (this.selector) {
        try {
          this.observer = new MutationObserver((mutations) => {
            if (
              mutations.length > 0 &&
              mutations.find(
                (mutation) =>
                  mutation.type === "childList" ||
                  mutation.type === "characterData"
              )
            ) {
              if (this.selector && this.selector.children[0]) {
                this.htmlChange(this.selector.children[0].innerHTML);
              }
            }
          });
          this.observer.observe(this.selector, {
            childList: true,
            subtree: true,
            characterData: true,
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    htmlChange: _.throttle(
      async function(str) {
        let clearLineHeight = false;
        const LINE_HEIGHT_PATTERN = /line-height:\s*\d+%?/gi;
        str = str.replace(LINE_HEIGHT_PATTERN, (match) => {
          if (match) {
            clearLineHeight = true;
            return "";
          }
        });
        if (clearLineHeight) {
          this.selector.children[0].innerHTML = str;
        }
        const html = this.editor.getHTML();
        this.$emit("change", html);
      },
      100,
      { leading: false, trailing: true }
    ),
    onInit({ editor }) {
      this.editor = editor;
      this.bindingEvents();
      this.$emit("ready", editor);
    },
    onFocus({ editor }) {
      this.$emit("focus", editor);
    },
    onBlur({ editor }) {
      this.$emit("blur", editor);
    },
    async onEditorUpdate(text) {
      if (text.indexOf("data:image") != -1) {
        this.pasteImg(text);
      }
    },
    async pasteImg(s) {
      return new Promise(async (resolve) => {
        let pattern = /"data:image\/.*?;base64,([^"]+)"/g;
        const base64TextArr = [];
        s = s.replace(pattern, function(match) {
          const uuid = UUID.generate();
          base64TextArr.push({
            uuid,
            base64: match.split(",")[1].replace(/^"|"$/g, ""),
          });
          return `*${uuid}*`;
        });
        console.log(`当前捕获的base64TextArr:${base64TextArr}`);
        if (base64TextArr.length > 0) {
          this.uploadLoading = true;
          for (let i = 0; i < base64TextArr.length; i++) {
            const item = base64TextArr[i];
            if (item.base64) {
              let file = new window.File(
                [this.base64Url(item.base64)],
                "base64Tofile",
                {
                  type: "image/png",
                }
              );
              let files = {
                file: file,
              };
              item.path = await this.uploadImage(files);
            }
          }
          base64TextArr.forEach((item) => {
            if (item.path) {
              s = s.replace(`*${item.uuid}*`, `"${item.path}"`);
            }
          });
          this.content = s;
          this.uploadLoading = false;
        }
        resolve();
      });
    },
    // 上传内容中的图片
    uploadImage(files) {
      return new Promise((resolve, reject) => {
        handlerUploadFileToQiniu(files, (item) => {
          resolve(item.path);
        });
      });
    },
    // 处理base64图片为blob
    base64Url(base64) {
      let base64bytes = window.atob(base64);
      let ab = new ArrayBuffer(base64bytes.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < base64bytes.length; i++) {
        ia[i] = base64bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/png" });
    },
    onPaste() {},
    onTransaction() {},
  },
};
</script>
<style lang="stylus" scoped>
.tiptap_editor
  width: 100%;
  height: 100%;
</style>
