var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "markEmailsList" },
    [
      _c("PublicList", {
        attrs: {
          showType: _vm.type,
          list: _vm.list,
          page: _vm.page,
          pageSize: _vm.pageSize,
          totalPage: _vm.totalPage,
          listLoading: _vm.listLoading
        },
        on: {
          prePage: _vm.prePage,
          nextPage: _vm.nextPage,
          jumpPage: _vm.jumpPage,
          retrieveOnDemand: _vm.retrieveOnDemand,
          refreshTotalPage: _vm.refreshTotalPage,
          handlerAnchorMailPage: _vm.handlerAnchorMailPage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }