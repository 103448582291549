<template>
  <div
    class="emailBox"
    :style="{
      'padding-left': IMWindowSize == 'full' ? '56px' : '',
    }"
  >
    <div
      class="emailContent"
      v-loading="emailModuleLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255,255,255,0.5)"
      v-resize-ob="handlerSize"
    >
      <template v-if="showMainView">
        <div
          class="treeMenu"
          :style="{ width: showTreeMenu ? `${treeWidth}px` : '0px' }"
        >
          <!-- 邮箱存在即显示 -->
          <TreeMenu
            ref="TreeMenu"
            v-if="emailAccounts.length != 0"
            :subMenu="subMenu"
          />
        </div>
        <div
          class="contentView"
          v-loading="mailsListViewLoading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255,255,255,0.5)"
        >
          <template v-if="showContentHeader">
            <div class="emailHeader">
              <div class="titleBox">
                <span class="titleFonts">
                  {{ currentTitle }}
                  <span class="titleCount" v-if="currentMessageCount != 0">{{
                    currentMessageCount
                  }}</span>
                </span>
                <span class="accountInfo" v-if="currentEmailAccount">
                  {{ currentEmailAccount.name }}({{
                    currentEmailAccount.mailAddress
                  }})
                </span>
              </div>
              <div class="headerOperation">
                <div class="operationBox">
                  <div
                    class="migrating_button"
                    v-if="needMigrating"
                    @click.stop="startMigrating"
                  >
                    <div class="button_box">
                      {{ $t("mailboxMigrate") }}
                    </div>
                    <div class="triangle"></div>
                  </div>
                  <el-tooltip
                    effect="dark"
                    :content="$t('contacts')"
                    placement="top"
                  >
                    <div class="button" @click.stop="showContacts" v-waves>
                      <el-image
                        style="height: 17px"
                        :src="
                          require('../../../../../assets/images/email/contacts.png')
                        "
                        :fit="'cover'"
                      ></el-image>
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    :content="$t('search')"
                    placement="top"
                  >
                    <div class="button" @click.stop="geSearch" v-waves>
                      <el-image
                        style="height: 17px"
                        :src="
                          require('../../../../../assets/images/email/search.png')
                        "
                        :fit="'cover'"
                      ></el-image>
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    :content="$t('setting')"
                    placement="top"
                  >
                    <div class="button" @click.stop="goSetting" v-waves>
                      <el-image
                        style="height: 17px"
                        :src="
                          require('../../../../../assets/images/email/setting.png')
                        "
                        :fit="'cover'"
                      ></el-image>
                    </div>
                  </el-tooltip>
                </div>
                <div class="windowChangeBox">
                  <span
                    class="imelink-miniscreen"
                    v-show="this.IMWindowSize != 'mini'"
                    @click="changeScreen('mini')"
                  >
                    <SizeButton :buttonType="'minimize'"></SizeButton>
                  </span>
                  <span
                    class="imelink-fullscreen"
                    v-show="this.IMWindowSize == 'medium'"
                    @click="changeScreen('full')"
                  >
                    <SizeButton></SizeButton>
                  </span>
                  <span
                    class="imelink-fullscreen"
                    v-show="this.IMWindowSize == 'full'"
                    @click="changeScreen('medium')"
                  >
                    <SizeButton :buttonType="'full'"></SizeButton>
                  </span>
                </div>
              </div>
            </div>
          </template>
          <div class="view">
            <div class="emptyBlock" v-if="currentComponent == 'main'">
              <el-image
                style="width: 120px; height: 120px"
                :src="
                  require('../../../../../assets/images/email/treeMenu/empty.png')
                "
                :fit="'cover'"
              ></el-image>
            </div>
            <Inbox
              v-if="currentComponent == 'inbox'"
              @listIsRefreshed="listIsRefreshed"
              :migrating_email="migrating_email"
            />
            <MarkEmail
              v-if="currentComponent == 'mark'"
              @listIsRefreshed="listIsRefreshed"
            />
            <SentMails
              v-if="currentComponent == 'sent'"
              @listIsRefreshed="listIsRefreshed"
              :migrating_email="migrating_email"
            />
            <Drafts
              v-if="currentComponent == 'drafts'"
              @listIsRefreshed="listIsRefreshed"
              :migrating_email="migrating_email"
            />
            <Trash
              v-if="currentComponent == 'trash'"
              @listIsRefreshed="listIsRefreshed"
            />
            <Spam
              v-if="currentComponent == 'spam'"
              @listIsRefreshed="listIsRefreshed"
            />
          </div>
          <transition name="fade">
            <div class="contactsView" v-if="contactsView">
              <ContactsView
                :showHeader="false"
                :showGoBack="true"
                @goBack="hiddenContacts"
              />
            </div>
          </transition>
        </div>
      </template>
      <template v-if="currentComponent == 'send'">
        <SendMail ref="sendMail" />
      </template>
      <template v-if="currentMaskComponent == 'read'">
        <div
          class="readComponent"
          v-loading="readingViewLoading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255,255,255,0.5)"
        >
          <ReadEmailBox />
        </div>
      </template>
      <template v-if="currentComponent == 'bind'">
        <BindAccount />
      </template>
      <template v-if="currentComponent == 'setting'">
        <EmailSetting />
      </template>
    </div>
  </div>
</template>
<script>
import SizeButton from "@/newVersion/components/im_size_button";
import TreeMenu from "./treeMenu";
import SendMail from "./sendMail";
import Inbox from "./inbox";
import ReadEmailBox from "./readEmail";
import MarkEmail from "./markEmail";
import SentMails from "./sentMails";
import Drafts from "./drafts";
import Trash from "./trash";
import Spam from "./spam";
import BindAccount from "./bindAccount";
import EmailSetting from "./emailSetting";
import { getEmailConfig } from "@/api/newVersion/melinked_email";
import { queryTransferResult } from "@/api/newVersion/team";
import { deleteLocalEmailsNotInAccountEmailList } from "@/api/indexDB/api/email_api";
import ContactsView from "../../view/contacts-view";

export default {
  components: {
    SizeButton,
    TreeMenu,
    SendMail,
    Inbox,
    ReadEmailBox,
    MarkEmail,
    SentMails,
    Drafts,
    Trash,
    Spam,
    BindAccount,
    EmailSetting,
    ContactsView,
  },
  computed: {
    IMWindowSize() {
      return this.$store.state.imStore.IMWindowSize;
    },
    currentComponent() {
      return this.$store.state.melinked_email.currentComponent;
    },
    currentMaskComponent() {
      return this.$store.state.melinked_email.currentMaskComponent;
    },
    showMainView() {
      return [
        "main",
        "inbox",
        "mark",
        "sent",
        "drafts",
        "trash",
        "spam",
      ].includes(this.currentComponent);
    },
    openEmailView() {
      return this.$store.state.melinked_email.openEmailView;
    },
    emailModuleLoading() {
      return this.$store.state.melinked_email.emailModuleLoading;
    },
    emailAccounts() {
      return this.$store.state.melinked_email.emailAccounts;
    },
    currentEmailAccount() {
      return this.$store.state.melinked_email.currentEmailAccount;
    },
    migratingDialog() {
      return this.$store.state.melinked_email.migratingDialog;
    },
    // 当前选中的小菜单
    currentSubMenuInfo() {
      return this.$store.state.melinked_email.currentSubMenuInfo;
    },
    needMigrating() {
      let needMigrating = false;
      if (
        this.migrating_email &&
        this.migrating_email.migrate &&
        (this.migrating_email.pulledStatus === "" ||
          this.migrating_email.pulledStatus == -1) &&
        this.currentEmailAccount.bindCondition == 1
      ) {
        needMigrating = true;
      }
      return needMigrating;
    },
    mailsListViewLoading() {
      return (
        this.$store.state.melinked_email.mailsListViewLoading &&
        !this.emailModuleLoading
      );
    },
    readingViewLoading() {
      return (
        this.$store.state.melinked_email.readingViewLoading &&
        !this.emailModuleLoading
      );
    },
    currentTitle() {
      let title = "";
      const findItem = this.subMenu.find(
        (sub) => sub.name == this.currentComponent
      );
      if (findItem) {
        title = findItem.title;
      }
      return title;
    },
  },
  watch: {
    currentEmailAccount: {
      handler(val, old) {
        if (val && (!old || val.mailAddress !== old.mailAddress)) {
          this.checkMigrating(val);
        }
      },
      deep: true,
    },
    // 迁移邮箱弹窗关闭
    migratingDialog: {
      async handler(val, old) {
        if (!val && val != old) {
          await this.getEmailTransferResult();
        }
      },
      deep: true,
    },
    currentComponent: {
      async handler() {
        if (this.contactsView) {
          this.contactsView = false;
        }
      },
      deep: true,
    },
    currentTitle: {
      async handler(val, old) {
        if (val != old && this.currentSubMenuInfo) {
          this.currentMessageCount = this.currentSubMenuInfo.messageCount;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      treeWidth: 190,
      migrate_emails: [],
      migrating_email: null,
      contactsView: false,
      showTreeMenu: true,
      showContentHeader: true,
      // folder_name：远程服务器文件夹名
      subMenu: [
        {
          icon: require(`../../../../../assets/images/email/treeMenu/edit.png`),
          title: this.$t("writeAnEmail"),
          iconWidth: 20,
          iconHeight: 16,
          name: "send",
          index: 0,
        },
        {
          icon: require(`../../../../../assets/images/email/treeMenu/sms.png`),
          title: this.$t("INBOX"),
          name: "inbox",
          iconWidth: 20,
          iconHeight: 20,
          folder_name: "INBOX",
          index: 1,
        },
        {
          icon: require(`../../../../../assets/images/email/treeMenu/mark.png`),
          title: this.$t("markMail"),
          name: "mark",
          iconWidth: 20,
          iconHeight: 20,
          folder_name: "Archive",
          index: 2,
        },
        {
          icon: require(`../../../../../assets/images/email/treeMenu/send.png`),
          title: this.$t("hasBeenSent"),
          name: "sent",
          iconWidth: 20,
          iconHeight: 20,
          folder_name: "Sent",
          index: 3,
        },
        {
          icon: require(`../../../../../assets/images/email/treeMenu/document.png`),
          title: this.$t("drafts"),
          name: "drafts",
          iconWidth: 20,
          iconHeight: 20,
          folder_name: "Drafts",
          index: 4,
        },
        {
          icon: require(`../../../../../assets/images/email/treeMenu/trash.png`),
          title: this.$t("deletedMail"),
          name: "trash",
          iconWidth: 20,
          iconHeight: 20,
          folder_name: "Trash",
          index: 5,
        },
        {
          icon: require(`../../../../../assets/images/email/treeMenu/spam.png`),
          title: this.$t("spam"),
          name: "spam",
          iconWidth: 20,
          iconHeight: 20,
          folder_name: "Spam",
          index: 6,
        },
      ],
      currentMessageCount: 0,
    };
  },
  async mounted() {
    await this.getEmailConfig();
    await this.getBindEmailList();
    // 获取通讯录邮箱联系人列表
    await this.updateLocalEmailContacts();
    await this.getEmailTransferResult();
    if (this.emailAccounts.length > 0) {
      // 清理本地垃圾数据
      await deleteLocalEmailsNotInAccountEmailList(
        this.emailAccounts.map((item) => item.mailAddress)
      );
    }
    // 如果当前账号没有绑定过的邮箱，则默认进去绑定页面
    if (
      this.emailAccounts.length == 0 ||
      (this.openEmailView && this.openEmailView.type == "bind")
    ) {
      this.$store.commit("setEmailCurrentComponentHistory", "bind");
    } else {
      this.$store.commit("setEmailCurrentComponentHistory", "main");
      await this.$nextTick();
      // 跳转到需要跳转的界面,默认使用第一个展开的邮箱
      if (this.openEmailView) {
        this.$store.commit(
          "setEmailCurrentComponentHistory",
          this.openEmailView.type
        );
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("setEmailCurrentComponentHistory", null);
  },
  methods: {
    // 判断当前邮箱是否有迁移权限
    checkMigrating(email) {
      this.migrating_email = null;
      this.migrate_emails.forEach((item) => {
        if (item.mailAddress == email.mailAddress) {
          this.migrating_email = item;
        }
      });
    },
    // 开始迁移
    async startMigrating() {
      await this.getEmailTransferResult();
      if (this.needMigrating) {
        this.$store.commit("setMigratingDialog", true);
        this.$store.commit(
          "setMigratingDialogAddress",
          this.migrating_email.mailAddress
        );
      } else {
        this.$message({
          type: "error",
          message: this.$t("migrate_tips_2"),
        });
      }
    },
    // 记录团队迁移邮箱列表
    async getEmailTransferResult() {
      const result = await queryTransferResult();
      if (result.code == 200) {
        this.migrate_emails = result.data.data;
        if (this.currentEmailAccount) {
          this.checkMigrating(this.currentEmailAccount);
        }
      } else {
        this.migrate_emails = [];
        this.migrating_email = null;
      }
    },
    // 获取邮箱配置信息
    async getEmailConfig() {
      if (!this.$store.state.melinked_email.emailConfig) {
        const result = await getEmailConfig();
        if (result.code == 200) {
          this.$store.commit("setEmailConfig", result.data.data);
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    handlerSize: _.throttle(async function(ob) {
      try {
        const proportion = 190 / 900;
        this.treeWidth = Math.floor(ob.width * proportion);
      } catch (e) {}
    }, 160),
    // 获取绑定过的邮箱列表
    async getBindEmailList() {
      this.$store.commit("setEmailModuleLoading", true);
      await this.globalGetBindEmailList();
      this.$store.commit("setEmailModuleLoading", false);
    },
    // 前往通讯录
    showContacts() {
      this.contactsView = true;
    },
    // 关闭通讯录
    hiddenContacts() {
      this.contactsView = false;
    },
    // 前往搜索
    geSearch() {
      this.$emit("openSearch");
    },
    goSetting() {
      this.$store.commit("setEmailCurrentComponentHistory", "setting");
    },
    changeScreen(type) {
      if (!["full", "medium", "mini"].includes(type)) return;
      this.$store.commit("setIMWindowSize", type);
    },
    // 邮箱列表已刷新
    listIsRefreshed(type) {
      this.$refs.TreeMenu.listIsRefreshed(type);
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.emailBox
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  .emailContent
    width: 100%;
    flex: 1;
    min-height: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    .readComponent
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 9999;
      background: #FFFFFF;
    :deep .el-loading-mask
      .el-loading-spinner
        .el-icon-loading
          font-size: 30px;
    .treeMenu
      height: 100%;
      flex-shrink: 0;
      transition: width 0.5s;
      overflow: hidden;
    .contentView
      flex: 1;
      min-width: 0;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .emailHeader
        overflow: hidden;
        height: 48px !important;
        flex-shrink: 0;
        background: #FFFFFF;
        font-size: 16px;
        border-right: 1px solid #eee;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width 100%;
        .titleBox
          height: 100%;
          box-sizing: border-box;
          padding: 0 0 0 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          overflow: hidden;
          .titleFonts
            font-size: 16px;
            .titleCount
              margin-left: 2px;
          .accountInfo
            font-size: 14px;
            color: #65696E;
            margin-left: 10px;
        .headerOperation
          height: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .operationBox
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 30px;
            .migrating_button
              height: 24px;
              margin-right: 20px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              cursor: pointer;
              user-select: none;
              .button_box
                min-width: 60px;
                height: 100%;
                padding: 0 15px;
                background: #F39945;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #FFFFFF;
              .triangle
                width: 0;
                height: 0;
                margin-left: 7px;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 5px solid #000;
            .button
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: #D9D9D9;
              margin-right: 22px;
              cursor: pointer;
              user-select: none;
              display: flex;
              align-items: center;
              justify-content: center;
          .windowChangeBox
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            width: 54px;
            .imelink-miniscreen,.imelink-fullscreen,.imelink-fullscreen
              top: 12px;
      .contactsView
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
        z-index: 2001;
      .view
        width: 100%;
        min-height: 0;
        flex: 1;
        .emptyBlock
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
</style>
<style lang="stylus">
.emailOperationTips
  padding: 0;
  width: 360px;
  min-height: 320px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .el-message-box__header
    padding: 0;
    width: 100%;
    flex-shrink: 0;
    .el-message-box__title
      width: 100%;
      padding-top: 36px;
      text-align: center;
      font-size: 20px;
      color: #000000;
      font-weight: 700;
      flex-shrink: 0;
  .el-message-box__content
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: #000000;
  .el-message-box__btns
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    flex-shrink: 0;
    padding: 0 24px;
    .el-button
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-radius: 4px;
      background: #F39945;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px !important;
</style>
