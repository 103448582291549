var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "popoverContent",
      staticClass: "popoverContent",
      style: { width: `${_vm.popoverWidth}px` }
    },
    _vm._l(_vm.handlerList, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "popoverItem",
          class: item.selected ? "selectedStyle" : ""
        },
        [
          _c("div", {
            staticClass: "itemInner",
            domProps: { innerHTML: _vm._s(item.HTML) },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.clickItem(item)
              }
            }
          })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }