var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "emailSetting" },
    [
      _c("div", { staticClass: "settingHeader" }, [
        _c("span", {
          staticClass: "iconfont_Me icon-return iconStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.goBack.apply(null, arguments)
            }
          }
        }),
        _c(
          "div",
          {
            staticClass: "addAccount",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.addAccount.apply(null, arguments)
              }
            }
          },
          [
            _c("el-image", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                src: require("../../../../../../assets/images/email/bind/add.png"),
                fit: "cover"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "settingContent" },
        _vm._l(_vm.accounts, function(item, mainIndex) {
          return _c(
            "div",
            { key: item.originProviderId, staticClass: "itemBox" },
            [
              _c(
                "div",
                { staticClass: "emailAccount" },
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "36px",
                      height: "36px",
                      "flex-shrink": "0"
                    },
                    attrs: {
                      src: require("../../../../../../assets/images/email/bind/logo_icon.png"),
                      fit: "fill"
                    }
                  }),
                  _c("div", { staticClass: "showBox" }, [
                    item.name
                      ? _c("div", { staticClass: "accountTitle" }, [
                          _vm._v(_vm._s(item.name))
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mailAddress" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.mailAddress) +
                          "\n          "
                      )
                    ])
                  ]),
                  item.bindCondition == 2
                    ? _c(
                        "div",
                        {
                          staticClass: "deleteBox",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.deleteAccount(item)
                            }
                          }
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: require("../../../../../../assets/images/email/list/trash_operational.png"),
                              fit: "cover"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._l(item.userSecurityInformation, function(info, index) {
                return _c("div", { key: index, staticClass: "infoBox" }, [
                  _c(
                    "div",
                    { staticClass: "infoIcon" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: require(`../../../../../../assets/images/email/bind/item_${index}.png`),
                          fit: "cover"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "infoContent" }, [
                    _c(
                      "div",
                      { staticClass: "inputBox", attrs: { title: info.val } },
                      [
                        !info.showInput
                          ? [
                              info.type == "question"
                                ? [
                                    _c("span", {
                                      staticClass:
                                        "iconfont_Me icon-check iconStyle"
                                    })
                                  ]
                                : [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(info.val) +
                                        "\n              "
                                    )
                                  ]
                            ]
                          : _c("el-input", {
                              ref: `input-${mainIndex}-${index}`,
                              refInFor: true,
                              on: {
                                input: function($event) {
                                  return _vm.inputChange(
                                    $event,
                                    mainIndex,
                                    index
                                  )
                                }
                              },
                              nativeOn: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.changeNickname(mainIndex, index)
                                }
                              },
                              model: {
                                value: info.val,
                                callback: function($$v) {
                                  _vm.$set(info, "val", $$v)
                                },
                                expression: "info.val"
                              }
                            })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "editBox",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.edit(mainIndex, index)
                          }
                        }
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: require(`../../../../../../assets/images/email/bind/edit_2.png`),
                            fit: "cover"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              }),
              _c("div", { staticClass: "autographBox" }, [
                _c(
                  "div",
                  { staticClass: "infoIcon" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: require(`../../../../../../assets/images/email/bind/autograph.png`),
                        fit: "cover"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "boxInner",
                    style: {
                      borderBottom:
                        item.editorAutograph.sign == ""
                          ? "1px solid #F0F0F0"
                          : ""
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "operationArea" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "30px", height: "30px" },
                          attrs: {
                            src: require(`../../../../../../assets/images/email/bind/edit_2.png`),
                            fit: "cover"
                          },
                          on: {
                            click: function($event) {
                              return _vm.editAutograph(item)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    item.editorAutograph.sign || item.showEditor
                      ? _c(
                          "div",
                          {
                            staticClass: "showAndEditArea",
                            style: {
                              height: item.showEditor ? "400px" : "auto",
                              maxHeight:
                                item.editorAutograph.sign && !item.showEditor
                                  ? "210px"
                                  : ""
                            }
                          },
                          [
                            item.showEditor
                              ? [
                                  _c("Eleditor", {
                                    attrs: {
                                      editorModel: item.editorAutograph.sign,
                                      extensionsProps: "simple"
                                    },
                                    on: {
                                      uploadMelinkedImage: () => {
                                        _vm.uploadMelinkedImage(item)
                                      },
                                      ready: editor => {
                                        _vm.onEditorReady(editor, item)
                                      },
                                      change: html => {
                                        _vm.onEditorChange(html, item)
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "footer",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "editorFooter" },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "buttonStyle",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.cancelSign(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "iconfont_Me icon-x iconStyle"
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "buttonStyle",
                                                      attrs: {
                                                        type: "primary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.confirmSign(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "iconfont_Me icon-check iconStyle"
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ]
                              : [
                                  _c("div", {
                                    staticClass: "autographView",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        item.editorAutograph.sign
                                      )
                                    }
                                  })
                                ]
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            2
          )
        }),
        0
      ),
      _c("el-upload", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "uploadImageRef",
        attrs: {
          action: "/",
          accept: "image/*",
          multiple: false,
          "show-file-list": false,
          "http-request": _vm.handlerImageUpload,
          "before-upload": _vm.beforeImageUpload
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }