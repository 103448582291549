import { Extension } from "tiptap";
import AttachmentButton from "./Attachment.vue";

export default class AttachmentExtension extends Extension {
  constructor(fn, fn1) {
    super();
    this.fn = fn;
    this.fn1 = fn1;
  }
  menuBtnView({ isActive, commands, focus, editor }) {
    return {
      component: AttachmentButton,
      componentProps: {},
      componentEvents: {
        addAttachment: () => {
          if (this.fn) {
            this.fn();
          }
        },
        addOriginalAttachment: () => {
          if (this.fn1) {
            this.fn1();
          }
        },
      },
    };
  }
}
