var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bindEmailAccountView" }, [
    _vm.emailAccounts.length != 0
      ? _c("div", { staticClass: "viewHeader" }, [
          _c("span", {
            staticClass: "iconfont_Me icon-return iconStyle",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.goBack.apply(null, arguments)
              }
            }
          })
        ])
      : _vm._e(),
    _c("div", { staticClass: "viewContent" }, [
      _c("div", { staticClass: "centerBox" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.$t("emailBinding")) + "\n        "
            )
          ]),
          _c("div", { staticClass: "tips" }, [
            _vm._v("(" + _vm._s(_vm.$t("emailBindingTips")) + ")")
          ])
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "resize-ob",
                rawName: "v-resize-ob",
                value: _vm.windowResize,
                expression: "windowResize"
              }
            ],
            staticClass: "inputBox"
          },
          [
            _c(
              "el-form",
              {
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                    return (() => false).apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "userName" } },
                      [
                        _c(
                          "CommonInput",
                          { on: { inputChange: _vm.getEmailText } },
                          [
                            _c("el-image", {
                              style: _vm.imageIconStyle,
                              attrs: {
                                src: require("../../../../../../assets/images/email/loginDialog/email_not_selected.png"),
                                fit: "cover"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "emailPassword" } },
                      [
                        _c(
                          "CommonInput",
                          {
                            attrs: { passwordInput: true },
                            on: { inputChange: _vm.getPasswordText }
                          },
                          [
                            _c("span", {
                              staticClass:
                                "iconfont_Me icon-password-fill iconStyle",
                              style: _vm.fontIconStyle
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "buttonStyle",
              class: { disabledStyle: _vm.disabled },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.startBind.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
          ),
          _c("div", { staticClass: "unregisteredTips" }, [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.goRegister.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("noEmail")))]
            ),
            _vm._v(">\n        ")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }