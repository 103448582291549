import { FontSize } from "element-tiptap";

const SIZE_PATTERN = /([\d.]+)px/i;

function convertToPX(styleValue) {
  const matches = styleValue.match(SIZE_PATTERN);
  if (!matches) return "";
  const value = matches[1];
  if (!value) return "";
  return value;
}

export default class PxFontSize extends FontSize {
  get schema() {
    return {
      attrs: {
        px: ""
      },
      inline: true,
      group: "inline",
      parseDOM: [
        {
          style: "font-size",
          getAttrs: (fontSize) => {
            const attrs = { px: "" };
            if (!fontSize) return attrs;
            const px = convertToPX(fontSize);
            if (!px) return attrs;

            return {
              px
            };
          }
        }
      ],
      toDOM(node) {
        const { px } = node.attrs;
        const attrs = { style: "" };
        if (px) {
          attrs.style = `font-size: ${px}px`;
        }
        return ["span", attrs, 0];
      }
    };
  }
}
