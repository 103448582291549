<template>
  <div
    class="tree-menu"
    v-loading="primaryTreeLoadng"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255,255,255,0.6)"
  >
    <div
      v-for="(item, index) in menuItems"
      :key="index"
      @click="toggleSubMenu(index)"
    >
      <div class="menu-item" :class="{ active: item.open }">
        <div class="ureadIcon" v-if="showUreadIcon(item)"></div>
        <div class="menu-content">
          <span
            class="menu-title"
            :style="{
              color: item.open ? '#292D32' : '#65696E',
              'font-weight': item.open ? 'bold' : '',
            }"
            :title="`${handlerName(item)}(${item.mailAddress})`"
          >
            {{ handlerName(item) }}
          </span>
          <span
            class="menu-note"
            :style="{ color: item.open ? '#858585' : '#65696E' }"
            :title="`${handlerName(item)}(${item.mailAddress})`"
          >
            ({{ item.mailAddress }})
          </span>
        </div>
        <el-image
          :style="{
            transform: item.open ? 'rotate(0deg)' : 'rotate(-90deg)',
            transition: 'transform .3s',
            width: '10px',
            height: '6px',
          }"
          :src="require('../../../../../assets/images/email/arrow.png')"
          :fit="'cover'"
        ></el-image>
      </div>
      <div
        :style="{
          height: item.open ? subMenuHeight : '0px',
          'border-bottom': item.open ? '1px solid #EDEDED' : '',
        }"
        class="sub-menu"
        @click.stop="() => false"
        v-loading="secondaryTreeLoadng"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255,255,255,0.5)"
      >
        <div
          v-for="(subItem, subIndex) in item.subMenu"
          class="sub-menu-item"
          :key="subIndex"
          :style="{
            background: showSubActive(item, subItem) ? '#E4F8E5' : '#FFFFFF',
          }"
          @click.stop="selectMenu(index, subIndex)"
        >
          <div class="menu-line" v-if="showSubActive(item, subItem)"></div>
          <el-image
            :style="{
              width: subItem.iconWidth ? `${subItem.iconWidth}px` : '18px',
              height: subItem.iconHeight ? `${subItem.iconHeight}px` : '18px',
            }"
            :src="subItem.icon"
            :fit="'cover'"
          ></el-image>
          <span class="sub-menu-title" :style="{ color: '#000000' }">
            {{ subItem.title }}
            <template v-if="showInboxUnreadCount(subItem)">
              <span class="unreadIcon">
                {{
                  handleUnreadIcon(
                    subItem.localUnreadCount || subItem.unreadMessageCount
                  )
                }}
              </span>
            </template>
            <template v-else-if="showMessageCount(subItem)">
              <span class="countIcon" v-if="handlerMessageCount(subItem) != 0">
                {{ handlerMessageCount(subItem) }}
              </span>
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEmailList,
  getEmailInfoByAddress,
} from "@/api/newVersion/melinked_email";
import { encryptData } from "@/utils/aes_email";
import { setSecretKeyByOriginId } from "@/api/indexDB/api/email_secretKey_api";
import {
  getUnreadEmailNum,
  getEmailNumByFolder,
  getStarredMailCount,
} from "@/api/indexDB/api/email_api";
import { env } from "@/utils";
export default {
  name: "TreeMenu",
  props: {
    subMenu: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      menuItems: [],
      currentOpenAccountFolder: null,
      treeLoading: false,
      subLoading: false,
    };
  },
  computed: {
    primaryTreeLoadng() {
      return this.treeLoading && !this.emailModuleLoading;
    },
    secondaryTreeLoadng() {
      return this.subLoading && !this.treeLoading && !this.emailModuleLoading;
    },
    emailModuleLoading() {
      return this.$store.state.melinked_email.emailModuleLoading;
    },
    subMenuHeight() {
      return this.menuItems[0].subMenu
        ? `${this.menuItems[0].subMenu.length * 50 - 10}px`
        : "0px";
    },
    currentComponent() {
      return this.$store.state.melinked_email.currentComponent;
    },
    currentEmailAccount() {
      return this.$store.state.melinked_email.currentEmailAccount;
    },
    emailAccounts() {
      return this.$store.state.melinked_email.emailAccounts;
    },
    // 当前用户信息
    userInfo() {
      return this.$store.getters.userInfo;
    },
    // 刷新邮箱菜单
    refreshEmailSubMenu() {
      return this.$store.state.melinked_email.refreshEmailSubMenu;
    },
    // 需要默认展开的邮箱
    needOpenEmailAddress() {
      return this.$store.state.melinked_email.needOpenEmailAddress;
    },
    // 初始化邮箱列表
    refreshEmailList() {
      return this.$store.state.melinked_email.refreshEmailList;
    },
    // 当前需要指定进入的模块
    current_list_module_to_enter() {
      return this.$store.state.melinked_email.current_list_module_to_enter;
    },
    // 子菜单
    emailSubMenus() {
      return this.$store.state.melinked_email.emailSubMenus;
    },
    // 收到邮箱接收到邮件通知
    emailUnreadRecords() {
      return this.$store.state.melinked_email.emailUnreadRecords;
    },
    // 获取所有未读邮件总数
    fetchAllEmailUnreadCount() {
      return this.$store.state.melinked_email.fetchAllEmailUnreadCount;
    },
  },
  watch: {
    // 刷新当前展开的菜单
    refreshEmailSubMenu: {
      async handler(val) {
        if (val) {
          try {
            await this.refreshEmailMenuInfo();
          } catch (e) {}
          this.$store.commit("setRefreshEmailSubMenu", false);
        }
      },
      deep: true,
    },
    needOpenEmailAddress: {
      async handler(val) {
        if (val && val.mailAddress != "") {
          await this.initData();
        }
      },
      deep: true,
    },
    // 刷新整个邮箱模块
    refreshEmailList: {
      async handler(val) {
        if (val) {
          await this.initData();
          this.$store.commit("setRefreshEmailList", false);
        }
      },
      deep: true,
    },
    // 监听邮箱收到邮件通知，用于刷新当前浏览的邮箱数据
    emailUnreadRecords: {
      async handler(val, old) {
        try {
          // 如果刷新的邮箱队列里有当前展开的邮箱
          if (old.includes(this.currentEmailAccount.mailAddress)) {
            const itemInMenu = this.menuItems.filter(
              (item) =>
                item.originProviderId ==
                this.currentEmailAccount.originProviderId
            )[0];
            if (itemInMenu.open) {
              await this.handlerLocalUnreadCount("inbox");
            }
          }
        } catch (err) {}
      },
      deep: true,
    },
    fetchAllEmailUnreadCount: {
      async handler(val, old) {
        if (val && val != old) {
          await this.refreshAllEmailMenuInfo();
          this.$store.commit("setFetchAllEmailUnreadCount", false);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.initData();
  },
  methods: {
    handleUnreadIcon(number) {
      if (number > 99) {
        return "99+";
      }
      return number;
    },
    showUreadIcon(menuItem) {
      if (this.menuItems.length < 2) {
        return false;
      }
      let ureadCount = 0;
      try {
        ureadCount = menuItem.subMenu[1].localUnreadCount;
      } catch (err) {}
      return ureadCount > 0;
    },
    async listIsRefreshed(data) {
      const { type, size } = data;
      if (type == "inbox") {
        await this.handlerLocalUnreadCount("inbox");
      }
      // 刷新草稿箱和垃圾箱的邮件总数
      if (type == "drafts" || type == "spam") {
        await this.handlerFolderCount(type);
      }
      if (type == "inbox" || type == "trash") {
        // 更新收件箱总数
        if (typeof size == "number") {
          this.updateMenuItemsCount(type, size);
        }
      }
      // 刷新星标邮件总数
      await this.getCurrentEmailStarredCount();
    },
    // 更新菜单项计数
    updateMenuItemsCount(type, size) {
      this.menuItems = this.menuItems.map((menu) => {
        if (
          menu.originProviderId === this.currentEmailAccount.originProviderId
        ) {
          menu.subMenu = menu.subMenu.map((sub) => {
            if (sub.name === type) {
              sub.messageCount = size;
            }
            return sub;
          });
        }
        return menu;
      });
    },
    // 获取当前选中邮箱的星标邮件总数
    async getCurrentEmailStarredCount() {
      const starredCount = await getStarredMailCount(
        this.currentEmailAccount.mailAddress
      );
      this.refreshInboxUnreadCount(starredCount, "mark");
      return starredCount;
    },
    // 展示收件箱未读数小红点
    showInboxUnreadCount(item) {
      const showUnreadCountArr = ["inbox"];
      const unreadCount = item.localUnreadCount || item.unreadMessageCount;
      return showUnreadCountArr.includes(item.name) && unreadCount > 0;
    },
    // 是否需要展示对应邮件列表的计数
    showMessageCount(item) {
      const showArr = ["inbox", "sent", "mark", "drafts", "trash", "spam"];
      return showArr.includes(item.name);
    },
    // 获取当前菜单的展示数
    handlerMessageCount(item) {
      return item.messageCount ? item.messageCount : 0;
    },
    // 计算当前收件箱还剩的未读邮件数量
    async handlerLocalUnreadCount(type = "inbox") {
      try {
        let folder_name,
          mapping = { inbox: "INBOX" };
        if (mapping[type]) {
          folder_name = mapping[type];
        }
        if (folder_name) {
          let result = await getUnreadEmailNum(
            this.currentEmailAccount.mailAddress,
            folder_name
          );
          await this.refreshInboxUnreadCount(result.unreadCount, type);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 计算当前剩余总量
    async handlerFolderCount(folder) {
      const mapping = { drafts: "Drafts", spam: "Spam" };
      try {
        let emailCount = await getEmailNumByFolder(
          this.currentEmailAccount.mailAddress,
          mapping[folder]
        );
        await this.refreshInboxUnreadCount(emailCount, folder);
      } catch (error) {
        console.log(error);
      }
    },
    // 更新当前菜单展示数
    async refreshInboxUnreadCount(count, menuName) {
      const showUnreadArr = ["inbox"];
      const showCountArr = ["drafts", "mark", "spam"];
      this.menuItems = this.menuItems.map((menu) => {
        if (
          menu.originProviderId == this.currentEmailAccount.originProviderId
        ) {
          menu.subMenu = menu.subMenu.map((sub) => {
            if (sub.name == menuName && showUnreadArr.includes(menuName)) {
              sub.localUnreadCount = count;
              sub.unreadMessageCount = count;
            }
            if (sub.name == menuName && showCountArr.includes(menuName)) {
              sub.messageCount = count;
            }
            return sub;
          });
        }
        return menu;
      });
      await this.$forceUpdate();
    },
    // 更新所有邮箱的收件箱未读计数并刷新菜单未读提示
    async refreshAllEmailMenuInfo() {
      if (this.menuItems.length < 2) {
        return;
      }
      let results = [];
      try {
        for (let i = 0; i < this.menuItems.length; i++) {
          const menu = this.menuItems[i];
          let result = await getUnreadEmailNum(menu.mailAddress, "INBOX");
          results.push(result);
        }
      } catch (error) {
        console.log(error);
      }
      results = results.filter((menu) => menu.unreadCount > 0);
      if (results.length > 0) {
        this.menuItems = this.menuItems.map((menu) => {
          const index = results.findIndex(
            (result) => result.mailAddress == menu.mailAddress
          );
          if (index !== -1) {
            menu.subMenu = menu.subMenu.map((sub) => {
              if (sub.name == "inbox") {
                sub.localUnreadCount = results[index].unreadCount;
                sub.unreadMessageCount = results[index].unreadCount;
              }
              return sub;
            });
          }
          return menu;
        });
      }
    },
    async refreshEmailMenuInfo() {
      if (this.currentEmailAccount) {
        try {
          await this.getAccountInfoFromGmail();
        } catch (e) {
          return;
        }
        try {
          // 刷新一下收件箱的未读数
          await this.handlerLocalUnreadCount("inbox");
          // 获取当前邮箱的星标数量
          await this.getCurrentEmailStarredCount();
        } catch (error) {}
        // 是否有需要进入的子菜单
        if (this.current_list_module_to_enter) {
          const mainIndex = this.menuItems.findIndex(
            (account) =>
              account.originProviderId ==
              this.current_list_module_to_enter.emailProviderId
          );
          const index = this.menuItems[mainIndex].subMenu.findIndex(
            (menu) => menu.name == this.current_list_module_to_enter.pageType
          );
          await this.selectMenu(mainIndex, index);
          this.$store.commit("setCurrent_list_module_to_enter", null);
        }
      }
    },
    // 初始化菜单
    async initData() {
      this.treeLoading = true;
      this.$store.commit("setEmailSubMenus", _.cloneDeep(this.subMenu));
      // 创建菜单队列
      this.menuItems = this.emailAccounts.map((account) => {
        let cloneAccount = _.cloneDeep(account);
        cloneAccount.open = false;
        cloneAccount.subMenu = _.cloneDeep(this.emailSubMenus);
        return cloneAccount;
      });
      // 如果有需要默认需要展开的邮箱
      if (
        this.needOpenEmailAddress &&
        this.needOpenEmailAddress.mailAddress != ""
      ) {
        this.menuItems = this.menuItems.map((item) => {
          if (
            item.mailAddress.toLowerCase() ==
            this.needOpenEmailAddress.mailAddress.toLowerCase()
          ) {
            if (item.freezeStatus) {
              this.$message({
                type: "error",
                message: this.$t("email_has_been_frozen", {
                  n: item.mailAddress,
                }),
              });
              this.$store.commit("setCurrentEmailAccount", null);
              item.open = false;
            } else {
              this.$store.commit("setCurrentEmailAccount", _.cloneDeep(item));
              item.open = true;
            }
          } else {
            item.open = false;
          }
          return item;
        });
        this.treeLoading = false;
        if (!this.currentEmailAccount) {
          return;
        }
        // 如果需要默认开启子菜单
        if (this.needOpenEmailAddress.folder_name) {
          const sub = this.currentEmailAccount.subMenu.find(
            (menu) => menu.folder_name == this.needOpenEmailAddress.folder_name
          );
          if (sub) {
            this.$store.commit("setCurrent_list_module_to_enter", {
              emailProviderId: this.currentEmailAccount.originProviderId,
              pageType: sub.name,
            });
          }
        }
        this.$store.commit("setNeedOpenEmailAddress", null);
        await this.refreshEmailMenuInfo();
        await this.refreshAllEmailMenuInfo();
        return;
      }
      // 如果当前没有选中开启的邮箱，就默认开启第一个
      if (!this.currentEmailAccount) {
        this.menuItems.forEach((item) => {
          item.open = false;
        });
        if (this.menuItems[0].freezeStatus) {
          this.$message({
            type: "error",
            message: this.$t("email_has_been_frozen", {
              n: this.menuItems[0].mailAddress,
            }),
          });
          this.menuItems[0].open = false;
          this.$store.commit("setCurrentEmailAccount", null);
        } else {
          this.menuItems[0].open = true;
          this.$store.commit(
            "setCurrentEmailAccount",
            _.cloneDeep(this.menuItems[0])
          );
          // 默认开启第一个文件夹
          this.$store.commit("setCurrent_list_module_to_enter", {
            emailProviderId: this.menuItems[0].originProviderId,
            pageType: "inbox",
          });
          await this.refreshEmailMenuInfo();
        }
        this.treeLoading = false;
      } else {
        this.menuItems.forEach((item) => {
          if (
            this.currentEmailAccount &&
            item.originProviderId == this.currentEmailAccount.originProviderId
          ) {
            if (item.freezeStatus) {
              this.$message({
                type: "error",
                message: this.$t("email_has_been_frozen", {
                  n: item.mailAddress,
                }),
              });
              item.open = false;
              this.$store.commit("setCurrentEmailAccount", null);
            } else {
              item.open = true;
              // 如果缺少子菜单则填充上
              if (!this.currentEmailAccount.subMenu) {
                let newAccount = _.cloneDeep(this.currentEmailAccount);
                newAccount.subMenu = item.subMenu;
                this.$store.commit("setCurrentEmailAccount", newAccount);
              }
            }
          } else {
            item.open = false;
          }
        });
        this.treeLoading = false;
        if (!this.currentEmailAccount) {
          return;
        }
        await this.refreshEmailMenuInfo();
      }
      await this.refreshAllEmailMenuInfo();
      await this.$forceUpdate();
    },
    // 将获取到的数据合并进菜单
    async fillInMenuData(folders) {
      this.menuItems.forEach((item) => {
        // 将开启的菜单更新
        if (item.open) {
          item.subMenu = item.subMenu.map((sub) => {
            const matchingFolder = folders.find(
              (f) => f.folder === sub.folder_name
            );
            return matchingFolder ? { ...sub, ...matchingFolder } : sub;
          });
          const cloneAccount = _.cloneDeep(this.currentEmailAccount);
          if (item.originProviderId == cloneAccount.originProviderId) {
            cloneAccount.subMenu = _.cloneDeep(item.subMenu);
            this.$store.commit("setCurrentEmailAccount", cloneAccount);
          }
        }
      });
      await this.$forceUpdate();
    },
    handlerName(item) {
      let name = item.name;
      if (item.name == "") {
        name = item.mailAddress.split("@")[0];
      }
      return name;
    },
    // 存入本地缓存
    async setEmailLocalPwd(data) {
      const handlerData = data ? encryptData(data) : data;
      return new Promise(async (resolve, reject) => {
        if (this.currentEmailAccount) {
          await setSecretKeyByOriginId(
            this.currentEmailAccount.originProviderId,
            handlerData
          );
        } else {
          this.$message({
            type: "error",
            message: "当前邮箱账号信息丢失",
          });
        }
        resolve();
      });
    },
    // 开始获取目前开启的邮箱账号文件夹信息
    async getAccountInfoFromGmail() {
      return new Promise(async (resolve, reject) => {
        this.subLoading = true;
        let password = "";
        try {
          password = await this.globalVerifyEmailPassword(
            this.currentEmailAccount
          );
        } catch (err) {
          this.subLoading = false;
          reject();
          return;
        }
        // 由于邮箱列表中updatePassword的为空，所以必须单独请求一下updatePassword
        try {
          const emailInfo = await getEmailInfoByAddress(
            this.currentEmailAccount.mailAddress
          );
          if (emailInfo.code == 200) {
            this.$store.state.melinked_email.currentEmailAccount.updatePassword =
              emailInfo.data.data.updatePassword;
          }
        } catch (error) {
          console.log(error);
        }
        // 判断一下是否是团队邮箱并且邮箱账号密码没有更新过，则需要走团队邮箱密码修改流程
        if (
          this.currentEmailAccount.mailAddress.indexOf(env("EMAIL_DOMAIN")) ==
            -1 &&
          !this.currentEmailAccount.updatePassword
        ) {
          // 清空本地缓存的密码
          this.$store.commit("setTeamWindowCloseFn", async () => {
            await this.setEmailLocalPwd("");
            this.$store.commit("setTeamWindowCloseFn", null);
          });
          this.$store.commit(
            "setMemberUserName",
            this.currentEmailAccount.mailAddress
          );
          this.$store.commit("setMemberOldPassword", password);
          this.$store.commit(
            "setMemberMailOriginProviderId",
            this.currentEmailAccount.originProviderId
          );
          this.$store.commit("setHideTeamMemberBackButton", true);
          localStorage.setItem("setEmailAccountPassword", true);
          this.showMemberPasswordSetting();
          this.subLoading = false;
          reject();
          return;
        }
        const createdToken = {
          u: this.currentEmailAccount.mailAddress,
          p: password,
        };
        const aesToten = JSON.stringify(createdToken);
        this.$store.commit("setMelinked_Email_token", aesToten);
        const result = await getEmailList();
        this.subLoading = false;
        if (result.code == 200) {
          this.currentOpenAccountFolder = result.data.data;
          this.fillInMenuData(this.currentOpenAccountFolder);
          resolve();
        } else if (result.code == 502) {
          await this.setEmailLocalPwd("");
          const verificationItem = _.cloneDeep(this.currentEmailAccount);
          this.$store.commit("setEmailPasswordVerificationDialog", {
            type: "verify",
            item: verificationItem,
          });
          this.$message({
            type: "error",
            message: result.message,
          });
          reject();
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          reject();
        }
      });
    },
    showSubActive(item, subItem) {
      return (
        this.currentEmailAccount &&
        item.originProviderId == this.currentEmailAccount.originProviderId &&
        subItem.name == this.currentComponent
      );
    },
    async toggleSubMenu(index) {
      if (this.menuItems[index].freezeStatus) {
        this.$message({
          type: "error",
          message: this.$t("email_has_been_frozen", {
            n: this.menuItems[index].mailAddress,
          }),
        });
        return;
      }
      this.menuItems.forEach(async (item, i) => {
        if (i === index) {
          item.open = !item.open;
          this.$store.commit("setCurrentEmailAccount", _.cloneDeep(item));
          if (item.open) {
            this.$store.commit("setCurrent_list_module_to_enter", {
              emailProviderId: item.originProviderId,
              pageType: "inbox",
            });
            await this.refreshEmailMenuInfo();
          }
        } else {
          item.open = false;
        }
      });
      this.$forceUpdate();
    },
    // 选择小菜单
    selectMenu: _.debounce(async function(index, subIndex) {
      try {
        await this.globalVerifyEmailPassword(this.currentEmailAccount);
      } catch (err) {
        // 如果密码验证失败，则显示当前展示组件展示为默认主页
        this.$store.commit("setEmailCurrentComponentHistory", "main");
        return;
      }
      if (!this.menuItems[index] || !this.menuItems[index].subMenu[subIndex]) {
        return;
      }
      this.$store.commit("setCurrentSubMenuInfo", null);
      this.$store.commit("setEmailCurrentComponentHistory", "main");
      await this.$nextTick();
      this.$store.commit(
        "setCurrentSubMenuInfo",
        this.menuItems[index].subMenu[subIndex]
      );
      switch (this.menuItems[index].subMenu[subIndex].index) {
        case 0:
          this.$store.commit("setEmailCurrentComponentHistory", "send");
          break;
        case 1:
          this.$store.commit("setEmailCurrentComponentHistory", "inbox");
          break;
        case 2:
          this.$store.commit("setEmailCurrentComponentHistory", "mark");
          break;
        case 3:
          this.$store.commit("setEmailCurrentComponentHistory", "sent");
          break;
        case 4:
          this.$store.commit("setEmailCurrentComponentHistory", "drafts");
          break;
        case 5:
          this.$store.commit("setEmailCurrentComponentHistory", "trash");
          break;
        case 6:
          this.$store.commit("setEmailCurrentComponentHistory", "spam");
          break;
        default:
          break;
      }
    }, 500),
  },
};
</script>

<style lang="stylus" scoped>
@import '~styles/utils/index';
.tree-menu
  font-family: Arial, sans-serif;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-light();
  .active
    background-color: #FFFFFF;
  .menu-item
    cursor: pointer;
    padding: 0 20px;
    overflow: hidden;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;;
    justify-content: flex-start;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 1px solid #EDEDED;
    .ureadIcon
      position: absolute;
      background: #F56C6C;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: 13px;
      left: 17px;
      z-index: 1;
    .menu-content
      flex: 1;
      height: 100%;
      line-height: 54px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .menu-title
        font-size: 16px;
      .menu-note
        font-size: 14px;
  .sub-menu
    width: 100%;
    overflow: hidden;
    transition: height 0.3s;
    .sub-menu-item
      width: 100%;
      height: 49px;
      box-sizing: border-box;
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      border-top: 1px solid #EDEDED;
      background: #FFFFFF;
      position: relative;
      &:hover
        background: #F0F0F0;
      .menu-line
        position: absolute;
        width: 6px;
        height: 49px;
        top: 0;
        left: 0;
        z-index: 1;
        background: #52BD68;
      .el-image
        flex-shrink: 0;
      .sub-menu-title
        flex: 1;
        min-width: 0;
        color: #000000;
        font-size: 14px;
        padding-left:12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 22px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        .unreadIcon
          height: 20px;
          width: 20px;
          border-radius:10px;
          background: #f57575;
          overflow: hidden;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          line-height: 20px;
          color: #fff;
          font-size: 12px;
          font-family: Abel;
        .countIcon
          font-family: Abel;
          color: #65696E;
          font-size: 12px;
          margin-right: 28px;
</style>
